import CryptoJS from "crypto-js";

const keyutf = CryptoJS.enc.Hex.parse("000102030405060708090a0b0c0d0e0f");
var iv = CryptoJS.enc.Hex.parse("00000000000000000000000000000000");

function encrypt(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), keyutf, {
    iv: iv,
  }).toString();
}

function decrypt(ciphertext) {
  var bytes = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(ciphertext) },
    keyutf,
    {
      iv: iv,
    }
  );
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export { encrypt, decrypt };

import { TimeCounter } from 'components/ProgressStepper/stepper.styles';
import { useState, useEffect } from 'react';

function useVideoPlayer(videoElement) {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    currentMinutes: 0,
    currentSeconds: 0,
    durationMinutes: 0,
    durationSeconds: 0,
  });
  const [loadingVideo, setLoadingVideo] = useState(true);

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
  };

  useEffect(() => {
    if (videoElement.current) {
      playerState.isPlaying ? videoElement.current.play() : videoElement.current.pause();
    }
  }, [playerState.isPlaying, videoElement]);

  // handle end of video
  const handleOnEnded = () => {
    setPlayerState((prevState) => ({
      ...prevState,
      isPlaying: false,
    }));
  };

  const handleOnTimeUpdate = () => {
    if (videoElement.current) {
      const progress =
        (videoElement.current.currentTime / videoElement.current.duration) * 100;
      const currentMinutes = Math.floor(videoElement.current.currentTime / 60);
      const currentSeconds = Math.floor(
        videoElement.current.currentTime - currentMinutes * 60
      );
      const durationMinutes = Math.floor(videoElement.current.duration / 60);
      const durationSeconds = Math.floor(
        videoElement.current.duration - durationMinutes * 60
      );

      if (!isNaN(durationMinutes) && !isNaN(durationSeconds)) {
        setPlayerState((prev) => ({
          ...prev,
          progress,
          currentMinutes,
          currentSeconds,
          durationMinutes,
          durationSeconds,
        }));
        setLoadingVideo(false);
      } else {
        setLoadingVideo(true);
      }
    }
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    videoElement.current.currentTime =
      (videoElement.current.duration / 100) * manualChange;
    setPlayerState((prev) => ({
      ...prev,
      progress: manualChange,
    }));
  };

  const handleVideoSpeed = (event) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  const reset = () => {
    setPlayerState({
      isPlaying: false,
      progress: 0,
      speed: 1,
      isMuted: false,
      currentMinutes: 0,
      currentSeconds: 0,
      durationMinutes: 0,
      durationSeconds: 0,
    });
    setLoadingVideo(true);
  };

  useEffect(() => {
    if (videoElement.current) {
      playerState.isMuted
        ? (videoElement.current.muted = true)
        : (videoElement.current.muted = false);
    }
  }, [playerState.isMuted, videoElement]);

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    handleOnEnded,
    reset,
    timeCounter: () => {
      return (
        <TimeCounter>
          {loadingVideo ? (
            <span>loading...</span>
          ) : (
            <>
              {playerState.currentMinutes < 10
                ? `0${playerState.currentMinutes}`
                : playerState.currentMinutes}
              :{' '}
              {playerState.currentSeconds < 10
                ? `0${playerState.currentSeconds}`
                : playerState.currentSeconds}{' '}
              /
              <span>
                {playerState.durationMinutes < 10
                  ? `0${playerState.durationMinutes}`
                  : playerState.durationMinutes}
                :{' '}
                {playerState.durationSeconds < 10
                  ? `0${playerState.durationSeconds}`
                  : playerState.durationSeconds}
              </span>
            </>
          )}
        </TimeCounter>
      );
    },
    loadingVideo,
  };
}

export default useVideoPlayer;

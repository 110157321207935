import styled from 'styled-components';

export const ButtonContainer = styled.div`
  & .MuiButton-root {
    width: 100%;
    white-space: nowrap;
  }
  & .Mui-disabled {
    opacity: 0.4 !important ;
  }
  & .btn-progress {
    line-height: 0;
  }

  .btn-progress .MuiCircularProgress-root {
    color: #fff;
  }
`;

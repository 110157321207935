import { Grid, Rating, Typography } from '@mui/material';
import {
  BlockMargin,
  FeedbackCardContainer,
  FlexColumn,
  RatingBlock,
} from 'assets/styles/main.styles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { Title, Videoview } from 'components';

const FeedbackCardSkeletonLoader = () => {
  return (
    <FeedbackCardContainer>
      <Grid container spacing={6} alignItems="flex-start">
        <Grid item xs={7}>
          <Skeleton
            style={{
              marginTop: '-50px',
            }}
            animation="wave"
            width={'100%'}
          >
            <Videoview videosrc={''} />
          </Skeleton>
        </Grid>
        <Grid item xs={5}>
          <BlockMargin>
            <Skeleton animation="wave" width="97%">
              <Typography variant="h4">.</Typography>
            </Skeleton>
            <Skeleton animation="wave" width="60%">
              <RatingBlock>
                <Rating
                  name="simple-controlled"
                  value={3}
                  precision={0.5}
                  size="large"
                  readOnly
                />
                <Title level="h4" text={''} /> &nbsp; / 5
              </RatingBlock>
            </Skeleton>
          </BlockMargin>
          <FlexColumn gap={16}>
            <Skeleton animation="wave" width="90%">
              <Typography variant="h5">.</Typography>
            </Skeleton>
            <FlexColumn gap={8}>
              {new Array(3).fill(0).map((_, index) => (
                <Skeleton animation="wave" variant="text" width="97%">
                  <Typography variant="p">.</Typography>
                </Skeleton>
              ))}
            </FlexColumn>
          </FlexColumn>
        </Grid>
      </Grid>
    </FeedbackCardContainer>
  );
};

export default FeedbackCardSkeletonLoader;

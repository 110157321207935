import React from "react";

import { Title } from "components";
import { Box, CodeVideoBlock } from "assets/styles/main.styles";

const InterviewWindow = ({ title, isVideoOn }) => {
  return (
    <div>
      <Title level="h1" text={title} />
      <CodeVideoBlock>
        {isVideoOn ? (
          <Box>Video Placeholder</Box>
        ) : (
          <Box>Code Editor Placeholder</Box>
        )}
      </CodeVideoBlock>
    </div>
  );
};

export default InterviewWindow;

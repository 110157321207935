import React, { useEffect, useState } from "react";
import GaugeChart from "react-gauge-chart";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import { Avatar, Grid, Skeleton, Snackbar, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  PageHeader,
  Tag,
  Title,
  Checkbox,
  GoBackButton,
} from "components";
import { Rating } from "@mui/material";
import { RatingValues } from "config/constants";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LinkIcon from "@mui/icons-material/Link";
import DownloadIcon from "@mui/icons-material/Download";
import ReviewAnswerCard from "pages/Interviewer/Review/components/ReviewAnswerCard";
import {
  Box,
  BackButton,
  InfoActionsBlock,
  CandidateInfoBlock,
  ActionButtons,
  CIBBlock,
  SubtitleInfo,
  BlockMargin,
  BasicQuestionItem,
  BasicAnswerItem,
  BodyTextFeedback,
  RatingOverall,
  RatingStatus,
  BasicQuestionAnswer,
  FeedbackCardContainer,
  RatingBlock,
  PaddingBlock,
  FlexColumn,
  FlexRow,
  ContentCenter,
} from "assets/styles/main.styles";
import FeedbackCard from "./components/FeedbackCard";
import {
  downloadResume,
  getBaseQuestionResponse,
  getInterviewFeedbackDetails,
} from "config/api.service";
import { statuses } from "config/constants";
import { theme } from "theme/constants";
import BarLoader from "components/SkeletonLoaders/BarLoader";
import FeedbackCardSkeletonLoader from "./components/FeedbackCardSkeletonLoader";
import { capitalizeWord } from "../../../utils";
import { formatDateDMY } from "utils";

import Stack from "@mui/material/Stack";

import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { format } from "date-fns";
import ResumePreview from "components/ResumePreview/ResumePreview";

function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = "#";
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function stringAvatar(name) {
  // return {
  //   sx: {
  //     bgcolor: stringToColor(name),
  //   },
  //   children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  // };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FeedbackView = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { state } = useLocation();
  const {
    candidateId,
    roleId,
    fullName,
    statusId,
    jobRoleFitRating,
    overallRating,
  } = state;
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [basicAnswers, setBasicAnswers] = useState([]);
  const [comment, setComment] = useState("");
  const [isDownloadingResume, setIsDownloadingResume] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
  const [resumePath, setResumePath] = useState("");

  const [roleDetails, setRoleDetails] = useState({});
  const resumeFile = "";
  const handleClickCopy = () => {
    setOpen(true);
  };

  const handleCloseCopy = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFeedbackDetails = (res) => {
    setInterviewDetails(res.qResponse);
    setRoleDetails(res.qResponse[0].roleDetails);
    setComment(res.comment);
  };

  const handleBasicAnswers = (res) => {
    setBasicAnswers(res);
  };

  const handleBasicAnswersErr = (error) => {
    console.log(error);
  };

  const downloadResumeFunction = async () => {
    setIsDownloadingResume(true);
    await downloadResume(interviewDetails[0].resumeLink);
    setIsDownloadingResume(true);
  };

  const openResumePreview = () => {
    setResumePath(interviewDetails[0].resumeLink);
    setIsResumePreviewOpen(true);
  };
  const handleResumePreview = () => {
    setIsResumePreviewOpen(false);
    setResumePath("");
  };

  useEffect(() => {
    (async () => {
      if (candidateId && roleId) {
        setIsLoadingData(true);
        getInterviewFeedbackDetails(
          { roleId, candidateId },
          handleFeedbackDetails
        );
        await getBaseQuestionResponse(
          { roleId, candidateId },
          handleBasicAnswers,
          handleBasicAnswersErr
        );
        setIsLoadingData(false);
      }
    })();
  }, [candidateId, roleId]);

  return (
    <div>
      {/* <BackButton>
        <Button
          btnText="Back"
          variant="text"
          startIcon={<KeyboardBackspaceIcon />}
          onClick={() => navigate(-1)}
        />
      </BackButton>
      <PageHeader
        isStartAlign
        pageTitleText={`${
          roleDetails
            ? capitalizeWord(roleDetails?.roleName)
            : "React Developer"
        }`}
      />
      <InfoActionsBlock>
        <CandidateInfoBlock>
          {isLoadingData ? (
            <FlexRow gap={10}>
              <Skeleton width={"30%"} variant="circular">
                <Avatar />
              </Skeleton>
              <div style={{ width: "200px" }}>
                <FlexRow gap={10}>
                  <Skeleton animation="wave" variant="text" width={"70%"}>
                    <Typography variant="h4">.</Typography>
                  </Skeleton>
                  <Skeleton animation="wave" variant="text" width={"30%"}>
                    <Tag type="purple" label={candidateId} />
                  </Skeleton>
                </FlexRow>
                <Skeleton animation="wave" variant="text" width={"100%"}>
                  <SubtitleInfo>.</SubtitleInfo>
                </Skeleton>
              </div>
            </FlexRow>
          ) : (
            <>
              <Avatar {...stringAvatar(fullName)} />
              <CIBBlock>
                <span>
                  <Title level="h4" text={fullName} />{" "}
                  {/* <Tag type="purple" label={candidateId} /> 
                </span>
                {isLoadingData ? (
                  <BarLoader />
                ) : (
                  <SubtitleInfo>
                    Interviewed on:
                    {interviewDetails.length &&
                      formatDateDMY(
                        interviewDetails[0].createdDate.split(" ")[0]
                      )}
                  </SubtitleInfo>
                )}
              </CIBBlock>
            </>
          )}
          {interviewDetails[0]?.resumeLink && (
            <Button
              startIcon={<DownloadIcon />}
              variant="text"
              btnText="Download Resume"
              onClick={downloadResumeFunction}
              loading={isDownloadingResume}
              loaderColor={theme.colors.primaryBlue}
            />
          )}
        </CandidateInfoBlock>
      </InfoActionsBlock> */}
      <Box>
        <Grid container justifyContent="flex-start" alignItems="left">
          <Grid item xs={8}>
            <BackButton>
              <GoBackButton
                sx={{
                  color: "#dcdcdc",
                  marginTop: "0px",
                  marginLeft: "-12px",
                  marginRight: "12px",
                  padding: "2px 15px",
                  borderRight: "1px solid #c4c5c5",
                  height: "36px",
                  cursor: "pointer",
                }}
              />
              <CIBBlock>
                <span>
                  <Title
                    level="h2"
                    text={capitalizeWord(roleDetails?.roleName)}
                    paddingTop={"70px"}
                    color="black"
                  />
                </span>
              </CIBBlock>
            </BackButton>
          </Grid>

          {/* <Grid item xs={4}>
						<Stack direction="row" spacing={1} justifyContent="flex-end">
							<Button
								style={{ height: "30px", marginBottom: "24px" }}
								btnText="Back"
								variant="outlined"
								startIcon={<KeyboardBackspaceIcon />}
								onClick={() => navigate(-1)}
							/>
						</Stack>
					</Grid> */}
        </Grid>

        {/* <Grid container sx={{ marginBottom: "10px" }}>
					<Stack direction="row" spacing={1} justifyContent="flex-end" paddingTop={"30px"}>
						<ContactPageOutlinedIcon sx={{ color: "#48137B", marginTop: " 15px", marginLeft: "30px" }} />
						<a
							style={{ textDecoration: "solid", marginTop: " 4px", color: "#48137B", marginRight: "30px" }}
							disabled={!interviewDetails[0]?.resumeLink}
							href="javascript:void(0)"
							onClick={() => {
								openResumePreview();
							}}>
							View Resume
						</a>

						<DownloadIcon sx={{ color: "#48137B", marginTop: " 15px", marginLeft: "30px" }} />
						<a
							style={{ textDecoration: "solid", marginTop: " 4px", color: "#48137B", marginRight: "30px" }}
							disabled={!interviewDetails[0]?.resumeLink}
							href="javascript:void(0)"
							onClick={downloadResumeFunction}>
							Download Resume
						</a>
					</Stack>
				</Grid> */}

        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          verticalAlign={"center"}
          sx={{ alignItems: "center" }}
          paddingTop={"30px"}
        >
          <Title level="h1" text={fullName} paddingTop={"40px"} color="black" />
          <SubtitleInfo>
            Interviewed on:
            <strong>
              {" "}
              {interviewDetails.length
                ? format(interviewDetails[0].createdDate, "PPp")
                : ""}
            </strong>
          </SubtitleInfo>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          verticalAlign={"center"}
          sx={{ alignItems: "center" }}
        >
          <Title
            level="h4"
            text={RatingValues[Math.round(overallRating) - 1]}
          ></Title>
          <div>
            {!isLoadingData && (
              <RatingBlock
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {[...Array(5)].map((_, index) => {
                  const isFilled = overallRating >= index + 1;
                  const isHalfFilled =
                    overallRating > index && overallRating < index + 1;
                  const color =
                    isHalfFilled || isFilled
                      ? "#faaf00"
                      : "rgba(0, 0, 0, 0.25)";
                  const Icon = isHalfFilled
                    ? StarHalfRoundedIcon
                    : isFilled
                    ? StarRoundedIcon
                    : StarBorderRoundedIcon;
                  return (
                    <Icon
                      key={index}
                      style={{
                        width: "80px",
                        height: "80px",
                        color: color,
                        textAlign: "center",
                      }}
                    ></Icon>
                  );
                })}
              </RatingBlock>
            )}
          </div>
          <div>
            <Title level="h4" text={overallRating + " / 5"}></Title>
          </div>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          verticalAlign={"center"}
          sx={{ alignItems: "center" }}
          paddingBottom={"30px"}
        >
          <Title level="h4" text="PreScreening Status: ">
            {" "}
            Total Rating:
          </Title>
          <Tag
            type={statuses[statusId]}
            label={statuses[statusId]}
            size="large"
          />
        </Stack>
        {comment && (
          <BlockMargin size={30} style={{ textAlign: "center" }}>
            <Grid container>
              <Grid item xs={12}>
                {isLoadingData ? (
                  <>
                    <Skeleton width={"80%"}>
                      <BodyTextFeedback variant="p">Skel</BodyTextFeedback>
                    </Skeleton>
                    <Skeleton width={"80%"}>
                      <BodyTextFeedback variant="p">Skel</BodyTextFeedback>
                    </Skeleton>
                  </>
                ) : (
                  <BodyTextFeedback>{comment}</BodyTextFeedback>
                )}
              </Grid>
            </Grid>
          </BlockMargin>
        )}

        {isLoadingData ? (
          <>
            <FeedbackCardSkeletonLoader />
            <FeedbackCardSkeletonLoader />
          </>
        ) : (
          // interviewDetails?.map((item, i) => {
          //   return <FeedbackCard data={item} />;
          // })
          <BlockMargin size={30}>
            <Grid container spacing={8} alignItems="flex-start">
              {interviewDetails?.map((item, i) => {
                return (
                  <Grid item xs={4} key={i}>
                    <ReviewAnswerCard
                      data={item}
                      handleReviewData={() => {}}
                      reviewData={[{ questionId: 17 }]}
                      totalQuestions={interviewDetails.length}
                      currentQuestionNo={i + 1}
                      fromFeedback={true}
                      interviewDetails={interviewDetails}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </BlockMargin>
        )}
        <BlockMargin size={20}>
          <Title level="h4" text="Basic Answers" />
          {/* <Checkbox label="Make all responses accessible via the interview link." /> */}
        </BlockMargin>
        <BlockMargin size={20}>
          <Box isGrey>
            <Grid container spacing={2} alignItems="flex-start">
              {isLoadingData ? (
                <Grid item xs={12}>
                  <FlexColumn gap={16}>
                    {new Array(3).fill(0).map((_, index) => (
                      <FlexColumn key={index}>
                        <Skeleton animation="wave" variant="text" width="97%">
                          <Typography variant="p">.</Typography>
                        </Skeleton>
                        <Skeleton animation="wave" variant="text" width="97%">
                          <Typography variant="h5">.</Typography>
                        </Skeleton>
                      </FlexColumn>
                    ))}
                  </FlexColumn>
                </Grid>
              ) : (
                basicAnswers?.map((item, i) => {
                  return (
                    <Grid item xs={12}>
                      {/* <Checkbox label={item.question} /> */}
                      <BasicQuestionItem>
                        {i + 1}. {item.question}
                      </BasicQuestionItem>
                      {/* <BasicQuestionAnswer> */}
                      <BasicAnswerItem>{item.answer ?? ""}</BasicAnswerItem>
                      {/* </BasicQuestionAnswer> */}
                    </Grid>
                  );
                })
              )}
            </Grid>
          </Box>
        </BlockMargin>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseCopy}
      >
        <Alert onClose={handleCloseCopy} severity="info" sx={{ width: "100%" }}>
          Interview link copied!
        </Alert>
      </Snackbar>
      <ResumePreview
        isResumePreviewOpen={isResumePreviewOpen}
        handleResumePreview={handleResumePreview}
        resumePath={resumePath}
      />
    </div>
  );
};

export default FeedbackView;

import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";

export const AlertContext = createContext(null);

export const AlertProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("info");

  const showAlert = (message, severityValue) => {
    if (severityValue) {
      setSeverity(severityValue);
    }
    setAlertMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setAlertMessage("");
    setSeverity("info");
    setOpen(false);
  };

  return (
    <>
      <AlertContext.Provider value={{ showAlert }}>
        {children}
      </AlertContext.Provider>
      {open && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            // sx={{ width: "100%", background: "red" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export const useAlert = () => useContext(AlertContext);

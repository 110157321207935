import React from "react";
import { CardMedia } from "@mui/material";
import Editor from "@monaco-editor/react";

import { ConsoleFeed } from "components";
import VideoPlaceholder from "assets/images/temp/Video.png";
import CodePlaceholder from "assets/images/temp/code.png";

import { Box, CodeVideoBlock, IWSubtitle } from "assets/styles/main.styles";
import VideoCam from "components/VideoCam";

const InterviewWindow = React.forwardRef((props, ref) => (
  <div>
    <CodeVideoBlock>
      {/* {props.isVideoOn ? ( */}
        <VideoCam
          handleLoading={props.handleLoading}
          isLoading={props.isLoading}
          handleVideoData={props.handleVideoData}
          handleVideoDetails={props.handleVideoDetails}
          handleRecordStart={props.handleRecordStart}
          handleRecordStop={props.handleRecordStop}
          timeLimit={props.timeLimit}
          isTimerEnded={props.isTimerEnded}
          isHiddenQuestion={props.isHiddenQuestion}
          handleVideoLink={props.handleVideoLink}
          setVideoSrc={props.setVideoSrc}
          recordStatus={props.recordStatus}
          selectedAudioSource={props.selectedAudioSource}
        />
      {/* ) : ( */}
        {/* <Box>
          <Editor
            defaultLanguage="javascript"
            defaultValue="// some comment"
            className="code-editor"
          />
          <ConsoleFeed className="console" />
          {console.log("interv")}
        </Box>
      )} */}
    </CodeVideoBlock>
  </div>
));

export default InterviewWindow;

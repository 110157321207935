import { useState, useEffect } from "react";
import Recording from "assets/images/icons/Recording.svg";
import styled from "styled-components";

const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
`;

function str_pad_left(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

export const Timer = ({
  currentQuestionTimeLimit,
  timerStarted,
  currentQuestionId,
  onStopRecording,
}) => {
  const [timeLeft, setTimeLeft] = useState(currentQuestionTimeLimit * 60);

  useEffect(() => {
    let interval;
    if (timeLeft > 0 && timerStarted) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
        if (timeLeft <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    }
    if (timeLeft === 0 && timerStarted) {
      onStopRecording();
    }
    return () => clearInterval(interval);
  }, [timeLeft, timerStarted, currentQuestionTimeLimit]);

  useEffect(() => {
    setTimeLeft(currentQuestionTimeLimit * 60);
  }, [currentQuestionId]);

  const minutes = Math.floor(timeLeft / 60);
  const finalTime =
    str_pad_left(minutes, "0", 2) +
    ":" +
    str_pad_left(timeLeft - minutes * 60, "0", 2);

  // const totalTime =
  //   str_pad_left(currentQuestionTimeLimit, "0", 2) +
  //   ":" +
  //   str_pad_left(0, "0", 2);

  return (
    <TimerWrapper>
      <img src={Recording} style={{ marginRight: "10px" }} />
      {finalTime}
    </TimerWrapper>
  );
};

import styled, { css } from "styled-components";

const StyledQuestion = styled.h1(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f32};
    font-weight: ${fontWeight.semibold};
    line-height: 28px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    @media (max-width: 768px) {
      font-size: ${fontSize.f14};
      line-height: 20px;
    }
  `
);

export const Question = ({ currentQuestion }) => {
  return <StyledQuestion>{currentQuestion}</StyledQuestion>;
};

import React, { useEffect, useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Modal,
  PageHeader,
  Card,
  Title,
  Dialog,
  GoBackButton,
} from "components";
import AddIcon from "@mui/icons-material/Add";
import DashboardCountCards from "components/DashboardCountCards";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Stack from "@mui/material/Stack";
import {
  Box,
  BackButton,
  CardBlock,
  TableActionButton,
  TooltipText,
} from "assets/styles/main.styles";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import AddCandidate from "./components/AddCandidate";
import {
  downloadCsvTemplate,
  getInvitedCandidates,
  getJobRoleDetails,
  sendReviewInvitesByRole,
} from "config/api.service";
import { H3 } from "components/Title/title.styles";
import * as XLSX from "xlsx";
import {
  keyCountryCode,
  keyEmail,
  keyMobileNo,
  keyName,
} from "config/constants";
import { useAlert } from "contexts/alert.context";
import SendViaEmail from "components/SendViaEmail/SendViaEmail";
import useWindowSize from "hooks/useWindowSize";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ApplicationListing from "../JobListing/components/ApplicationListing/ApplicationListing";
import VideoInterviewListing from "../JobListing/components/VideoInterviewListing/VideoInterviewListing";
import { Loader } from "pages/Interviewer/RecentApplications/RecentApplications";

const JobDetail = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [invitedCandidatesList, setInvitedCandidatesList] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { state } = useLocation();
  const { id, roleName } = state;
  const hiddenFileInput = React.useRef(null);
  const [editId, setEditId] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFromWebsite, setIsFromWebsite] = useState(false);
  const [sendForReviewModalOpen, setSendForReviewModalOpen] = useState(false);
  const [
    selectedCandidatesForReviewEmail,
    setSelectedCandidatesForReviewEmail,
  ] = useState([]);
  const [value, setValue] = React.useState(0);
  const windowSize = useWindowSize();
  const { showAlert } = useAlert();
  const isMobile = windowSize.width <= 768;
  const [reviewsData, setReviewsData] = useState([]);

  const handleSetJobDetails = (data) => {
    setJobDetails(data);
  };

  const handleSetInvitedCandidatesList = (data) => {
    setInvitedCandidatesList(data);
    const datas = data.filter(
      (item) =>
        item.InterviewPool.length &&
        [2, 3, 5, 6].includes(item.InterviewPool?.[0].status)
    );
    setReviewsData(datas);
  };

  const getInvitedCandidatesList = async () => {
    setIsLoadingData(true);
    await getInvitedCandidates(handleSetInvitedCandidatesList, Number(id));
    setIsLoadingData(false);
  };

  useEffect(() => {
    (async () => {
      await getJobRoleDetails(handleSetJobDetails, Number(id), () =>
        console.log("Failed to fetch job details")
      );
      getInvitedCandidatesList();
    })();
  }, [id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSheetData([]);
    setOpen(false);
    getInvitedCandidates(handleSetInvitedCandidatesList, Number(id));
  };

  const handleClickFileUpload = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    readDataFromFile(file);
  };

  const findDataFromExcelJson = (keyNames, namesSet) => {
    for (let keyName of keyNames) {
      if (namesSet.includes(keyName)) {
        return keyName;
      }
    }
    return "";
  };

  const readDataFromFile = async (file) => {
    const data = await file.arrayBuffer();
    const wb = XLSX.read(data);
    const sheetName = wb.SheetNames[0];
    const workSheet = wb.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(workSheet);
    const keyNames = Object.keys(jsonData[0]);
    const convertedData = jsonData.map((item) => {
      return {
        fullName: item[findDataFromExcelJson(keyNames, keyName)],
        email: item[findDataFromExcelJson(keyNames, keyEmail)],
        countryCode: item[findDataFromExcelJson(keyNames, keyCountryCode)],
        mobileNumber: item[findDataFromExcelJson(keyNames, keyMobileNo)],
      };
    });
    setSheetData([...convertedData]);
  };

  const getCounts = (type) => {
    if (type === "shortlisted") {
      return invitedCandidatesList?.filter(
        (item) =>
          item.InterviewPool.length &&
          item.InterviewPool[item.InterviewPool.length - 1].status === 3
      )?.length;
    } else {
      return invitedCandidatesList?.filter(
        (item) =>
          item.InterviewPool.length &&
          item.InterviewPool[item.InterviewPool.length - 1].status === 5
      )?.length;
    }
  };

  const handleEdit = (id, fromWebsite) => {
    setEditId(id);
    setIsFromWebsite(fromWebsite);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    getInvitedCandidates(handleSetInvitedCandidatesList, Number(id));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div sx={{ p: 3 }}>{children}</div>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSendReviewEmail = async (email) => {
    // console.log(selectedCandidatesForReviewEmail, email);
    if (email) {
      const data = {
        email,
        roleId: id,
        candidateIds: selectedCandidatesForReviewEmail,
      };
      await sendReviewInvitesByRole(
        data,
        handleSendReviewSuccess,
        handleSendReviewError
      );
    }
    setSendForReviewModalOpen(false);
  };

  const handleSendReviewSuccess = (res) => {
    showAlert(res.message, "success");
  };

  const handleSendReviewError = (message) => {
    showAlert(message, "error");
  };

  return (
    <div style={{ padding: "1rem" }}>
      <DashboardCountCards roleId={id} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Grid container justifyContent="flex-start" alignItems="left">
          <Grid item xs={8}>
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <GoBackButton
                sx={{
                  color: "#dcdcdc",
                  marginTop: "10px",
                  marginLeft: "-12px",
                  marginRight: "12px",
                  padding: "2px 15px",
                  borderRight: "1px solid #c4c5c5",
                  height: "36px",
                  cursor: "pointer",
                }}
              />

              <PageHeader
                isStartAlign
                pageTitleText={
                  roleName ? roleName : jobDetails?.roleDetails?.roleName
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack
              direction="row"
              spacing={4}
              justifyContent="flex-end"
              alignItems={"flex-end"}
            >
              <TableActionButton>
                <Button
                  startIcon={<SendRoundedIcon />}
                  btnText="Send for Review"
                  variant={
                    selectedCandidatesForReviewEmail?.length == 0
                      ? "text"
                      : "outlined"
                  }
                  onClick={() => setSendForReviewModalOpen(true)}
                  disabled={selectedCandidatesForReviewEmail?.length == 0}
                />
                <Button
                  startIcon={<AddIcon />}
                  btnText="Add Candidates"
                  variant={
                    selectedCandidatesForReviewEmail?.length == 0
                      ? "outlined"
                      : "text"
                  }
                  onClick={handleClickOpen}
                  disabled={selectedCandidatesForReviewEmail?.length > 0}
                  style={{
                    marginLeft: "10px",
                  }}
                />
              </TableActionButton>
            </Stack>
          </Grid>
        </Grid>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="candidate-listing-table-tabs"
        >
          <Tab label="Video Interviews" {...a11yProps(0)} />
          <Tab label="Applications" {...a11yProps(1)} />
        </Tabs>

        <CustomTabPanel
          value={value}
          index={0}
          style={{ padding: isMobile ? "0px" : "8px 0px" }}
        >
          {isLoadingData ? (
            <Loader />
          ) : reviewsData.length > 0 ? (
            <VideoInterviewListing
              data={reviewsData}
              setSelectedCandidatesForReviewEmail={
                setSelectedCandidatesForReviewEmail
              }
              selectedCandidatesForReviewEmail={
                selectedCandidatesForReviewEmail
              }
            />
          ) : (
            <Title level="h6" text="No Video interviews." />
          )}
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          style={{ padding: isMobile ? "0px" : "8px 0px" }}
        >
          <ApplicationListing />
          {/* <CandidateListingTable
            filterName={"Video"}
            isCompleteList
            invitedCandidatesList={interviewsData}
            roleId={id}
            getInvitedCandidatesList={getInvitedCandidatesList}
            loading={isLoadingData}
            handleEdit={handleEdit}
            roleDetails={roleName ? roleName : jobDetails?.roleDetails}
          /> */}
        </CustomTabPanel>
      </Box>
      {open && (
        <Dialog
          size="md"
          open={open}
          close={handleClose}
          actionWithTitle={
            <>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <H3> Add Candidates </H3>
                </Grid>
                {/* <Grid item xs={2}>
                  <Button
                    disabled
                    startIcon={<DescriptionOutlinedIcon />}
                    btnText="Download template"
                    variant="text"
                    onClick={async () => {
                      setDownloading(true);
                      await downloadCsvTemplate();
                      setDownloading(false);
                    }}
                    sx={{ marginRight: 5 }}
                    loading={downloading}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    disabled
                    startIcon={<FileUploadOutlinedIcon />}
                    btnText="Upload CSV File"
                    variant="text"
                    onClick={() => handleClickFileUpload()}
                    sx={{ marginRight: 5 }}
                  />
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                    accept=".xls,.xlsx,csv"
                  />
                </Grid> */}
              </Grid>
            </>
          }
          children={
            <AddCandidate
              handleClose={handleClose}
              roleId={id}
              sheetData={sheetData}
            />
          }
        />
      )}

      {sendForReviewModalOpen && (
        <SendViaEmail
          isOpen={sendForReviewModalOpen}
          onClose={() => setSendForReviewModalOpen(false)}
          handleSendEmail={handleSendReviewEmail}
        />
      )}
    </div>
  );
};

export default JobDetail;

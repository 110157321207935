import React from "react";
import { Grid } from "@mui/material";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";

import { Tag, Title } from "components";

import { CardContainer, CardImg, CardText, TitleContainer, NumberCount, HrsText, TagBlock } from "./card.styles.";

const Card = ({ img, tagLabel, cardTitle, cardText, isNumbered, count, statusTagLabel, showTag = true }) => {
  return (
    <CardContainer num={isNumbered}>
      {statusTagLabel && (
        <TagBlock>
          <Tag label={statusTagLabel} type={statusTagLabel} />
        </TagBlock>
      )}

      <div>
        {isNumbered ? (
          <NumberCount styledColor={cardTitle}>
            {cardTitle === "Total Time Saved" && <HrsText>hrs</HrsText>}
            {count}
          </NumberCount>
        ) : (
          <CardImg src={img} alt={img} />
        )}
        {!isNumbered && showTag && (
          <Tag icon={<WorkspacePremiumIcon className="yellow" />} type="blue" label={tagLabel} />
        )}
        <TitleContainer>
          <Title level="body" text={cardTitle} />
        </TitleContainer>
        <CardText>{cardText}</CardText>
      </div>
    </CardContainer>
  );
};

export default Card;

import React, { useState, useEffect } from "react";
// import ProgressBar from "@ramonak/react-progress-bar";

import { CardMedia } from "@mui/material";
import {
  VideoContainer,
  ReviewCardContainer,
  PlayButton,
  ThumbnailVideoContainer,
} from "assets/styles/main.styles";
import Play from "assets/images/play.jpg";

const VideoThumbnail = ({ thumbnail, vidSrc }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [thumbnailData, setThumbnailData] = useState(null);

  const handleImageError = () => {
    //to reload the image
    setThumbnailData(`${thumbnailData}?v=${Date.now()}`);
  };

  useEffect(() => {
    setThumbnailData(thumbnail);
  }, [thumbnail]);

  return (
    <>
      {isOpen ? (
        <VideoCard vidSrc={vidSrc} />
      ) : (
        <>
          {thumbnailData && (
            <ThumbnailVideoContainer isClickable={true}>
              <VideoContainer>
                <img
                  className="thumbnail"
                  src={thumbnailData}
                  alt="thumbnail"
                  onError={handleImageError}
                />
                <PlayButton>
                  <img src={Play} alt="play" onClick={() => setIsOpen(true)} />
                </PlayButton>
              </VideoContainer>
            </ThumbnailVideoContainer>
          )}
        </>
      )}
    </>
  );
};

export default VideoThumbnail;

function VideoCard({ vidSrc }) {
  return (
    <>
      {vidSrc && (
        <VideoContainer>
          <CardMedia component="video" controls src={vidSrc} autoPlay />
        </VideoContainer>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";

import { getInvitedCandidates, getJobRoleDetails } from "config/api.service";
import { useLocation } from "react-router-dom";
import { Dialog } from "components";
import PublicIcon from "@mui/icons-material/Public";
import { Tooltip } from "@mui/material";
import ResumePreview from "components/ResumePreview/ResumePreview";
import useWindowSize from "hooks/useWindowSize";
import EditCandidate from "../../../JobDetail/components/EditCandidate";
import CandidateListingTable from "../../../JobDetail/components/CandidateListingTable";

const ApplicationListing = () => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const { state } = useLocation();
  const { id, roleName } = state;
  const [jobDetails, setJobDetails] = useState({});
  const [invitedCandidatesList, setInvitedCandidatesList] = useState([]);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const [editId, setEditId] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isFromWebsite, setIsFromWebsite] = useState(false);
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
  const [resumePath, setResumePath] = useState("");

  const getInvitedCandidatesList = async () => {
    await getInvitedCandidates(handleSetInvitedCandidatesList, Number(id));
  };

  useEffect(() => {
    (async () => {
      setIsLoadingData(true);
      await getJobRoleDetails(handleSetJobDetails, Number(id), () =>
        console.log("Failed to fetch job details")
      );
      getInvitedCandidatesList();
    })();
  }, [id]);

  const handleSetJobDetails = (data) => {
    setJobDetails(data);
  };

  const handleSetInvitedCandidatesList = (data) => {
    setInvitedCandidatesList(data);
    setIsLoadingData(false);
  };

  const handleEdit = (id, fromWebsite) => {
    setEditId(id);
    setIsFromWebsite(fromWebsite);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    getInvitedCandidates(handleSetInvitedCandidatesList, Number(id));
  };
  const handleResumePreview = () => {
    setIsResumePreviewOpen(false);
    setResumePath("");
  };

  const interviewsData = invitedCandidatesList.filter(
    (item) =>
      item.InterviewPool.length && [1].includes(item.InterviewPool?.[0].status)
  );

  return (
    <>
      <CandidateListingTable
        filterName={"Video"}
        isCompleteList
        invitedCandidatesList={interviewsData}
        roleId={id}
        getInvitedCandidatesList={getInvitedCandidatesList}
        loading={isLoadingData}
        handleEdit={handleEdit}
        roleDetails={roleName ? roleName : jobDetails?.roleDetails}
      />
      <Dialog
        size="md"
        open={isEditModalOpen}
        close={handleEditModalClose}
        title={
          <>
            Edit Candidate
            {isFromWebsite && (
              <Tooltip placement="bottom" arrow>
                <PublicIcon sx={{ marginLeft: "10px", opacity: 0.5 }} />
              </Tooltip>
            )}
          </>
        }
        children={
          <EditCandidate
            handleClose={handleEditModalClose}
            roleId={id}
            editId={editId}
          />
        }
      />
      <ResumePreview
        isResumePreviewOpen={isResumePreviewOpen}
        handleResumePreview={handleResumePreview}
        resumePath={resumePath}
      />
    </>
  );
};

export default ApplicationListing;

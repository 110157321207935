import styled, { css } from "styled-components";
import CheckMark from "../images/icons/check.svg";

// Login

export const LoginContainer = styled.div`
  height: 100%;
  min-height: 100vh;
`;

// export const LogoImage = styled.img`
//   padding-top: 100px;
//   padding-bottom: 80px;
//   margin-left: auto;
//   margin-right: auto;
// `;

export const LoginBanner = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const FieldBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  height: 100%;
  padding: 0 160px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
  h1 {
    margin-bottom: 30px;
  }
`;

export const LinkLarge = styled.a(
  ({
    theme: {
      colors: { blue },
      fontWeight,
    },
  }) => css`
    font-weight: ${fontWeight.semibold};
    text-decoration: none;
    text-align: left;
    margin-top: 5px;
    color: ${blue};
  `
);

export const LinkSmall = styled(LinkLarge)(
  ({ theme: { fontSize, fontWeight } }) => css`
    display: block;
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.regular};
    margin-top: 10px;
  `
);

export const RegisterNow = styled.h4`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 30px;
`;

export const BlockMargin = styled.div`
  margin-bottom: ${(props) => (props.size ? `${props.size}px` : "30px")};
`;

// Login End

export const TableTagContainer = styled.div`
  margin: 0;
`;

export const Box = styled.div(
  ({
    theme: {
      colors: { white, grey, whiteGrey },
    },
  }) => css`
    padding: 20px;
    margin-top: 20px;
    background: ${white};
    border: 1px solid ${grey};
    box-shadow: ${(props) =>
      props.isGrey
        ? "none"
        : "4px 4px 10px rgba(0, 0, 0, 0.04), -4px -4px 10px rgba(0, 0, 0, 0.04)"};

    border-radius: ${(props) => (props.isGrey ? "4px" : "12px")};
    background-color: ${(props) => (props.isGrey ? whiteGrey : white)};
  `
);

export const CardBlock = styled.div`
  margin: ${(props) => (props.noTopMargin ? "0px 0 20px 0" : "10px 0 20px 0")};
`;

export const BackButton = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const PageHeaderItem = styled.div`
  margin-left: 10px;
  margin-top: 4px;
`;

export const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  margin: -5px 0;
  .MuiAvatar-root {
    margin-right: 10px;
    margin-top: 4px;
    width: 28px;
    height: 28px;
    font-size: 10px;
    font-weight: 800;
    bgcolor: #ff5900;
  }
  .MuiChip-root {
    margin: 0 10px;
  }
`;

export const AvatarBlue = styled.div`
  margin-right: 10px;
  margin-top: 4px;
  width: 28px;
  height: 28px;
  font-size: 10px;
  font-weight: 800;
  bgcolor: #ff5900;
  border-radius: 50%;
  border: 2px solid #ff5900;
`;

export const TableButtonContainer = styled.div`
  margin: -5px 0;
  display: ${(props) => (props.isFlex ? `flex` : "block")};
  align-items: center;
  .MuiRating-root {
    margin-right: 5px;
  }
  > div {
    margin-right: 10px;
  }
`;

export const TableActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin-left: auto;
  z-index: 10;
`;

export const ContentCenter = styled.div`
  text-align: center;
`;

export const CompanyLogo = styled.img`
  margin-bottom: 10px;
  max-width: 120px;
  width: 100%;
  display: block;
  object-fit: cover;
`;

export const CandidateCompanyLogo = styled(CompanyLogo)`
  margin: auto;
`;

export const ListFlex = styled.ul`
  list-style: none;
  display: flex;
  justify-content: left;
  align-items: left;
`;

export const ListFlexItem = styled.li(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f16};
    font-weight: ${fontWeight.light};
    line-height: 19px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 768px) {
      font-size: ${fontSize.f14};
      line-height: 18px;
    }
  `
);

export const HideInMobileView = styled.div`
  @media (max-width: 567px) {
    display: none;
  }
`;

export const InterviewIllustrationImage = styled.img`
  width: 100%;
`;

export const CheckList = styled.ul`
  margin-top: 5px;
  list-style: number;
`;

export const CheckListItem = styled.li(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f16};
    font-weight: ${fontWeight.regular};
    line-height: 22px;
    margin-bottom: 16px;
    // &::before {
    //   content: url(${CheckMark});
    //   display: inline-flex;
    //   margin-left: -35px;
    //   margin-right: 15px;
    //   padding-top: 10px;
    //   position: absolute;
    // }
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 768px) {
      font-size: ${fontSize.f14};
      line-height: 18px;
      margin-bottom: 10px;
    }
    span {
      font-weight: bold;
    }
  `
);

export const TermsCheckbox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 16px;
  button {
    margin-left: -10px;
    font-size: 16px;
    text-decoration: underline;
  }
  @media (max-width: 768px) {
    .MuiTypography-root {
      font-size: 14px !important;
    }
    button {
      font-size: 14px !important;
    }
  }
`;

export const DeviceTestButtons = styled.div`
  button {
    margin-bottom: 20px;
    background: #ddf3ff;
    border: 1px solid #2e6ae0;
    border-radius: 100px;
    width: auto !important;
  }
`;

export const SectionContainer = styled.div`
  margin: 5px 0;
  @media (max-width: 768px) {
    .MuiContainer-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const ActionRightButton = styled.div`
  display: flex;
  justify-content: flex-end;
  & button {
    width: 220px !important;
  }
  @media (max-width: 768px) {
    display: block;
    & button {
      width: 100% !important;
    }
  }
`;

export const GotItWrapper = styled(ActionRightButton)`
  margin: 5em;
  margin-bottom: 2em;
  margin-top: -5em;
  & button {
    width: 190px !important;
  }
`;

export const CodeVideoBlock = styled.div`
  .code-editor {
    margin-top: 180px;
    height: 100vh;
    border: 1px solid #d2d2d2;
  }
  .console {
    background-color: red;
    height: 300px;
    width: 100%;
  }

  .mirror {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .video {
    width: 100%;
    margin: auto;
    height: 100vh;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
  }

  .video-controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 30px;
    width: 90%;
    flex-wrap: wrap;
    background: rgba(217, 217, 217, 0.86);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 20px;
    border: 0px solid rgba(217, 217, 217, 0.86);
    transform: translateY(150%);
    transition: all 0.3s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: flex-start;
    align-items: center;
  }

  input[type="range"] {
    -webkit-appearance: none !important;
    background: rgba(217, 217, 217, 0.86);
    border-radius: 20px;
    height: 8px;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }

  .progress-bar {
    width: ${(props) => props.progress && `${props.progress - 1.1}%`};
    height: 8px;
    background: #2e6ae0;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    position: absolute;
    @media (max-width: 768px) {
      width: ${(props) => props.progress && `${props.progress - 3}%`};
    }
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    cursor: pointer;
    height: 16px;
    background: white;
    border-radius: 50%;
    width: 16px;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }

  input[type="range"]::-moz-range-progress {
    -webkit-appearance: none !important;
    background-color: green;
    transition: all 0.3s ease-in-out;
  }

  .velocity {
    appearance: none;
    background: none;
    color: white;
    outline: none;
    border: none;
    text-align: center;
    font-size: 16px;
  }
`;

export const CandidateInterviewContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  // height: 100%;
  object-fit: cover;
  background-color: #ffffff;
  .video-capture {
    width: auto;
    margin: auto;
    height: 100vh;
    max-width: 100%;
    object-fit: cover;
  }
  .MuiContainer-root {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const InfoActionsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CandidateInfoBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;

export const ActionButtons = styled.div`
  margin: 0;
`;

export const CIBBlock = styled.div`
  margin-left: 10px;
  & span {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div {
      margin-left: 5px;
    }
  }
`;

export const SubtitleInfo = styled.p(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f12};
    font-weight: ${fontWeight.light};
    line-height: 19px;
    margin-bottom: 5px;
  `
);

export const FeedbackCardContainer = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const RatingBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0;
  h4 {
    margin-left: 10px;
    margin-bottom: -3px;
  }
  .MuiRating-sizeLarge {
    font-size: 38px;
  }
`;

export const ProgressWrapper = styled.div(
  ({
    theme: {
      colors: { orange, white, lightGrey, blueGrey, blue, green },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    width: 100%;
    margin-top: 40px;
    h6 {
      color: ${blueGrey};
      margin-bottom: 10px;
    }

    .wrapper {
      border-radius: 16px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .container {
      background-color: ${lightGrey};
      border-radius: 16px;
      &.Stress div {
        background-color: ${orange} !important;
      }
      &.Confidence div {
        background-color: ${blue} !important;
      }
      &.Excitement div {
        background-color: ${green} !important;
      }
    }
    .label {
      font-family: ${base};
      font-size: 12px;
      color: ${white};
      font-weight: ${fontWeight.regular};
      line-height: 17px;
      margin-right: 10px;
    }
  `
);

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row !important;
  gap: ${({ gap }) => `${gap}px` || "0px"};
  .delete-button {
    width: 24px;
  }
`;

export const FlexRowAlignEnd = styled(FlexRow)`
  align-items: flex-end;
`;

export const FlexRowAlignCenter = styled(FlexRow)`
  align-items: center;
`;

export const InviteModalContainer = styled.div`
  margin: 20px 0 0 0;
`;

export const ModalActionButtons = styled.div`
  margin: 20px 0 0 0;
`;

export const BasicAnswerContainer = styled.div`
  margin: 20px 0 0 0;
`;

export const BasicQuestionItem = styled.h4(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.medium};
    line-height: 19px;
  `
);

export const BasicAnswerItem = styled.p(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.regular};
    line-height: 22px;
    margin-top: 6px;
    padding-left: 12px;
  `
);

export const BasicQuestionAnswer = styled.div`
  margin-left: 35px;
`;

export const CandidateWrapper = styled.div`
  justify-content: center;
  max-width: 100%;
  margin: auto;
  margin-top: 20px;

  .candidate-list label + .css-a8oc15-MuiInputBase-root {
    margin-top: 0px;
    margin-bottom: 24px;
  }
  .add-button {
    margin: 20px auto;
  }
`;

export const CandidateItemList = styled.ul``;
export const CandidateItem = styled.li`
  .delete-button {
    visibility: hidden;
    // margin-bottom: 24px;
  }
  &:hover .delete-button {
    visibility: visible;
  }
`;

export const IWSubtitle = styled.h5(
  ({
    theme: {
      colors: { blueGrey, primaryBlue },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f16};
    font-weight: ${fontWeight.regular};
    line-height: 28px;
    width: 70%;
    margin: 10px auto auto auto;
    span {
      color: ${primaryBlue};
    }
    @media (max-width: 768px) {
      width: 100%;
      font-size: ${fontSize.f14};
      line-height: 20px;
    }
  `
);

export const BodyTextFeedback = styled.p(
  ({
    theme: {
      colors: { blueGrey, primaryBlue },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f16};
    font-weight: ${fontWeight.regular};
    line-height: 22px;
    margin-top: 5px;
    @media (max-width: 768px) {
      width: 100%;
      font-size: ${fontSize.f14};
      line-height: 20px;
    }
  `
);

export const ProgressStripBlock = styled.div`
  position: relative;
  width: 90%;
  .label {
    position: absolute;
    right: -10%;
    color: #1b1b1b;
  }
`;

export const ProgressLabel = styled.p(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f12};
    font-weight: ${fontWeight.medium};
    line-height: 22px;
    margin-top: -10px;
    margin-bottom: 10px;
  `
);

export const RatingOverall = styled.p(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f20};
    font-weight: ${fontWeight.medium};
    line-height: 22px;
    margin-bottom: 20px;
    margin-top: 30px;
    text-align: center;
  `
);

export const RatingStatus = styled.h1(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: 42px;
    font-weight: ${fontWeight.semibold};
    line-height: 22px;
    margin-bottom: 0;
    text-align: center;

    .tag-status {
      transform: scale(3.5);
      margin-top: 30px;
    }
  `
);

export const IWDBlock = styled.div``;

export const InputWithDropDown = styled.div`
  position: relative;
  ${IWDBlock} {
    position: absolute;
    right: 0;
    top: -46px;
    width: 200px;
  }
`;

export const UploadCard = styled.div`
  padding: 20px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  .upload-btn {
    max-width: 200px !important;
  }
`;
export const UploadImage = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
export const AlertSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 24px;
  padding-top: 5px;
  align-items: center;
`;

export const FullScreenContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  & > .img-wrapper > .educate-img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }
  .webcam {
    width: 100%;
    margin: auto;
    height: 100vh;
    object-fit: cover;
  }
  .mirror {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  @media (max-width: 768px) {
    .webcam {
      /* Full height */
      height: 100vh;

      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: fill;
    }
  }
`;

export const FullScreenContainerWithOverflow = styled(FullScreenContainer)`
  overflow: auto;
  .webcam {
    height: auto;
    margin-top: -5em;
  }
`;

export const LoaderFullScreenContainer = styled(FullScreenContainer)`
  background-color: #000;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => `${gap}px` || "0px"};
`;

export const PaddingBlock = styled.div`
  padding: ${({ padding }) => `${padding}px` || "0px"};
`;

export const ActionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  width: 150px; /* Need a specific value to work */
  transform: translateX(5%);
  bottom: ${(props) =>
    props.isVideo
      ? "50px"
      : props.isVideoInterviewInstruction
      ? "5px"
      : "40px"};
  justify-content: center;
  align-items: center;
  gap: 11.25px;
`;

export const IconWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 500;
    color: white;
  }
`;

export const IconWrapper = styled.div(
  ({
    theme: {
      colors: { primaryBlue, whiteGrey, grey },
    },
  }) => css`
    display: flex;
    background: ${(props) =>
      props.isShowingCamera
        ? props.isDisabled
          ? whiteGrey
          : "transparent"
        : "rgba(255, 255, 255, 0.86)"};
    border-radius: 50%;
    padding: 10px;
    height: 50px;
    width: 50px;
    @media (max-width: 768px) {
      height: 40px;
      width: 40px;
    }
    justify-content: center;
    align-items: center;
    .action-icon {
      font-size: 3rem;
      color: ${(props) => (props.isDisabled ? grey : primaryBlue)};
      rotate: 180deg;
    }
  `
);
export const TooltipTitle = styled.h4(
  ({
    theme: {
      colors: { lightGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${lightGrey};
    font-family: ${base} !important;
    font-size: ${fontSize.f12};
    font-weight: ${fontWeight.medium};
    line-height: 20px;
  `
);

export const TooltipText = styled.p(
  ({
    theme: {
      colors: { white },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${white};
    font-family: ${base} !important;
    font-size: ${fontSize.f12};
    font-weight: ${fontWeight.light};
    line-height: 19px;
  `
);

export const CreateJobContainer = styled.div`
  .label-grid label {
    display: flex;
    align-items: center;
  }
  .help-icon {
    margin-left: 5px;
  }
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 2vw;
  top: 5vh;
`;

export const LoginContent = styled.div`
  height: 100%;
  min-height: 90vh;
  padding: 0 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignInContainer = styled.div(
  ({
    theme: {
      colors: { black, red, darkBlue },
      fontWeight,
      fontSize,
    },
  }) => css`
    width: 100%;
    h1 {
      text-align: center;
      font-weight: bold;
      margin-bottom: 1em;
    }
    label {
      color: ${black};
      font-weight: ${fontWeight.semibold};
      font-size: ${fontSize.f20};
      &::after {
        content: "*";
        width: 10px;
        height: 10px;
        color: ${red};
      }
    }
    button {
      background: ${darkBlue};
      text-transform: uppercase;
      padding: 1em;
      font-weight: ${fontWeight.bold};
      &:hover {
        background: ${darkBlue};
      }
    }
    input.MuiInputBase-input {
      padding: 1em;
    }
  `
);

export const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  button {
    max-width: 25em;
    margin: 20px auto;
    padding: 8px 80px;
  }
`;

export const ReviewAnswerCardContainer = styled.div`
  background: #ebf8ff;
  border-radius: 6px;
  shadow: rgba(100, 100, 111, 1) 0px 7px 29px 0px;
  padding: 16px 19px;
  cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};
`;

export const ThumbnailContainer = styled.div`
  background: #fff;
  border-radius: 10px;
  ${"" /* padding: 16px 19px; */}
  cursor: ${(props) => (props.isClickable ? "pointer" : "auto")};
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
`;

export const ThumbnailVideoContainer = styled.div`
  border: 2px solid #ebf8ff;
  cursor: pointer;
  ${"" /* max-width: 600px; */}
  width: 100%;
  border-radius: 10px;
  background: white;
  ${"" /* width: 600px; */}
`;

export const VideoContainer = styled.div`
  position: relative;
  // border-radius: 6px 6px 0 0;
  overflow: hidden;
  width: 100%;
  img.thumbnail {
    width: 100%;
    // height: 188.408px;
    border-radius: 6px;
  }
`;

export const PlayButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  img {
    max-width: 100%;
  }
`;

export const ReviewQuestionWrapper = styled.div`
 margin-top: 1em;
 background: #fff;
  font-size: 1.25rem;
height: 100px;
padding: 16px 19px;
}
  `;

export const ReviewAnswerRatingWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  padding: 6px 10px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  gap: 6px;
  flex-wrap: wrap;
  border-radius: 6px;
  background: #fff;
  overflow: hidden;
  > div {
    display: flex;
    align-items: center;
    &:first-child {
      align-items: flex-start;
    }
  }
  h4 {
    font-size: 1.25rem;
  }
  p {
    color: #2e6ae0;
    font-size: 0.8rem;
    font-weight: 500;
    padding-right: 8px;
  }
  h1 {
    padding-left: 8px;
  }
  h6 {
    font-size: 0.8rem;
    font-weight: 600;
    color: #4a5668;
  }
`;

export const ReviewCardContainer = styled.div`
  margin: 1em 0;

  .question__box {
    margin-top: 1em;
  }
  .details {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ReviewModalHeader = styled.h2`
  color: var(--primary-blue, #2e6ae0);
  font-size: 28px;
  line-height: 36px;
`;

export const VideoThumbnailContainer = styled.div`
  min-height: 100%;
  min-width: 100%;
  height: 21.125em;
  width: 37.5em;
  background-color: black;
  display: flex;
  justify-content: center;
`;

export const ForgotPasswordWrapper = styled.h4(
  ({
    theme: {
      colors: { blue },
      fontWeight,
      fontSize,
    },
  }) => css`
    font-weight: ${fontWeight.semibold};
    text-decoration: none;
    text-align: left;
    margin-top: 5px;
    color: ${blue};
    display: block;
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.regular};
    margin-top: 10px;
    cursor: pointer;
  `
);

export const AppriciationButtonWrapper = styled.div`
  margin-top: 2em;
`;

export const SettingsLogoContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const LogoUploadContainer = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: 55px;
  right: 15px;
  background: #2455b7;
  display: flex;
  align-items: center;
  justify-content: center;
  .uploadIcon {
    position: relative;
    input {
      position: absolute;
      left: -66px;
      bottom: 0px;
      opacity: 0;
    }
    svg {
      color: white;
      font-size: 1rem;
    }
  }
`;

export const FullWidthBox = styled(Box)`
  width: 90vw;
  height: 90vh;
  margin: auto;
`;

export const JobDescriptionTagContainer = styled.div`
  background: lightblue;
  border: 1px solid blue;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 1.5em;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  font-size: 0.8rem;
  svg {
    font-size: 1rem;
  }
`;

export const TableContainer = styled.div`
  .editbtn,.deletebtn,.showOnHover {
    display: none;
  }
  .tr {
    &:hover {
      .td {
        button.editbtn, button.deletebtn, .showOnHover {
          display: inline-flex;
        }
      }
    }
`;

export const LoadingContainer = styled.div`
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
`;

export const ActiveJobBadge = styled.div`
  min-width: 10px;
  height: 10px;
  background: green;
  border-radius: 50%;
  margin: auto 10px auto 2px;
`;

export const InActiveJobBadge = styled(ActiveJobBadge)`
  background: lightgrey;
`;

export const FileUploadLabel = styled.label`
  color: rgba(0, 0, 0, 0.6);
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.4375em;
  margin-bottom: 10px;
`;

export const CircularProgressContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InterviewOverviewContainer = styled.div`
  background-color: #f6f7f9;
  padding: 4em;
  min-height: 100%;

  @media (max-width: 768px) {
    padding: 2em 1em;
  }
`;

export const RetakeButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NextQuestionLoader = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2rem;
`;

export const ResumeWrapper = styled.div`
  margin-top: 25px;
`;

export const DescriptionBoxContainer = styled.div`
  height: ${({ fixedHeight }) => (fixedHeight ? "220px" : "auto")};
  overflow: hidden;
`;

export const DescriptionContainer = styled.div`
  height: ${({ fixedHeight }) => (fixedHeight ? "140px" : "auto")};
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
`;

export const FileUploadContainer = styled.div`
  input[type="file"] {
    display: none;
  }
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
`;

export const AlignRight = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  > div {
    margin-left: 10px;
  }
  button {
    width: 10em;
  }
`;

export const SelectLabel = styled.p`
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 0.8rem;
  padding-bottom: 7px;
  margin-top: -4px;
`;

export const SelectWrapper = styled.div`
  select {
    border-radius: 4px;
    position: relative;
    background-color: #fcfcfb;
    border: 1px solid #8c8c8c;
    font-size: 14px;
    width: 100%;
    padding: 6px 12px;
    outline: none;

    :focus {
      border-color: #2e6ae0;
    }
    &.error {
      border-color: #ec2f3c;
    }
  }
`;

export const ActionLink = styled.a`
  text-decoration: none;
  color: #111;
  margin-right: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

export const BackButtonHover = styled.div`
  color: "red";
  background: "green";

  &:hover {
    color: "green";
    background: "green";
  }
`;

export const FromWebsiteIcon = styled.div`
  background-color: #8fc2b0;
  margin-right: 10px;
  color: white;
  padding-inline: 4px;
  line-height: 16px;
  border-radius: 16px;
  font-size: 0.6rem;
  letter-spacing: 1px;
  height: 16px;
  margin-top: 8px;
`;

export const RemainingCharCount = styled.div`
  font-size: 0.8rem;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
`;

export const BaseQuestionLabel = styled(FileUploadLabel)`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const TimeDisplay = styled.div(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    display: flex;
    flex-direction: row;
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f12};
    font-weight: ${fontWeight.light};
    line-height: 12px;
    margin-bottom: 4px;
    > div {
      margin-left: 10px;
      font-size: ${fontSize.f9};
      font-style: italic;
    }
  `
);

export const ButtonIcon = styled.div`
  display: flex;
  flex-direction: row;
  color: #4286f5;
  margin-top: 8px;
  > a {
    font-size: 14px;
    color: #4286f5;
    margin-left: 4px;
    margin-top: 4px;
    text-decoration: none;
  }
`;

export const NameDisplay = styled.div`
  color: #1b1b1b;
  font-size: 1em;
  font-weight: 900;
  margin: ${(props) => props.margin};
`;

export const JobRoleDisplay = styled.p`
  color: #666666;
  font-size: 0.9em;
  font-weight: 800;
  margin: ${(props) => props.margin};
`;

export const JobRoleList = styled.p`
  color: #666666;
  font-size: 14px;
  font-weight: ${(props) => props.fontWeight};
`;

export const wwwIcon = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
`;

export const RatingDisplay = styled.div`
  font-size: 0.6em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ModalCommentWrapper = styled.div`
  padding: 25px 6.25em;
  position: relative;
  textarea {
    font-family: "Manrope";
    border-radius: 5px;
    border-color: lightgray;
    padding: 10px;
    min-height: 60px;
  }
`;

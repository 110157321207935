import React, { useEffect } from "react";
import { Box } from "assets/styles/main.styles";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import { format } from "date-fns";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Grid, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Button, Title } from "components";
import { testWhatsapp, testEmail, getAllWhatsappMessages } from "config/api.service";
import { whatsAppTemplates, emailTemplates, tableData } from "./data.js";

const TestPage = () => {
	//whatsapp
	const [mobile, setMobile] = React.useState(918105983598);
	const [waTemplate_name, setWaTemplate_name] = React.useState("test_template");
	const [waTemplate, setWaTemplate] = React.useState({});
	const [rows, setRows] = React.useState([]);

	//email
	const [email, setEmail] = React.useState("midhun@neointeraction.com");
	const [emTemplate_name, setEmTemplate_name] = React.useState("common");
	const [subject, setSubject] = React.useState("");
	const [emailContent, setEmailContent] = React.useState("");

	//common
	const [outputData, setOutputData] = React.useState("");
	const [outputWindow, setOutputWindow] = React.useState("");

	useEffect(() => {
		const template = whatsAppTemplates.find((item) => item.value === waTemplate_name);
		setWaTemplate(template?.template);
	}, [waTemplate_name]);

	const handleWaChange = (event) => {
		setWaTemplate_name(event.target.value);
		const filteredTemplates = whatsAppTemplates.filter((item) => item.value === event.target.value);
		if (filteredTemplates.length > 0) {
			setWaTemplate(filteredTemplates[0].template);
		}
	};
	const handleEmTemplateChange = (event) => {
		setEmTemplate_name(event.target.value);
		const filteredTemplates = emailTemplates.filter((template) => template.value === event.target.value);
		if (filteredTemplates.length > 0) {
			setSubject(filteredTemplates[0].subject);
			setEmailContent(filteredTemplates[0].emailContent);
		}
	};

	const handleComponentChange = (e, index, parameterIndex) => {
		const template = whatsAppTemplates.find((item) => item.value === waTemplate_name);
		template &&
			setWaTemplate({
				...template.template,
				components: template.template.components.map((comp, i) =>
					i === index
						? {
								...comp,
								parameters: comp.parameters.map((param, j) => (j === parameterIndex ? { ...param, text: e.target.value } : param)),
						  }
						: comp
				),
			});
	};
	const SendEmail = async () => {
		let payload = {
			email: email,
			subject: subject,
			templateName: emTemplate_name,
			content: typeof emailContent === "string" ? (emailContent.startsWith("{") ? JSON.parse(emailContent) : emailContent) : emailContent,
		};
		setOutputData(JSON.stringify(payload, null, 2));
		await testEmail(
			payload,
			(data) => {
				setOutputData(JSON.stringify(data, null, 2));
			},
			(error) => setOutputData(JSON.stringify(error, null, 2))
		);
	};

	const sendWhatsApp = async () => {
		let payload = { mobile: mobile, template: waTemplate };
		setOutputData(JSON.stringify(payload, null, 2));

		await testWhatsapp(
			payload,
			(data) => {
				console.log(data);
				setOutputData(JSON.stringify(data, null, 2));
			},
			(error) => setOutputData(JSON.stringify(error, null, 2))
		);
		//setOutputData(JSON.stringify(response, null, 2));
	};
	const ShowPayloadEmail = () => {
		setOutputWindow("Email Template -  Console");
		let payload = {
			email: email,
			subject: subject,
			templateName: emTemplate_name,
			emailContent: typeof emailContent === "string" ? (emailContent.startsWith("{") ? JSON.parse(emailContent) : emailContent) : emailContent,
		};
		setOutputData(JSON.stringify(payload, null, 2));
	};
	const ShowPayloadWhatsapp = () => {
		setOutputWindow("Whatsapp Template -  Console");
		let payload = { mobile: mobile, template: waTemplate };
		setOutputData(JSON.stringify(payload, null, 2));
	};

const prepareTableData = (tableData) => {
	let rows = [];
		for (let i = 0; i < tableData.length; i++) {
			let wdata = JSON.parse(tableData[i].entry);
			let vmsg = wdata[0]?.changes[0]?.value?.contacts
			let vmsgc = wdata[0]?.changes[0]?.value?.messages
			
			if (vmsg && vmsg.length > 0) {
				vmsg = vmsg[0];
				if (vmsgc && vmsgc.length > 0) {
						let vmsgtime = format(new Date(vmsgc[0].timestamp * 1000) ,"PPp") 
						//let vmsgstatus = wdata[0]?.changes[0]?.value?.statuses?.status

					rows.push({ name: vmsg.profile.name, time: format(vmsgtime,'PPpp'),  phoneNumber: vmsg.wa_id, message: vmsgc[0]?.text?.body });
				}
			}
			
		}
		rows = rows.sort((a, b) => new Date(a.time) - new Date(b.time));
		setRows(rows)

}

	const GetAllWhatsappMessages = async () => {
		await getAllWhatsappMessages(
			(data) => prepareTableData(data),
			(error) => setOutputData(JSON.stringify(error, null, 2))
		);
	};
	return (
		<div style={{ padding: "1rem" }}>
			{/* WhatsApp  */}
			<Grid container spacing={2}>
				<Grid item lg={4} sm={12} xs={12}>
					<Box>
						<Title level="h4" text="WhatsApp  -  Send Message" />

						<Grid container spacing={2} sx={{ marginTop: "10px" }}>
							<Grid item lg={12}>
								<FormControl fullWidth>
									<TextField id="outlined-basic" label="To Mobile Number" variant="outlined" size="small" defaultValue={mobile} onChange={(e) => setMobile(e.target.value)} />
								</FormControl>
							</Grid>
							<Grid item lg={12}>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label" size="small">
										Template
									</InputLabel>
									<Select labelId="demo-simple-select-label" id="demo-simple-select" value={waTemplate_name} label="Temlate" size="small" onChange={handleWaChange}>
										{whatsAppTemplates.map((template) => (
											<MenuItem key={template.value} value={template.value}>
												{template.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>

							<Grid item lg={12}>
								<FormControl fullWidth>
									{waTemplate?.components?.map((component, index) => {
										if (component.type === "header") {
											return (
												<React.Fragment key={index}>
													<Title level="h6" text="Header" />
													{component.parameters.map((parameter, parameterIndex) => (
														<Input
															id={`outlined-basic-${index}-${parameterIndex}`}
															startAdornment={<InputAdornment position="start"> {parameterIndex + 1}</InputAdornment>}
															style={{
																marginTop: "10px",
															}}
															key={parameterIndex}
															defaultValue={parameter.text}
															variant="standard"
															size="small"
															onChange={(e) => handleComponentChange(e, index, parameterIndex)}
														/>
													))}
												</React.Fragment>
											);
										} else if (component.type === "body") {
											return (
												<React.Fragment
													key={index}
													style={{
														marginTop: "30px",
														backgroundColor: "red",
													}}>
													<Title
														level="h6"
														text="Body"
														style={{
															marginTop: "40px",
														}}
													/>
													{component.parameters.map((parameter, parameterIndex) => (
														<Input
															id={`outlined-basic-${index}-${parameterIndex}`}
															startAdornment={<InputAdornment position="start"> {parameterIndex + 1}</InputAdornment>}
															style={{
																marginTop: "10px",
															}}
															key={parameterIndex}
															defaultValue={parameter.text}
															variant="standard"
															size="small"
															onChange={(e) => handleComponentChange(e, index, parameterIndex)}
														/>
													))}
												</React.Fragment>
											);
										} else if (component.type === "button") {
											return (
												<React.Fragment
													key={index}
													style={{
														marginTop: "30px",
														backgroundColor: "red",
													}}>
													<Title
														level="h6"
														text="Button"
														style={{
															marginTop: "40px",
														}}
													/>
													{component.parameters.map((parameter, parameterIndex) => (
														<Input
															id={`outlined-basic-${index}-${parameterIndex}`}
															startAdornment={<InputAdornment position="start"> {parameterIndex + 1}</InputAdornment>}
															style={{
																marginTop: "10px",
															}}
															key={parameterIndex}
															defaultValue={parameter.text}
															variant="standard"
															size="small"
															onChange={(e) => handleComponentChange(e, index, parameterIndex)}
														/>
													))}
												</React.Fragment>
											);
										}
									})}
								</FormControl>
							</Grid>

							<Grid
								item
								md={12}
								gap={2}
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}>
								<Button variant="outlined" btnText="View payload" onClick={() => ShowPayloadWhatsapp()} startIcon={<VisibilityOutlinedIcon />}></Button>
								<Button style={{ backgroundColor: "#25D366" }} variant="contained" btnText="Send WhatsApp Message" onClick={() => sendWhatsApp()} startIcon={<WhatsAppIcon />}></Button>
							</Grid>
						</Grid>
					</Box>

					{/* Email */}
					<Box style={{ padding: "20px" }}>
						<div style={{ marginBottom: "20px" }}>
							<Title level="h4" text="Email" />
						</div>
						<Grid container spacing={2} sx={{ marginTop: "10px" }}>
							<Grid item lg={12}>
								<FormControl fullWidth>
									<TextField id="outlined-basic" label="To Email" variant="outlined" size="small" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
								</FormControl>
							</Grid>
							<Grid item lg={12}>
								<FormControl fullWidth>
									<InputLabel id="demo-simple-select-label" size="small">
										Template
									</InputLabel>
									<Select labelId="demo-simple-select-label" id="demo-simple-select" value={emTemplate_name} label="Temlate" size="small" onChange={handleEmTemplateChange}>
										{emailTemplates.map((template) => (
											<MenuItem key={template.value} value={template.value}>
												{template.label}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
							<Grid item lg={12}>
								<FormControl fullWidth>
									<TextField id="outlined-basic" label="Subject" variant="outlined" value={subject} onChange={(e) => setSubject(e.target.value)} size="small" />
								</FormControl>
							</Grid>
							<Grid item lg={12}>
								<FormControl fullWidth>
									<TextField id="outlined-basic" label="Content" multiline variant="outlined" size="small" value={emailContent} onChange={(e) => setEmailContent(e.target.value)} />
								</FormControl>
							</Grid>
							<Grid
								item
								md={12}
								gap={2}
								style={{
									display: "flex",
									justifyContent: "flex-end",
								}}>
								<Button variant="outlined" btnText="View payload" onClick={() => ShowPayloadEmail()} startIcon={<VisibilityOutlinedIcon />}></Button>
								<Button variant="contained" style={{ backgroundColor: "#C71610" }} btnText="Send Test Email" onClick={() => SendEmail()} startIcon={<ForwardToInboxIcon />}></Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid item lg={8} sm={12} xs={12}>
				<Box>
						<Grid item lg={12}>
							<Title level="h4" text={outputWindow} style={{ marginBottom: "20px" }} />
							<FormControl fullWidth>
								<TextField id="outlined-basic" label="Output" multiline variant="outlined" value={outputData} size="small" />
							</FormControl>
						</Grid>
					</Box>
				</Grid>

				<Grid item lg={12} sm={12} xs={12}>
				
					<Box>
						<Grid
							item
							md={12}
							gap={2}
							style={{
								display: "flex",
								justifyContent: "space-between",
							}}>
							<Title level="h4" text="WhatsApp  -  Incoming Messages" />
							<Button variant="outlined" btnText="Reload Messages" onClick={() => GetAllWhatsappMessages()} startIcon={<VisibilityOutlinedIcon />}></Button>
						</Grid>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell width={150}>Name</TableCell>
										<TableCell align="right" width={100}>Mobile Number</TableCell>
										<TableCell align="right"width={170}>Time</TableCell>
										{/* <TableCell align="right"width={100}>Status</TableCell> */}
										<TableCell align="right" width={2}>Message</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row) => (
										<TableRow
											key={row.time}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}>
											<TableCell align="left" width={150}>{row.name}</TableCell>
											<TableCell align="right" width={100}>{row.phoneNumber}</TableCell>
											<TableCell align="right" width={170}>{row.time}</TableCell>
											{/* <TableCell align="right" width={100}>{row.status}</TableCell> */}
											<TableCell align="right" width={2}><Tooltip placement="left" title={row.message} arrow><VisibilityOutlinedIcon  style={{cursor: "pointer"}}/></Tooltip></TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Grid>
			</Grid>
			{/* Email  */}
		</div>
	);
};

export default TestPage;

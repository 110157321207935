import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Rating, Checkbox, Tooltip, Grid } from "@mui/material";
import { Tag, Table, Button, Modal, Title } from "components";
import { getInvitedCandidates, getRecentApplications } from "config/api.service";
import { useLocation } from "react-router-dom";
import { Dialog } from "components";
import PublicIcon from "@mui/icons-material/Public";
import useWindowSize from "hooks/useWindowSize";
import EditCandidate from "../../../JobDetail/components/EditCandidate";
import { statuses } from "config/constants";
import { TableTagContainer, AvatarBlock, TimeDisplay, TableContainer, FromWebsiteIcon } from "assets/styles/main.styles";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ResumePreview from "components/ResumePreview/ResumePreview";
import { format } from "date-fns";
import { useAlert } from "contexts/alert.context";
import { capitalizeWord, getTimePassed } from "utils";
import { Padding } from "@mui/icons-material";

const RecentApplicationListing = () => {
	const navigate = useNavigate();
	const { showAlert } = useAlert();
	const [isLoadingData, setIsLoadingData] = useState(true);
	const [data, setData] = useState([]);
	const [jobDetails, setJobDetails] = useState({});
	const [invitedCandidatesList, setInvitedCandidatesList] = useState([]);
	const windowSize = useWindowSize();
	const isMobile = windowSize.width <= 768;
	const [editId, setEditId] = useState("");
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isFromWebsite, setIsFromWebsite] = useState(false);
	const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
	const [resumePath, setResumePath] = useState("");
	const [resendInviteConfirmModalOpen, setResendInviteConfirmModalOpen] = useState(false);

	const [isLoading, setIsLoading] = useState();

	useEffect(() => {
		(async () => {
			setIsLoadingData(true);
			await getRecentApplications(handleSetJobDetails, () => console.log("Failed to fetch job details"));
		})();
	}, []);

	const handleSetJobDetails = (data) => {
		setData(data);
	};

	const handleResumePreview = () => {
		setIsResumePreviewOpen(false);
		setResumePath("");
	};

	const interviewsData = invitedCandidatesList.filter((item) => item.InterviewPool.length && [1, 6].includes(item.InterviewPool?.[0].status));
	function stringAvatar(name) {
		if (name) {
			let nameArray = name.split(" ");
			return `${nameArray[0][0] || ""}${nameArray?.[1] ? nameArray?.[1][0] : ""}`;
		}
		return;
	}

	const openResumePreview = (resumeFile) => {
		if (resumeFile) {
			setResumePath(resumeFile);
			setIsResumePreviewOpen(true);
		}
	};

	const columns = React.useMemo(
		() => [
			{
				Header: "CANDIDATE NAME",
				accessor: "name",
				width: 30,
				Cell: (row) => {
					let resumeFile = "";
					console.log();
					if (row.row.original.resumeDir.length > 0) {
						const resumeObj = JSON.parse(row.row.original.resumeDir[0].docMeta);
						resumeFile = resumeObj.Location;
					}
					const isHRInvitedCandidate = (row.row.original.fromWebsite == null || row.row.original.fromWebsite == 0) && row.row.original.status !== "Requested";
					return (
						<Tooltip placement="right" title={row.row.original.fromWebsite == true ? <>Applied via career page</> : <>Invited by HR</>} arrow>
							<AvatarBlock>
								<Avatar
									sx={{
										color: "#A2DFFF",
										border: "2px solid #C1EAFF",
										bgcolor: "#E0F5FF",
									}}>
									{stringAvatar(row.row.original.name).toUpperCase()}
								</Avatar>

								<div
									className={`${row.row.original.fromWebsite || isHRInvitedCandidate ? "table-nav-link" : ""}`}
									onClick={() => {
										if (row.row.original.fromWebsite || isHRInvitedCandidate) {
											navigate("/candidate-details", {
												state: {
													candidateId: row.row.original.id,
													roleId: row.row.original.roleId,
												},
											});
										}
									}}
									style={{
										marginRight: "5px",
										fontWeight: row.row.original.isViewed ? 500 : 900,
									}}>
									{capitalizeWord(row.row.original.name)}
								</div>
							</AvatarBlock>
						</Tooltip>
					);
				},
			},
			{
				Header: "JOB ROLE",
				accessor: "jobRole",
				width: 20,
				Cell: ({ row }) => {
					let jobeRole = row.original.jobrole.roleName;

					return <TableTagContainer>{jobeRole}</TableTagContainer>;
				},
			},
			{
				Header: "",
				accessor: "resumeDir",
				width: 10,
				align: "right",
				disableSortBy: true,
				Cell: ({ row }) => {
					let resumeFile = "";
					if (row.original.resumeDir.length > 0) {
						const resumeObj = JSON.parse(row.original.resumeDir[0].docMeta);
						resumeFile = resumeObj.Location;
					}
					const link = `https://wa.me/+91${row.original.phoneNumber}`;
					const isHRInvitedCandidate = (row.original.fromWebsite == null || row.original.fromWebsite == 0) && row.original.status !== "Requested";
					const statusId = row.original.statusId;
					return (
						<>
							{row.original.fromWebsite && <FromWebsiteIcon title="Added from website">www</FromWebsiteIcon>}
							{(row.original.fromWebsite || isHRInvitedCandidate) && (
								<>
									{resumeFile && (
										<a href="javascript:void(0)" onClick={() => openResumePreview(resumeFile)} title="View Resume">
											<DescriptionOutlinedIcon
												sx={{
													color: "#c4c5c5",
													marginRight: "10px",
													":hover": { color: "#3f50b5" },
												}}
											/>
										</a>
									)}
									<a href={link} target="_blank" title="Send WhatsApp Message">
										<WhatsAppIcon
											sx={{
												color: "#c4c5c5",
												marginRight: "10px",
												":hover": { color: "#25D366" },
											}}
										/>
									</a>
								</>
							)}
						</>
					);
				},
			},

			{
				Header: "INTERVIEW STATUS",
				accessor: "status",
				width: 5,
				Cell: ({ row }) => {
					let type = statuses[row.original.InterviewPool[0]?.status];

					return (
						<TableTagContainer>
							<Tag type={type} label={type} />
						</TableTagContainer>
					);
				},
			},

			{
				Header: "RECENT ACTIVITY",
				accessor: "updateDate",
				width: 40,
				Cell: (row) => {
					let cdate = row.row.original.InterviewPool[0].createdDate;
					const date = new Date(row.row.original.updateDate);
					const formattedDate = date ? format(date, "PPp") : "-";
					return (
						<TimeDisplay style={{ marginTop: "10px" }}>
							{formattedDate}
							<div style={{ fontStyle: "italic" }}>( {getTimePassed(date)} ) </div>
						</TimeDisplay>
					);
				},
			},
		],
		[navigate]
	);
	return (
		<>
			<TableContainer>
				<Table filterName={"Video"} columns={columns} data={data} isLoading={isLoading} defaultFilter={""} hideSearch numOfLoadingBarCols={4} hideFilter={true} />
			</TableContainer>
			<ResumePreview isResumePreviewOpen={isResumePreviewOpen} handleResumePreview={handleResumePreview} resumePath={resumePath} />
		</>
	);
};

export default RecentApplicationListing;

import styled, { css } from "styled-components";

export const SwitchContainer = styled.div(
  ({
    theme: {
      colors: { primaryBlue },
    },
  }) => css`
    .MuiSwitch-track {
      border: 2px solid ${primaryBlue} !important;
      background: transparent !important ;
    }
    .Mui-checked .MuiSwitch-track {
      border: 2px solid ${primaryBlue} !important;
    }
    .MuiSwitch-thumb {
      background: ${primaryBlue} !important;
    }
    .MuiSwitch-root {
      overflow: visible;
    }
  `
);

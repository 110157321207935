import React, { useState } from "react";

import { CandidateInterviewContainer } from "assets/styles/main.styles";
import { useLocation } from "react-router-dom";

import { getCandidateInterviewLoadDetails } from "config/api.service";
import VideoInterview from "./VideoInterview";
import { useInterviewDataContext } from "contexts/interviewData";

const CandidateInterview = () => {
  // const { state } = useLocation();
  const { interviewData } = useInterviewDataContext();
  // let inviteId = localStorage.getItem("uniqueInterviewId");
  // const interviewData = JSON.parse(localStorage.getItem("interviewData"));

  // React.useEffect(() => {
  //   if (inviteId != null) {
  //     (async () =>
  //       await getCandidateInterviewLoadDetails(inviteId, (res) => {
  //         setAnswerData(res);
  //       }))();
  //   }
  // }, [inviteId]);

  if (interviewData == null)
    return <h2>Please go back and attend the interview from start</h2>;

  return (
    <CandidateInterviewContainer>
      <VideoInterview />
    </CandidateInterviewContainer>
  );
};

export default CandidateInterview;

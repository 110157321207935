import styled, { css } from "styled-components";

export const CheckboxContainer = styled.div(
  ({
    theme: {
      colors: { primaryBlue },
    },
  }) => css`
    .Mui-checked {
      color: ${primaryBlue} !important;
    }
    .MuiCheckbox-root svg {
      fill: ${primaryBlue};
    }
  `
);

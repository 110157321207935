import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Rating, Checkbox, Tooltip, Grid } from "@mui/material";
import { Tag, Table, Button, Modal, Title } from "components";
import {
  TableTagContainer,
  AvatarBlock,
  TableButtonContainer,
  TableContainer,
  Box,
  ModalButtonWrapper,
  InviteModalContainer,
  FromWebsiteIcon,
  TimeDisplay,
} from "assets/styles/main.styles";
import BasicAnswer from "../BasicAnswer";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VideoCameraFrontOutlinedIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ResumePreview from "components/ResumePreview/ResumePreview";
import { statuses } from "config/constants";
import { appUrl } from "config/constants";
import { format } from "date-fns";
import { useAlert } from "contexts/alert.context";
import { resendCandidateInvite } from "config/api.service";
import { add, differenceInDays } from "date-fns";
import { capitalizeWord, getTimePassed } from "utils";

const TableCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox ref={resolvedRef} {...rest} className="table-checkbox" />
    </>
  );
});

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function suttleColours(string) {
  // take the input string and take the first letter and map it to the colour

  return "#48137B";
}

function stringAvatar(name) {
  if (name) {
    let nameArray = name.split(" ");
    return `${nameArray[0][0] || ""}${nameArray?.[1] ? nameArray?.[1][0] : ""}`;
  }
  return;
}

const CandidateListingTable = ({
  isCompleteList = true,
  filterName,
  starRating,
  filter,
  hideSearch,
  invitedCandidatesList,
  roleId,
  loading,
  handleEdit,
  roleDetails,
}) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [open, setOpen] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [data, setData] = useState(invitedCandidatesList);
  const [isLoading, setIsLoading] = useState(loading);
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
  const [resumePath, setResumePath] = useState("");
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [resendInviteConfirmModalOpen, setResendInviteConfirmModalOpen] =
    useState(false);
  const [resendCandidateId, setResendCandidateId] = useState(null);
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  const handleClickOpen = (details) => {
    setCandidateDetails(details);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openResumePreview = (resumeFile) => {
    if (resumeFile) {
      setResumePath(resumeFile);
      setIsResumePreviewOpen(true);
    }
  };

  const handleResend = async () => {
    setIsSendingInvite(true);
    const data = {
      candidateId: resendCandidateId,
      roleId,
    };
    await resendCandidateInvite(data, handleResendSuccess, handleSubmitFail);
    setIsSendingInvite(false);
  };

  function handleResendSuccess() {
    showAlert("Candidate Updated Successfully", "success");
    setResendInviteConfirmModalOpen(false);
  }

  function handleSubmitFail(errorMessage) {
    showAlert(errorMessage || "Something went wrong", "error");
  }

  function generateShareLink(id, roleId) {
    const link = appUrl + "/candidate-videos?id=" + id + "&role=" + roleId;
    setShareLink(link);
  }

  const columns = React.useMemo(
    () => [
      // {
      //   id: "selection",
      //   Header: "",
      //   // ({ getToggleAllRowsSelectedProps }) => (
      //   //   <div>
      //   //     <TableCheckbox {...getToggleAllRowsSelectedProps()} />
      //   //   </div>
      //   // ),
      //   Cell: ({ row }) => (
      //     <div>
      //       {row?.original?.status === "New" && (
      //         <TableCheckbox {...row.getToggleRowSelectedProps()} />
      //       )}
      //     </div>
      //   ),
      //   maxWidth: 2,
      //   disableSortBy: true,
      // },
      {
        Header: "CANDIDATE NAME",
        accessor: "name",
        width: 30,
        Cell: (row) => {
          let resumeFile = "";
          if (row.row.original.resumeDir.length > 0) {
            const resumeObj = JSON.parse(row.row.original.resumeDir[0].docMeta);
            resumeFile = resumeObj.Location;
          }
          const isHRInvitedCandidate =
            (row.row.original.fromWebsite == null ||
              row.row.original.fromWebsite == 0) &&
            row.row.original.status !== "Requested";
          return (
            <Tooltip
              placement="right"
              title={
                row.row.original.fromWebsite == true ? (
                  <>Applied via career page</>
                ) : (
                  <>Invited by HR</>
                )
              }
              arrow
            >
              <AvatarBlock>
                <Avatar
                  // sx={{
                  // 	color: row.row.original.fromWebsite == false ? "#dda2f2" : "#6ab096",
                  // 	border: row.row.original.fromWebsite == false ? "2px solid #efd5f8" : "2px solid #bbedcc",
                  // 	bgcolor: row.row.original.fromWebsite == false ? "#fbebfe" : "#d4f4e5",
                  // }}>
                  sx={{
                    color: "#A2DFFF",
                    border: "2px solid #C1EAFF",
                    bgcolor: "#E0F5FF",
                  }}
                >
                  {stringAvatar(row.row.original.name).toUpperCase()}
                </Avatar>

                <div
                  className={`${
                    row.row.original.fromWebsite || isHRInvitedCandidate
                      ? "table-nav-link"
                      : ""
                  }`}
                  onClick={() => {
                    if (row.row.original.fromWebsite || isHRInvitedCandidate) {
                      navigate("/candidate-details", {
                        state: {
                          candidateId: row.row.original.id,
                          roleId: row.row.original.roleId,
                        },
                      });
                    }
                  }}
                  style={{
                    marginRight: "5px",
                    fontWeight: row.row.original.isViewed ? 500 : 900,
                  }}
                >
                  {capitalizeWord(row.row.original.name)}
                </div>
                {/* ) : (
									<Button
										size="small"
										btnText={<div style={{ fontWeight: "800" , color:"ff5900"}}>{row.row.original.name}</div>}
										variant="text"
										onClick={() =>  showAlert("Resume not Available", "error")}
									/>
								)} */}

                {/* <Tag type="purple" label={row.row.original.id} /> */}
                {/* {row.row.original.resumeDir.length > 0 && (
									<Tooltip placement="bottom" title={<>Download Resume</>} arrow>
									<div
										style={{
										color: "unset",
										marginLeft: "10px",
										marginTop: "5px",
										opacity: 0.5,
										}}
									>
										<DescriptionIcon
										onClick={() => openResumePreview(resumeFile)}
										/>
									</div>
									</Tooltip>
								)} */}
                {/* {row.row.original.fromWebsite && (
						<Tooltip placement="bottom" title={<>Added from website</>} arrow>
							<PublicIcon sx={{ marginLeft: "10px", opacity: 0.5 }} />
						</Tooltip>
					)} */}
              </AvatarBlock>
            </Tooltip>
          );
        },
      },
      {
        Header: "",
        accessor: "resumeDir",
        width: 20,
        align: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          let resumeFile = "";
          if (row.original.resumeDir.length > 0) {
            const resumeObj = JSON.parse(row.original.resumeDir[0].docMeta);
            resumeFile = resumeObj.Location;
          }
          const link = `https://wa.me/+91${row.original.phoneNumber}`;
          const isHRInvitedCandidate =
            (row.original.fromWebsite == null ||
              row.original.fromWebsite == 0) &&
            row.original.status !== "Requested";
          const statusId = row.original.statusId;
          return (
            <>
              {row.original.fromWebsite && (
                <FromWebsiteIcon title="Added from website">
                  www
                </FromWebsiteIcon>
              )}
              {(row.original.fromWebsite || isHRInvitedCandidate) && (
                <>
                  {resumeFile && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => openResumePreview(resumeFile)}
                      title="View Resume"
                    >
                      <DescriptionOutlinedIcon
                        sx={{
                          color: "#c4c5c5",
                          marginRight: "10px",
                          ":hover": { color: "#3f50b5" },
                        }}
                      />
                    </a>
                  )}
                  <a href={link} target="_blank" title="Send WhatsApp Message">
                    <WhatsAppIcon
                      sx={{
                        color: "#c4c5c5",
                        marginRight: "10px",
                        ":hover": { color: "#25D366" },
                      }}
                    />
                  </a>
                </>
              )}
            </>
          );
        },
      },

      {
        Header: "INTERVIEW STATUS",
        accessor: "status",
        width: 10,
        Cell: ({ row }) => {
          let type = row.original.status;
          if (type === "Invite Expired") {
            type = "purple";
          }
          return (
            <TableTagContainer>
              <Tag
                type={type}
                label={
                  row.original.status === "Attempted"
                    ? "Partially Attempted"
                    : row.original.status
                }
              />
            </TableTagContainer>
          );
        },
      },
      {
        Header: "",
        accessor: "resumeDir2",
        width: 5,
        align: "right",
        disableSortBy: true,
        Cell: ({ row }) => {
          let resumeFile = "";
          if (row.original.resumeDir.length > 0) {
            const resumeObj = JSON.parse(row.original.resumeDir[0].docMeta);
            resumeFile = resumeObj.Location;
          }
          const link = `https://wa.me/+91${row.original.phoneNumber}`;
          const isHRInvitedCandidate =
            (row.original.fromWebsite == null ||
              row.original.fromWebsite == 0) &&
            row.original.status !== "Requested";
          const statusId = row.original.statusId;
          return (
            <>
              {/* {(row.original.fromWebsite || isHRInvitedCandidate) && ( */}
              <>
                {(statusId < 2 || statusId > 5) && (
                  <div
                    title="Resend Invite"
                    onClick={() => {
                      setResendCandidateId(row.original.id);
                      setResendInviteConfirmModalOpen(true);
                    }}
                  >
                    <SendRoundedIcon
                      color="info"
                      sx={{
                        color: "#c4c5c5",
                        ":hover": { color: "#0288d1" },
                      }}
                    />
                  </div>
                )}
              </>
              {/* )} */}
            </>
          );
        },
      },
      {
        Header: "RECENT ACTIVITY",
        accessor: "updateDate",
        width: 30,
        Cell: (row) => {
          const date = new Date(row.row.original.updateDate);
          const formattedDate = date
            ? format(row.row.original.updateDate, "PPp")
            : "-";
          return (
            <TimeDisplay style={{ marginTop: "10px" }}>
              {formattedDate}
              <div style={{ fontStyle: "italic" }}>
                ( {getTimePassed(date)} ){" "}
              </div>
            </TimeDisplay>
          );
        },
      },

      {
        id: "action",
        Header: "Action",
        accessor: "Action",
        width: 5,
        disableSortBy: true,
        Cell: (row) => {
          const statusId = row.row.original.statusId;
          let fitRatingForRole = 5;
          const { jobrole } = row.row.original;
          if (jobrole.roleMoreInfo) {
            const jobRoleMoreInfo = JSON.parse(jobrole.roleMoreInfo);
            fitRatingForRole = jobRoleMoreInfo?.fit;
          }
          return (
            <>
              {(statusId === 3 || statusId === 4 || statusId === 5) && (
                <TableButtonContainer isFlex>
                  <Rating
                    name={row.row.original.name}
                    value={row.row.original.review?.rating}
                    precision={0.5}
                    readOnly
                  />
                </TableButtonContainer>
              )}
              {statusId === 2 && (
                <TableButtonContainer isFlex>
                  <Button
                    startIcon={<VideoCameraFrontOutlinedIcon />}
                    size="small"
                    btnText="Review"
                    variant="text"
                    //	className="showOnHover"
                    onClick={() =>
                      navigate("/review", {
                        state: {
                          candidateId: row.row.original.id,
                          roleId,
                          fullName: row.row.original.name,
                          roleDetails: {
                            roleName: roleDetails?.roleName,
                            isKycRequired: roleDetails?.isKycRequired,
                          },
                        },
                      })
                    }
                    sx={{
                      "& .MuiButtonBase-root-MuiButton-root": {
                        fontSize: "revert",
                        lineHeight: "auto",
                        marginTop: "15px",
                      },
                    }}
                  />
                </TableButtonContainer>
              )}
              {(statusId === 3 || statusId === 4 || statusId === 5) && (
                <TableButtonContainer isFlex>
                  <Button
                    startIcon={<GradingOutlinedIcon />}
                    size="small"
                    btnText="Feedback"
                    variant="text"
                    onClick={() =>
                      navigate("/view-feedback", {
                        state: {
                          candidateId: row.row.original.id,
                          roleId,
                          fullName: row.row.original.name,
                          overallRating: row.row.original.review?.rating,
                          jobRoleFitRating: fitRatingForRole,
                          statusId:
                            row.row.original.InterviewPool[
                              row.row.original.InterviewPool.length - 1
                            ]?.status,
                        },
                      })
                    }
                    sx={{
                      "& .MuiButtonBase-root-MuiButton-root": {
                        fontSize: "revert",
                        lineHeight: "auto",
                      },
                    }}
                  />
                </TableButtonContainer>
              )}
              {statusId <= 1 && (
                <TableButtonContainer isFlex>
                  <a
                    href="javascript:void(0)"
                    onClick={() =>
                      handleEdit(
                        row.row.original.id,
                        row.row.original.fromWebsite
                      )
                    }
                    title="Edit Candidate"
                    className="showOnHover"
                  >
                    <ModeEditOutlineOutlinedIcon
                      sx={{
                        color: "#c4c5c5",
                        marginTop: "5px",
                        ":hover": { color: "#3f50b5" },
                      }}
                    />
                  </a>
                </TableButtonContainer>
              )}
              {statusId === 2 && (
                <TableButtonContainer isFlex>
                  <Button
                    startIcon={<ShareOutlinedIcon />}
                    size="small"
                    btnText="Share"
                    className="showOnHover"
                    variant="text"
                    onClick={() => {
                      const { id, roleId } = row.row.original;
                      setShareModalOpen(true);
                      generateShareLink(id, roleId);
                    }}
                  />
                </TableButtonContainer>
              )}
              {/* <a
								href="javascript:void(0)"
								onClick={() => {
									resendInvite();
								}}
								title="Resend Invite">
								<ForwardToInboxIcon sx={{ color: "#c4c5c5" }} />
							</a> */}
            </>
          );
        },
      },
    ],
    [navigate, roleDetails]
  );

  useEffect(() => {
    const newList = invitedCandidatesList.map((d) => {
      let status = d.InterviewPool.length
        ? d.InterviewPool[d.InterviewPool.length - 1].status
        : 0;
      let interviewStatus = statuses[status];

      if (status === 1) {
        let expiryDate = add(new Date(d.inviteCreatedDate), {
          days: d.expireIn,
        });
        let today = new Date();
        if (differenceInDays(today, expiryDate) > d.expireIn) {
          interviewStatus = "Invite Expired";
        }
      }

      // let fitRatingForRole = 5;
      // const { jobrole } = d;
      // if (jobrole.roleMoreInfo) {
      //   const jobRoleMoreInfo = JSON.parse(jobrole.roleMoreInfo);
      //   fitRatingForRole = jobRoleMoreInfo?.fit;
      // }
      // const overAll = d.review?.rating;

      // if (overAll != undefined) {
      //   if (overAll >= fitRatingForRole) {
      //     interviewStatus = "Fit";
      //   } else {
      //     interviewStatus = "Rejected";
      //   }
      // }
      return {
        ...d,
        status: interviewStatus,
        statusId: status,
      };
    });
    setData(newList);
  }, [invitedCandidatesList]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const handleResumePreview = () => {
    setIsResumePreviewOpen(false);
    setResumePath("");
  };

  return (
    <div className="table-container">
      <TableContainer>
        <Table
          filterName={filterName}
          columns={columns}
          data={isCompleteList ? data : data.slice(0, 5)}
          isLoading={isLoading}
          defaultFilter={filter}
          hideSearch={hideSearch}
          numOfLoadingBarCols={4}
          hideFilter={false}
          starRating={starRating}
        />
      </TableContainer>
      {open && (
        <Modal
          size="md"
          open={open}
          close={handleClose}
          title={roleDetails?.roleName}
          children={
            <BasicAnswer candidateDetails={candidateDetails} roleId={roleId} />
          }
        />
      )}
      <ResumePreview
        isResumePreviewOpen={isResumePreviewOpen}
        handleResumePreview={handleResumePreview}
        resumePath={resumePath}
      />
      {shareModalOpen && (
        <Modal
          size="md"
          open={shareModalOpen}
          close={() => setShareModalOpen(false)}
          title="Share link"
          children={
            <Box isGrey>
              <Grid container justifyContent="space-between">
                <Grid item xs={8}>
                  <Title level="h6" text={shareLink} />
                </Grid>
                <Grid item xs={2}>
                  <a href={shareLink} target="_blank">
                    <Button
                      variant="contained"
                      btnText="Open"
                      style={{ display: "inline-block" }}
                    />
                  </a>
                </Grid>
              </Grid>
            </Box>
          }
        />
      )}
      {resendInviteConfirmModalOpen && (
        <Modal
          size="sm"
          open={resendInviteConfirmModalOpen}
          close={() => setResendInviteConfirmModalOpen(false)}
          title="Invite Again"
          children={
            <InviteModalContainer>
              <Title
                level="h6"
                text="Are you sure you want to resend invite?"
              />
              <ModalButtonWrapper>
                <Button
                  variant="contained"
                  btnText="Resend Invite"
                  onClick={handleResend}
                  loading={isSendingInvite}
                />
                {/* <Button variant="outlined" btnText="Cancel" onClick={() => setResendInviteConfirmModalOpen(false)} /> */}
              </ModalButtonWrapper>
            </InviteModalContainer>
          }
        />
      )}
    </div>
  );
};

export default CandidateListingTable;

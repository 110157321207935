import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Tag } from "components";

import { FilterChips, TooltipTitle, TooltipText } from "../table.styles";

const Filter = ({ filterValue, setFilterValue }) => {
  return (
    <div>
      <FilterChips>
        <Tag
          className={filterValue === "" ? "active-all" : ""}
          label="All"
          type="violet"
          onClick={() => setFilterValue("")}
        />
        <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle>Pending Review</TooltipTitle>
              <TooltipText>
                This displays candidates who are yet to be reviewed.
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              className={filterValue === "Pending" ? "active-pending" : ""}
              label="Pending Review"
              type="Pending"
              onClick={() => {
                setFilterValue("Pending");
              }}
            />
          </span>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle> Fit</TooltipTitle>
              <TooltipText>
                This displays candidates who are fit for the role.
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              className={filterValue === "Fit" ? "active-fit" : ""}
              label="Fit"
              type="Fit"
              onClick={() => setFilterValue("Fit")}
            />
          </span>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle> Rejected</TooltipTitle>
              <TooltipText>
                This displays candidates who are not fit for the role.
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              className={filterValue === "Rejected" ? "active-NotFit" : ""}
              label="Rejected"
              type="Rejected"
              onClick={() => setFilterValue("Rejected")}
            />
          </span>
        </Tooltip>
      </FilterChips>
    </div>
  );
};

export default Filter;

import React from "react";

import { Radio as MuiRadio } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { RadioContainer } from "./radio.styles";
import Title from "components/Title";

const Radio = ({ data, label, ...rest }) => {
  return (
    <RadioContainer>
      <FormControl>
        <Title level="label" text={label} />
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          {data.map((item) => (
            <FormControlLabel
              key={item.option}
              value={item.option}
              control={<MuiRadio {...rest} />}
              label={item.option}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </RadioContainer>
  );
};

export default Radio;

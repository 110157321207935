import React from "react";

import { Modal, Title, Button } from "components";

import {
  AlertContent,
  AlertSub,
} from "components/ProgressStepper/stepper.styles";

const Alert = ({
  open,
  icon,
  alertText,
  alertSubtext,
  onClick,
  isFinalStep,
}) => {
  return (
    <div>
      <Modal
        hideClose
        size="xs"
        open={open}
        children={
          <AlertContent>
            <div>
              {icon}
              <Title level="h6" text={alertText} />
              {alertSubtext && <AlertSub>{alertSubtext}</AlertSub>}
              <Button
                variant="contained"
                btnText={isFinalStep ? "Ok" : "Proceed"}
                onClick={onClick}
              />
            </div>
          </AlertContent>
        }
      />
    </div>
  );
};

export default Alert;

import React, { useState } from "react";
import { Modal, PageHeader } from "components";
import OrganizationListingTable from "./components/OrganizationListingTable";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "assets/styles/main.styles";
import CreateOrganization from "./components/CreateOrganization";

const OrgListing = () => {
  const [open, setOpen] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [isTableUpdated, setIsTableUpdated] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSelectedOrgId(null);
  };

  const handleClickEdit = (id) => {
    setOpen(true);
    setSelectedOrgId(id);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOrgId(null);
  };

  const handleTableUpdate = () => {
    setIsTableUpdated(!isTableUpdated);
  };

  return (
    <div style={{ padding: "1rem" }}>
      <PageHeader
        buttonIcon={<AddIcon />}
        pageTitleText="Organization Listings"
        btnText="Add New Organization"
        onClick={handleClickOpen}
      />
      <Box>
        <OrganizationListingTable
          isCompleteList
          handleClickOpen={handleClickOpen}
          handleClickEdit={handleClickEdit}
          isTableUpdated={isTableUpdated}
        />
      </Box>
      {open && (
        <Modal
          size="md"
          open={open}
          close={handleClose}
          title={`${
            selectedOrgId != null ? "Update" : "Create New"
          } Organization`}
          children={
            <CreateOrganization
              handleClose={handleClose}
              id={selectedOrgId}
              handleTableUpdate={() => handleTableUpdate()}
            />
          }
        />
      )}
    </div>
  );
};

export default OrgListing;

import styled from "styled-components";

export const ModalContainer = styled.div`
  margin: 0;
`;

export const ModalContent = styled.div`
  padding: ${(props) =>
    props.hideClose ? "20px 20px 20px 20px" : "0 20px 20px 20px"};
`;

export const ModalClose = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 12px 0 0;
  margin-bottom: -36px;
  svg {
    cursor: pointer;
  }
`;

import React from "react";

import { Checkbox as MuiCheckbox } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import { CheckboxContainer } from "./checkbox.styles";

const Checkbox = ({ label, ...rest }) => {
  return (
    <CheckboxContainer>
      <FormGroup>
        <FormControlLabel control={<MuiCheckbox {...rest} />} label={label} />
      </FormGroup>
    </CheckboxContainer>
  );
};

export default Checkbox;

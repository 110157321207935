const fontFamily = {
  base: "Manrope, sans-serif",
};

const colors = {
  primaryBlue: "#2E6AE0",
  green: "#008561",
  black: "#1B1B1B",
  white: "#ffffff",
  orange: "#F77935",
  blue: "#4286F5",
  whiteGrey: "#F6F7F9",
  grey: "#D2D2D2",
  lightGrey: "#EEEEEE",
  red: "#EC2F3C",
  yellow: "#FBBC05",
  lightBlue: "#DDF3FF",
  blueGrey: "#4A5668",
  darkBlue: "#5A189A",
};

const fontSize = {
  f32: "32px",
  f24: "24px",
  f28: "28px",
  f20: "20px",
  f16: "16px",
  f14: "14px",
  f12: "12px",
};

const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const itemSize = {
  sm: "24px",
  md: "32px",
  lg: "40px",
  xl: "48px",
};

export const theme = {
  colors,
  fontFamily,
  fontSize,
  fontWeight,
  itemSize,
};

export const GlobalSize = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
  EXTRA_LARGE: "xl",
};

import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import Logout from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Arrow from "assets/images/icons/menu-down.svg";
import ListItemIcon from "@mui/material/ListItemIcon";
import InventoryIcon from "@mui/icons-material/Inventory";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Modal, Button } from "components";
import { ContentCenter } from "assets/styles/main.styles";
import { capitalizeWord } from "utils";
import { Grid } from "@mui/material";
import {
  HeaderContainer,
  LogoContainer,
  LeftMenuBlock,
  SearchMenuBlock,
  MenuItemList,
  RightMenuBlock,
  ProfileBlock,
  ProfileSection,
  MenuArrow,
  MenuIconFlex,
  AvatarContainer,
  SearchCandidatesText,
} from "../Header/header.styles";
import { webAuth } from "config/auth-config";
import { ADMIN, appUrl, RECRUITER } from "config/constants";
import { useUser } from "contexts/user.context";
import useAuthValidate from "hooks/useAuthValidate";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { searchCandidate } from "config/api.service";
import LabelBottomNavigation from "components/LabelBottomNavigation";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const HeaderAdmin = ({ notFixed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logOutHandler } = useAuthValidate();
  const { pathname } = location;
  const { userDetails, isLoading } = useUser();
  const [showVideo, setShowVideo] = useState(false);
  const url = pathname.split("/");

  const [value, setValue] = React.useState("recents");

  const navigateToHome = () => {
    if (userDetails?.userRoles.includes(RECRUITER)) {
      navigate("/job-listings");
    } else if (userDetails?.userRoles.includes(ADMIN)) {
      navigate("/org-listings");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <>
        <HeaderContainer
          notFixed={notFixed}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <LogoContainer onClick={navigateToHome}>
            <img src={Logo} alt="Logo" onClick={() => navigateToHome()} />
          </LogoContainer>

          <RightMenuBlock>
            {/* {!url[1] === "info-candidate" && ( */}
            <ProfileBlock>
              <ProfileSection onClick={handleClick}>
                <AvatarContainer>
                  <img src={userDetails?.picture} alt="profile" />
                </AvatarContainer>
                <MenuArrow>
                  <img src={Arrow} alt="Arrow" />
                </MenuArrow>
              </ProfileSection>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                disableripple
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.10))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={logOutHandler}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </ProfileBlock>
            {/* )} */}
          </RightMenuBlock>

          {/* <div style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "red" }}>
							{menuNotNeededPage() ? null : (
								<BottomNavigation value={value} onChange={handleChange}>
									{menuRoutes.map((item) => (
										<BottomNavigationAction label={item.menu} value="recents" icon={<RestoreIcon />} />
									))}
								</BottomNavigation>
							)}
						</div> */}
        </HeaderContainer>
      </>
    </>
  );
};

export default HeaderAdmin;

import styled, { css } from "styled-components";

export const H1 = styled.h1(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f32};
    font-weight: ${fontWeight.semibold};
    line-height: 44px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f24};
      line-height: 34px;
    }
  `
);

export const H2 = styled.h2(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f28};
    font-weight: ${fontWeight.bold};
    line-height: 38px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f24};
    }
  `
);

export const H3 = styled.h3(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f28};
    font-weight: ${fontWeight.semibold};
    line-height: 38px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f20};
    }
  `
);

export const H4 = styled.h4(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f20};
    font-weight: ${fontWeight.semibold};
    line-height: 27px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f16};
    }
  `
);

export const H5 = styled.h5(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f20};
    font-weight: ${fontWeight.regular};
    line-height: 27px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f16};
    }
  `
);

export const H6 = styled.h6(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f16};
    font-weight: ${fontWeight.semibold};
    line-height: 27px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f14};
    }
  `
);

export const Label = styled.label(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.medium};
    line-height: 27px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f12};
    }
  `
);

export const Body = styled.p(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f16};
    font-weight: ${fontWeight.medium};
    line-height: 27px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f14};
    }
  `
);

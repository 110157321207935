import * as icons from "assets/icons/svg";

import { SvgIconContainer } from "./Icon.styles";

const Icon = (props) => {
  return <SvgIconContainer className="icon-class" {...props} />;
};

const SVGContainer = (props) => {
  const { name, ...restProps } = props;
  const iconName = String(name);
  const SVG = icons[name];

  if (!SVG) {
    throw new Error(
      `SVG not available: [${iconName}].`
    );
  }

  return (
    <>
      <Icon {...restProps}>
        <SVG fill="currentColor" />
      </Icon>
    </>
  );
};

export default SVGContainer;

import styled, { css } from "styled-components";

export const StepperContainer = styled.div(
  ({
    theme: {
      colors: { primaryBlue },
    },
  }) => css`
    .Mui-active {
      color: ${primaryBlue} !important;
    }
    .Mui-completed {
      color: ${primaryBlue} !important;
    }
  `
);

export const TabContentContainer = styled.div(
  ({
    theme: {
      colors: { primaryBlue },
    },
  }) => css`
    margin: 40px 0;
  `
);

export const RecordButtonContainer = styled.div(
  ({
    theme: {
      colors: { primaryBlue },
    },
  }) => css`
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FullScreenContainer, ActionsContainer, IconWrapper, IconWrapperContainer } from "assets/styles/main.styles";
import Header from "./Header";

import CameraIcon from "@mui/icons-material/CameraAlt";
import AlertBox from "../ProgressStepper/components/AlertBox";
import { useNavigate } from "react-router-dom";
import useFullscreen from "hooks/useFullscreen";
import useWindowSize from "hooks/useWindowSize";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RefreshIcon from "@mui/icons-material/Refresh";
import useMediaController from "hooks/useMediaController";
import CheckIcon from "@mui/icons-material/Check";
import { updateKYC } from "config/api.service";
import { DataURLtoFile } from "utils";
import { useAlert } from "contexts/alert.context";
import highlight from "../../assets/images/placeholder-comp.svg";
import highlightMob from "../../assets/images/placeholder-mobile.svg";

const CameraWindow = ({ interviewUserData }) => {
  const EducationImgMobile =
    "https://vidrec-bkt-01.s3.ap-south-1.amazonaws.com/static-images/video_placeholder_mobile.jpg";
  const EducationImg = "https://vidrec-bkt-01.s3.ap-south-1.amazonaws.com/static-images/video_placeholder.jpg";
  const webcamRef = React.useRef(null);
  const photoRef = React.useRef(null);
  const [hasTakenPhoto, setHasTakenPhoto] = React.useState(false);
  const [educate, setEducate] = React.useState(true);
  const [showSubmitAlert, setShowSubmitAlert] = React.useState(false);
  const [isSubmitingKyc, setIsSubmittingKyc] = React.useState(false);
  const { toggle } = useFullscreen();
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const { getVideo } = useMediaController(webcamRef);
  const { showAlert } = useAlert();
  const isMobile = windowSize.width <= 768;

  function stopVideo(stream) {
    stream?.getTracks().forEach(function (track) {
      if (track.readyState === "live" && track.kind === "video") {
        track.stop();
      }
    });
  }

  useEffect(() => {
    if (!educate && !hasTakenPhoto) {
      getVideo();
    }
  }, [webcamRef, educate, hasTakenPhoto]);

  const takePhoto = () => {
    const width = windowSize.width;
    const height = windowSize.height;

    const video = webcamRef.current;
    const photo = photoRef.current;

    photo.width = width;
    photo.height = height;
    console.log({ photo });

    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);
    stopVideo(video.srcObject);
    setHasTakenPhoto(true);
  };

  const handleRetake = () => {
    let photo = photoRef.current;
    let ctx = photo.getContext("2d");
    ctx.clearRect(0, 0, photo.width, photo.height);

    setHasTakenPhoto(false);
  };

  const onSubmitSuccess = () => {
    setShowSubmitAlert(true);
  };

  const onSubmitFailure = (data) => {
    console.log("failed to submit ==>", data);
    showAlert("Your photo submission failed", "error");
  };

  const handleSubmit = async () => {
    if (hasTakenPhoto && photoRef.current) {
      const imageData = photoRef.current.toDataURL("image/jpg");
      const imageFile = DataURLtoFile(imageData, `${interviewUserData?.candidateId}-kyc-image.png`);
      const data = {
        inviteUUID: interviewUserData.inviteUUID,
        kyc: imageFile,
      };
      const formData = new FormData();
      for (let key of Object.keys(data)) {
        formData.append(key, data[key]);
      }
      setIsSubmittingKyc(true);
      await updateKYC(formData, onSubmitSuccess, onSubmitFailure);
      setIsSubmittingKyc(false);
    }
  };

  const handleQuitEducattion = () => {
    setEducate(false);
  };

  const handleDone = () => {
    setShowSubmitAlert(false);
    navigate("/interview-instructions");
    // toggle();
  };

  return (
    <FullScreenContainer>
      <Header
        item={{
          question: educate ? "First, you need to verify your identity" : "Verify your Identity with your Photo ID",
          subtitle: educate
            ? "Please hold your id in front of the web camera just as the image you see below"
            : ` Please show your government issued PHOTO ID proof (eg: Aadhar  proof, Voters ID and Driving License,PAN card etc).`,
        }}
        retakeAvailable={hasTakenPhoto ? true : false}
        handleRetake={handleRetake}
        handleSubmit={educate ? handleQuitEducattion : handleSubmit}
        enableSubmit={hasTakenPhoto ? true : educate ? true : false}
        educate={educate}
        isSubmitingKyc={isSubmitingKyc}
      />
      <div className="img-wrapper">
        <div style={{ display: hasTakenPhoto ? "flex" : "none" }}>
          <canvas ref={photoRef} className="webcam mirror" />
        </div>
        <img
          className="webcam"
          src={isMobile ? EducationImgMobile : EducationImg}
          alt="kyc-educate"
          style={{ display: educate ? "" : "none" }}
        />
        <video
          className="mirror webcam"
          ref={webcamRef}
          style={{ transform: "scaleX(-1)", position: "absolute", display: educate ? "none" : "" }}
        />
        {!educate && (
          <img
            src={isMobile ? highlightMob : highlight}
            alt="highlight"
            style={{
              position: "absolute",
              top: isMobile ? "50%" : "50%",
              left: isMobile ? "50%" : "50%",
              // right: isMobile ? "5%" : "unset",
              transform: "translate(-50%, -50%)",
              width: isMobile ? "75%" : "75%",
              // height: isMobile ? "7.5rem" : "20rem",
              // border: "2px solid green",
              // zIndex: 999,
            }}
          />
        )}
        {/* <div
          style={{
            position: "absolute",
            top: "20%",
            left: isMobile ? "5%" : "20%",
            width: isMobile ? "10rem" : "20rem",
            height: isMobile ? "12.5rem" : "25rem",
            border: "2px solid green",
            borderRadius: "50%",
            zIndex: 999,
          }}
        /> */}
      </div>
      <ActionsContainer>
        {!hasTakenPhoto && !educate ? (
          <div>
            <IconWrapper isShowingCamera={!hasTakenPhoto && !educate}>
              <IconButton
                onClick={() => {
                  // capture();
                  takePhoto();
                }}
              >
                <CameraIcon
                  style={{
                    fontSize: "5rem",
                    color: "#fff",
                  }}
                  className="action-icons"
                />
              </IconButton>
            </IconWrapper>
          </div>
        ) : null}
        {isMobile ? (
          <>
            {hasTakenPhoto && (
              <IconWrapperContainer>
                <IconWrapper>
                  <IconButton onClick={handleRetake}>
                    <RefreshIcon className="action-icon" />
                  </IconButton>
                </IconWrapper>
                <p>Retake</p>
              </IconWrapperContainer>
            )}
            {(hasTakenPhoto || educate) && (
              <IconWrapperContainer>
                <IconWrapper>
                  <IconButton onClick={hasTakenPhoto ? handleSubmit : handleQuitEducattion}>
                    {hasTakenPhoto ? (
                      <CheckIcon
                        style={{
                          rotate: "none",
                        }}
                        className="action-icon"
                      />
                    ) : (
                      <KeyboardBackspaceIcon className="action-icon" />
                    )}
                  </IconButton>
                </IconWrapper>
                <p>{hasTakenPhoto ? "Submit" : "Next"}</p>
              </IconWrapperContainer>
            )}
          </>
        ) : null}
      </ActionsContainer>
      <AlertBox
        isFinalStep
        open={showSubmitAlert}
        icon={<CheckCircleIcon className="alert-icon" />}
        alertText="Photo ID Successfully Submitted"
        alertSubtext="You can now start the video interview"
        onClick={handleDone}
      />
    </FullScreenContainer>
  );
};

export default CameraWindow;

import styled, { css } from 'styled-components';
import { GlobalSize } from 'theme/constants';

  
 
export const SvgIconContainer = styled.div(({ size, theme: { itemSize },color='inherit' }) => {
  let width;

  switch (size) {
    case GlobalSize.SMALL:
      width = itemSize.sm;
      break;
    case GlobalSize.LARGE:
      width = itemSize.lg;
      break;
    case GlobalSize.EXTRA_LARGE:
      width = itemSize.xl;
      break;
    case GlobalSize.MEDIUM:
    default:
      width = itemSize.md;
  }

  return css`
    line-height: 0;
    color: ${color};

    > svg {
      fill: currentColor;

      width: ${width};
      path: {
        fill: currentColor;
      }
    }
    width: ${width};

  `;
});


import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import Logout from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FilterTiltShiftOutlinedIcon from '@mui/icons-material/FilterTiltShiftOutlined';
import Arrow from "assets/images/icons/menu-down.svg";
import ListItemIcon from "@mui/material/ListItemIcon";
import InventoryIcon from "@mui/icons-material/Inventory";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { menuRoutes } from "./routes/routes.config";
import { Modal, Button } from "components";
import { ContentCenter } from "assets/styles/main.styles";
import {
  HeaderContainer,
  LogoContainer,
  LeftMenuBlock,
  SearchMenuBlock,
  MenuItemList,
  RightMenuBlock,
  ProfileBlock,
  ProfileSection,
  MenuArrow,
  MenuIconFlex,
  AvatarContainer,
} from "./header.styles";
import { webAuth } from "config/auth-config";
import { ADMIN, appUrl, RECRUITER } from "config/constants";
import { useUser } from "contexts/user.context";
import useAuthValidate from "hooks/useAuthValidate";
import LabelBottomNavigation from "components/LabelBottomNavigation";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CandidateSearch from "./CandidateSearch";

const Header = ({ notFixed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logOutHandler } = useAuthValidate();
  const { pathname } = location;
  const { userDetails, isLoading } = useUser();
  const [showVideo, setShowVideo] = useState(false);
  const url = pathname.split("/");


  const [value, setValue] = React.useState("recents");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigateToHome = () => {
    if (userDetails?.userRoles.includes(RECRUITER)) {
      navigate("/job-listings");
    } else if (userDetails?.userRoles.includes(ADMIN)) {
      navigate("/org-listings");
    }
  };

  const checkActive = (menu) => {
    if ("/" + url[1] === menu.link) {
      return true;
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuNotNeededPage = () => {
    return (
      url[1] === "candidate-overview" ||
      url[1] === "candidate-questions" ||
      url[1] === "candidate-interview" ||
      url[1] === "interview-videos" ||
      url[1] === "info-candidate" ||
      url[1] === "reg-submit" ||
      url[1] === "candidate-videos" ||
      url[1] === "candidate-basequestions" ||
      url[1] === "login-failed" ||
      url[1] === "reviewer-interview-data" ||
      url[1] === "candidate-completed-interview"
    );
  };

  return (
		<>
			{url[1] === "candidate-interview" || url[1] === "interview-instructions" || url[1] === "candidate-interview/kyc" ? (
				<></>
			) : (
				<>
					<HeaderContainer notFixed={notFixed}>
						<LogoContainer onClick={navigateToHome}>
							<img src={Logo} alt="Logo" onClick={() => navigateToHome()} />
						</LogoContainer>
						<LeftMenuBlock>
							{menuNotNeededPage() ? null : (
								<>
									{menuRoutes.map((item) => (
										<Link key={item.menu} to={item.link} className="link">
											<MenuItemList isActive={checkActive(item) ? true : false}>
												<MenuIconFlex>
													{item.icon}
													{item.menu}
												</MenuIconFlex>
											</MenuItemList>
										</Link>
									))}
								</>
							)}
						</LeftMenuBlock>
						<SearchMenuBlock>{menuNotNeededPage() ? null : <CandidateSearch />}</SearchMenuBlock>
						{menuNotNeededPage() ? null : (
							<RightMenuBlock>
								{/* {!url[1] === "info-candidate" && ( */}
								<Stack spacing={2} direction="row">
									<Button
										sx={{ marginTop: "6px" }}
										btnText={
											<MenuIconFlex>
												<SmartDisplayOutlinedIcon />
												Set Up Kapiree
											</MenuIconFlex>
										}
										variant="text"
										size="small"
										onClick={() => setShowVideo(true)}
									/>

									<ProfileBlock>
										<ProfileSection onClick={handleClick}>
											<AvatarContainer>
												<img src={userDetails?.picture} alt="profile" />
											</AvatarContainer>
											<MenuArrow>
												<img src={Arrow} alt="Arrow" />
											</MenuArrow>
										</ProfileSection>
										<Menu
											anchorEl={anchorEl}
											id="account-menu"
											open={open}
											onClose={handleClose}
											onClick={handleClose}
											disableripple
											PaperProps={{
												elevation: 0,
												sx: {
													overflow: "visible",
													filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.10))",
													mt: 1.5,
													"& .MuiAvatar-root": {
														width: 32,
														height: 32,
														ml: -0.5,
														mr: 1,
													},
													"&:before": {
														content: '""',
														display: "block",
														position: "absolute",
														top: 0,
														right: 14,
														width: 10,
														height: 10,
														bgcolor: "background.paper",
														transform: "translateY(-50%) rotate(45deg)",
														zIndex: 0,
													},
												},
											}}
											transformOrigin={{ horizontal: "right", vertical: "top" }}
											anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
											{userDetails?.userRoles[0] !== "ADMIN" && (
												<>
													<MenuItem onClick={() => navigate("/categories")}>
														<ListItemIcon>
															<InventoryIcon fontSize="small" />
														</ListItemIcon>
														Categories
													</MenuItem>
													<MenuItem onClick={() => navigate("/user-settings")}>
														<ListItemIcon>
															<SettingsOutlinedIcon fontSize="small" />
														</ListItemIcon>
														Settings
													</MenuItem>

													<MenuItem onClick={() => window.open("https://kapiree.atlassian.net/servicedesk/customer/portal/1")}>
														<ListItemIcon>
															<HelpOutlineIcon fontSize="small" />
														</ListItemIcon>
														Help Center
													</MenuItem>
													{/* Testing Ground */}
													{userDetails?.email === "kapireejobs@gmail.com" && (
														<MenuItem onClick={() => navigate("/global-testing")}>
															<ListItemIcon>
																<FilterTiltShiftOutlinedIcon fontSize="small" />
															</ListItemIcon>
															Testing Page
														</MenuItem>
													)}
													{/* Testing Ground Ends */}
												</>
											)}
											<MenuItem onClick={logOutHandler}>
												<ListItemIcon>
													<Logout fontSize="small" />
												</ListItemIcon>
												Logout
											</MenuItem>
										</Menu>
									</ProfileBlock>
								</Stack>
								{/* )} */}
							</RightMenuBlock>
						)}
						{/* <div style={{ position: "fixed", bottom: 0, width: "100%", backgroundColor: "red" }}>
							{menuNotNeededPage() ? null : (
								<BottomNavigation value={value} onChange={handleChange}>
									{menuRoutes.map((item) => (
										<BottomNavigationAction label={item.menu} value="recents" icon={<RestoreIcon />} />
									))}
								</BottomNavigation>
							)}
						</div> */}
					</HeaderContainer>
					{showVideo && (
						<Modal
							size={"md"}
							open={showVideo}
							close={() => setShowVideo(false)}
							title="How to setup Kapiree?"
							children={
								<ContentCenter>
									<iframe
										width="800"
										height="338"
										src="https://www.youtube.com/embed/wjwLf-Tttcg?si=j0GNXe2M8pn1ZvoJ"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										allowfullscreen
										style={{
											margin: "1em",
										}}></iframe>
								</ContentCenter>
							}
						/>
					)}
				</>
			)}
		</>
  );
};

export default Header;

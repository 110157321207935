import Answers from "pages/Interviewer/Answers/Answers";
import { useState, useEffect } from "react";
import { Footer, Header, HeaderAdmin } from "components";
import Container from "@mui/material/Container";
import { useSearchParams } from "react-router-dom";

export default function Review() {
  // const id = localStorage.getItem("candidateIdForExternal");
  // const role = localStorage.getItem("jobRoleIdForExternal");
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  let role = searchParams.get("role");
  return (
    <>
      <Header />
      <Container maxWidth={false} style={{ padding: 0 }}>
        <div style={{ paddingTop: "64px" }}>
          <Answers id={id} role={role} />
        </div>
      </Container>
      <Footer />
    </>
  );
}

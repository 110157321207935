import axios from "axios";
import { baseUrl } from "./constants";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  Authorization: `${localStorage.getItem("idToken")}`,
  "Access-Control-Allow-Origin": "/*",
};

export class HttpClient {
  BASE_URL = baseUrl;
  URL = "";
  instance = null;

  constructor(url) {
    this.URL = url;
  }

  get http() {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: this.BASE_URL,
      headers,
    });
    http.interceptors.request.use(
      (config) => {
        try {
          const token = localStorage.getItem("idToken");
          if (token != null) {
            config.headers.Authorization = `${token}`;
          }
          return config;
        } catch (error) {
          throw new Error(error);
        }
      },
      (error) => Promise.reject(error)
    );
    this.instance = http;
    return http;
  }
}

const Http = new HttpClient();

export const AxiosHttp = Http.http;

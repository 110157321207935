import React from "react";
import Dialog from "@mui/material/Dialog";

import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ModalContainer, ModalContent, ModalClose } from "./modal-styles";

import Fade from "@mui/material/Fade";
import Title from "components/Title";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const DialogModal = ({ title, size, children, open, close, hideClose, actionWithTitle, actions }) => {
  const handleClose = (reason) => {
    if (reason === "backdropClick") {
      return false;
    }
    return close;
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <ModalContainer>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => handleClose(reason)}
        aria-describedby="close"
        fullWidth
        maxWidth={size}
      >
        {hideClose ? null : (
          <ModalClose>
            <IconButton aria-label="close" onClick={close}>
              <CloseIcon />
            </IconButton>
          </ModalClose>
        )}
        {/* <ModalContent hideClose={hideClose ? true : false}>
        </ModalContent> */}
        <div
          id="scroll-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "0 4rem 1rem 2rem",
          }}
        >
          {title && <Title level="h3" text={title} />}
          {actionWithTitle && actionWithTitle}
        </div>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            {children}
          </DialogContentText>
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </ModalContainer>
  );
};

export default DialogModal;

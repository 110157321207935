import styled from "styled-components";

export const PageHeaderContainer = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  justify-content: ${(props) => (props.isStartAlign ? "flex-start" : "space-between")};
  align-items: center;
`;

export const ActionButton = styled.div`
  margin: 0;
`;

export const SubLinkWrapper = styled.div`
  text-decoration: underline;
  font-size: 1rem;
  text-transform: lowercase;

  a {
    color: #2455b7;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
`;

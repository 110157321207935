import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  Button,
  Checkbox,
  Dropdown,
  Footer,
  Header,
  Input,
  Modal,
  Radio,
  SearchWithIcon,
  Switch,
  Tag,
  Title,
  Card,
  Icon,
  Stepper,
  ProgressStepper,
  DragItemList,
} from "components";
import JobListingTable from "pages/Interviewer/JobListing/components/JobListingTable";

// images
import CardImage from "assets/images/illustrations/hire.svg";
import DeleteIcon from "@mui/icons-material/Delete";

const Uikit = () => {
  // MODAL

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // STEPPER

  const steps = [
    { title: "Hello1", content: "1" },
    { title: "Hello2", content: "2" },
    { title: "Hello3", content: "3" },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Button
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={2}>
            <Button variant="contained" btnText="Primary" />
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              btnText="Primary"
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" btnText="Secondary" />
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={<DeleteIcon />}
              variant="outlined"
              btnText="Secondary"
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="secondary" btnText="Secondary Table" />
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={<DeleteIcon />}
              variant="secondary"
              btnText="Secondary Table"
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant="text" btnText="Button Link" />
          </Grid>
          <Grid item xs={2}>
            <Button
              startIcon={<DeleteIcon />}
              variant="text"
              btnText="Button Link"
            />
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" size="small" btnText="Small" />
          </Grid>
          <Grid item xs={1}>
            <Button
              startIcon={<DeleteIcon />}
              variant="contained"
              size="small"
              btnText="Small"
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          sx={{
            marginTop: 2,
          }}
        >
          <Grid item xs={2}>
            <Button variant="contained" btnText="Primary" disabled />
          </Grid>
          <Grid item xs={2}>
            <Button variant="outlined" btnText="Secondary" disabled />
          </Grid>
          <Grid item xs={2}>
            <Button variant="text" btnText="Button Link" disabled />
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" size="small" btnText="Small" disabled />
          </Grid>
        </Grid>
      </Box>
      {/* TITLE  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Title
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={6}>
            <Title level="h1" text="Manrope 32px Semibold - h1" />
            <Title level="h2" text="Manrope 28px Bold - h2" />
            <Title level="h3" text="Manrope 28px Semibold - h3" />
            <Title level="h4" text="Manrope 20px Semibold - h4" />
            <Title level="h5" text="Manrope 20px Regular - h5" />
            <Title level="h6" text="Manrope 16px Semibold - h6" />
            <Title level="body" text="Manrope 16px Medium - body" />
            <Title level="label" text="Manrope 14px Medium - label" />
          </Grid>
        </Grid>
      </Box>
      {/* INPUT  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Input
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={3}>
            <Input
              label="Input Label"
              placeholder="Enter Text"
              name="ui-input"
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Input Label"
              placeholder="Enter Text"
              name="ui-input"
              error
              errorText="Error message here"
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Input Label"
              placeholder="Enter Text"
              name="ui-input"
              value="abd@gmail.com"
              isDisabled
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Input Label"
              placeholder="Enter Text"
              name="ui-input"
              isMulti
            />
          </Grid>
          <Grid item xs={3}>
            <Input
              type="password"
              label="Input Label"
              placeholder="Enter Text"
              name="ui-input"
              value="passwordispassword"
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              label="Dropdown Label"
              onChange={() => {}}
              value={""}
              options={[
                {
                  id: "option a",
                  optionText: "Option A",
                },
                {
                  id: "option b",
                  optionText: "Option B",
                },
              ]}
            />
          </Grid>
          <Grid item xs={2}>
            <SearchWithIcon placeholder={"Search"} />
          </Grid>
        </Grid>
      </Box>
      {/* CHECKBOX  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Checkbox / Radio / Switch
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={3}>
            <Checkbox label="Checkbox label" />
          </Grid>
          <Grid item xs={3}>
            <Radio
              label="Radio Label"
              data={[{ option: "Option A" }, { option: "Option B" }]}
            />
          </Grid>
          <Grid item xs={3}>
            <Switch label="Switch Label" />
          </Grid>
        </Grid>
      </Box>
      {/* HEADER  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          background: "#F3F3F4",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Header
        </Typography>
        <Header notFixed />
      </Box>

      {/* FOOTER  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          background: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Footer
        </Typography>
        <Footer />
      </Box>

      {/* Tags  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          background: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Tags
        </Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={1}>
            <Tag type="yellow" label="Tag Yellow" />
          </Grid>
          <Grid item xs={1}>
            <Tag type="primaryBlue" label="Tag primaryBlue" />
          </Grid>
          <Grid item xs={1}>
            <Tag type="blue" label="Tag Blue" />
          </Grid>
          <Grid item xs={1}>
            <Tag type="purple" label="Tag Purple" />
          </Grid>
          <Grid item xs={1}>
            <Tag label="Tag Grey" />
          </Grid>
        </Grid>
      </Box>

      {/* Table  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          background: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Table
        </Typography>
        <JobListingTable />
      </Box>

      {/* MODAL  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 4,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Modal
        </Typography>
        <Grid container spacing={2} alignItems="baseline">
          <Grid item xs={2}>
            <Button
              btnText="Show  Modal"
              variant="contained"
              onClick={handleClickOpen}
            />
            <Modal
              size="sm"
              open={open}
              close={handleClose}
              title="Modal Title"
              children={<div>Modal Content</div>}
            />
          </Grid>
        </Grid>
      </Box>

      {/* CARD  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 4,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Cards
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Card
              img={CardImage}
              tagLabel="Premium"
              cardTitle="Hiring Professional Interviewers"
              cardText="Examine interviewer profiles and hire the best candidate. "
            />
          </Grid>
          <Grid item xs={4}>
            <Card
              isNumbered
              cardTitle="Shortlisted"
              cardText="Candidates who meet the requirements are shortlisted"
            />
          </Grid>
        </Grid>
      </Box>

      {/* ICONS  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 4,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Icons
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1}>
            <Icon name="Save" color="#1B1B1B" size="sm" />
          </Grid>
          <Grid item xs={1}>
            <Icon name="Edit" color="#1B1B1B" size="sm" />
          </Grid>
          <Grid item xs={1}>
            <Icon name="ChevronDown" color="#1B1B1B" size="sm" />
          </Grid>
        </Grid>
      </Box>

      {/* Stepper  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 4,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Stepper
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={8}>
            <Stepper steps={steps} />
          </Grid>
        </Grid>
      </Box>

      {/* Progress stepper  */}
      <Box
        sx={{
          flexGrow: 1,
          p: 4,
          border: "1px dashed grey",
          marginBottom: 2,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Progress stepper
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={8}>
            <ProgressStepper steps={steps} />
          </Grid>
        </Grid>
      </Box>

      {/* Draggable */}

      <Box
        sx={{
          flexGrow: 1,
          p: 4,
          border: "1px dashed grey",
          marginBottom: 20,
          borderRadius: 2,
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
          sx={{
            marginBottom: 2,
          }}
        >
          Draggable
        </Typography>
        <Grid container spacing={1} alignItems="center">
          {/* <Grid item xs={12}>
            <DraggableBox />
          </Grid> */}
          {/* <Grid item xs={6}>
          <DragItem />
          </Grid> */}
          <Grid item xs={12}>
          <DragItemList />
          </Grid>
        </Grid>
      </Box>
      
    </div>
  );
};

export default Uikit;

import React, { useEffect, useRef, useState, createRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import { Button, Input, Title, Header, Footer } from "components";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
	SectionContainer,
	Box,
	UploadImage,
	UploadCard,
	BlockMargin,
	ContentCenter,
	CompanyLogo,
	ListFlex,
	ListFlexItem,
	SubmitButtonContainer,
	FlexRow,
	FlexRowAlignCenter,
	JobDescriptionTagContainer,
	DescriptionContainer,
	FileUploadLabel,
	DescriptionBoxContainer,
	FileUploadContainer,
	AlignRight,
	RemainingCharCount,
	BaseQuestionLabel,
	FlexColumn,
} from "assets/styles/main.styles";
import { ErrorText } from "components/Input/input.styles";
import CardImage from "assets/images/illustrations/file.svg";
import { errorMessages } from "config/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { getJobRoleDetailsToRegister, submitStudentInfo, getBaseQuestionsByJobRole, saveBaseQuestionFromWebsite } from "config/api.service";
import { useAlert } from "contexts/alert.context";
import useWindowSize from "hooks/useWindowSize";
import { currentBrowser, Device } from "../../../utils/index";
import axios from "axios";
import { decrypt } from "utils/encrypt";

function JobDescriptionTag({ text, icon = "" }) {
	if (text == "undefined") {
		return;
	}
	return (
		<JobDescriptionTagContainer>
			{icon}
			{text}
		</JobDescriptionTagContainer>
	);
}

const CandidateInfo = () => {
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	let [searchParams] = useSearchParams();
	const windowSize = useWindowSize();
	const isMobile = windowSize.width <= 768;
	let data = searchParams.get("id");
	let id = decrypt(data.replaceAll(" ", "+"));
	const [fieldError, setFieldError] = useState({});
	const [resumeFile, setResumeFile] = useState(null);
	const fileInput = useRef(null);
	const [roleInfo, setRoleInfo] = useState({});
	const { showAlert } = useAlert();
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const recaptchaRef = createRef();
	const [fixedHeight, setFixedHeight] = useState(true);
	const [questions, setQuestions] = useState([]);
	const [questionAnswerData, setQuestionAnswerData] = useState([]);
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [remainingCharCount, setRemainingCharCount] = useState({});
	const [location, setLocation] = useState("");
	const [device, setDevice] = useState("");
	const [browser, setBrowser] = useState("");

	const initialState = {
		data: [
			{
				email: "",
				name: "",
				firstName: "",
				lastName: "",
				phoneNumber: "",
				whatsappNo: "",
			},
		],
		roleId: id,
		// referenceToken: "cb19ab40-c1c9-11ed-afa1-0242ac120002",
		referenceToken: "",
		resume: "",
	};

	const [formData, setFormData] = useState(initialState);

	const getlocation = () => {
		axios
			.get("https://ipapi.co/json/")
			.then((response) => {
				setLocation(response.data.city + ", " + response.data.region + ", " + response.data.country);
			})
			.catch((error) => {
				console.error("Error fetching location:", error);
			});
	};

	useEffect(() => {
		getlocation();
		setBrowser(currentBrowser(window));
		setDevice(Device(window));
		if (id) {
			getJobRoleDetailsToRegister(id, handleroleInfo, (err) => console.log(err));
			getQuestions();
		}
	}, [id]);

	const onSubmitSuccess = (data) => {
		setIsSubmitting(false);
		showAlert("Submitted Successfully", "success");
		navigate("/reg-submit", {
			state: { email: data[0].email, inviteId: data.inviteUUID },
			replace: true,
		});
		// navigate("/candidate-basequestions", {
		//   state: { roleId: id, candidateId: data?.[0]?.id },
		// });
	};

	async function getQuestions() {
		let response = await getBaseQuestionsByJobRole(id);
		let { data, status } = await response?.data;
		if (status == "success") {
			setQuestions(data);
			setQuestionAnswerData(
				data.map((item) => {
					return {
						id: item.qid,
						answer: "",
					};
				})
			);
		}
	}

	const onSubmitFail = () => {
		setIsSubmitting(false);
		showAlert("Something went wrong", "error");
	};

	const convertToFormData = (data) => {
		const newData = new FormData();
		for (const [key, value] of Object.entries(data)) {
			if (Array.isArray(value)) {
				for (const item of value) {
					for (const [subKey, subValue] of Object.entries(item)) {
						newData.append(`data[${key}][${subKey}]`, subValue);
					}
				}
			} else {
				newData.append(key, value);
			}
		}
		return newData;
	};

	function onChange(captchaCode) {
		// console.log("Captcha value:", value);
		if (!captchaCode) {
			return;
		}
	}

	const handleroleInfo = (res) => {
		setRoleInfo(res);
	};

	const handleFileUpload = (e) => {
		const sizeInBytes = e.target.files[0].size;
		const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
		setFormData({
			...formData,
			resume: e.target.files[0],
		});
		setResumeFile(e.target.files[0]);
		let temp = { ...fieldError };
		delete temp.resume;
		if (sizeInMB > 5) {
			temp = { ...temp, resume: errorMessages.fileSize };
		}
		setFieldError(temp);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		validateData(e);
		setFormData((prevState) => ({
			...prevState,
			data: [
				{
					...prevState.data[0],
					[name]: value,
				},
			],
		}));
	};

	const handleOpenFileUpload = () => {
		fileInput.current.click();
	};

	const validateEmail = (email) => {
		return emailPattern.test(email);
	};

	const validateForNonAlphabets = (name) => {
		return /[a-zA-Z]+/.test(name);
	};

	const validateData = (e) => {
		if (e.target.value === undefined || e.target.value === "") {
			setFieldError({
				...fieldError,
				[e.target.name]: errorMessages.required,
			});
		} else if (e.target.name === "email" && !validateEmail(e.target.value)) {
			setFieldError({
				...fieldError,
				[e.target.name]: errorMessages.email,
			});
		} else if (e.target.name === "name" && !validateForNonAlphabets(e.target.value)) {
			setFieldError({
				...fieldError,
				[e.target.name]: errorMessages.onlyAlphablets,
			});
		} else {
			let temp = { ...fieldError };
			delete temp[e.target.name];
			setFieldError(temp);
		}
	};

	// onchange fn for answering question
	const handleQuestionAnswerChange = (e, index) => {
		let temp = [...questionAnswerData];
		temp[index] = { ...temp[index], answer: e.target.value };
		validate(e, temp[index].id);
		setQuestionAnswerData(temp);
	};

	const validate = (e, id) => {
		if (e.target.value.trim() === "") {
			setFieldError({ ...fieldError, [id]: errorMessages.required });
		} else {
			let temp = { ...fieldError };
			delete temp[id];
			setFieldError(temp);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let errors = { ...fieldError };
		const data = formData.data[0];
		if (data.name === undefined || data?.name?.trim() === "") {
			errors = { ...errors, name: errorMessages.required };
		} else if (!/[a-zA-Z]+/.test(data.name)) {
			errors = { ...errors, name: errorMessages.onlyAlphablets };
		} else {
			errors = { ...errors, name: null };
		}
		if (data.email === undefined || data.email?.trim() === "") {
			errors = { ...errors, email: errorMessages.required };
		} else if (!emailPattern.test(data.email)) {
			errors = { ...errors, email: errorMessages.email };
		} else {
			errors = { ...errors, email: null };
		}
		if (data.phoneNumber === undefined || data.phoneNumber?.trim() === "") {
			errors = { ...errors, phoneNumber: errorMessages.required };
		} else {
			errors = { ...errors, phoneNumber: null };
		}
		if (resumeFile == null || formData.resume == "") {
			errors = { ...errors, resume: errorMessages.required };
		} else {
			errors = { ...errors, resume: null };
		}
		setFieldError(errors);
		let newData = convertToFormData(formData);
		// if (resumeFile) {
		//   newData.append("resume", resumeFile);
		// }
		let errorKey = Object.keys(errors);
		const errorCount = errorKey.filter((item) => errors[item] !== null).length;
		if (errorCount > 0) {
			return;
		}
		const captchaCode = recaptchaRef.current.getValue();
		if (captchaCode === "") {
			alert("Please click <I'm not a robot> before submitting");
			return;
		}
		let temp = { ...fieldError };
		for (let qa of questionAnswerData) {
			if (qa.answer.trim() === "") {
				temp = { ...temp, [qa.id]: errorMessages.required };
			} else {
				delete temp[qa.id];
			}
		}
		if (Object.keys(temp).length > 0) {
			setFieldError(temp);
			showAlert("please fill all required fields");
			return;
		}
		for (let i = 0; i < questionAnswerData.length; i++) {
			newData.append(`qaList[${i}][qid]`, questionAnswerData[i].id);
			newData.append(`qaList[${i}][answer]`, questionAnswerData[i].answer);
		}
		if (location) {
			newData.append("location", location);
		}
		if (browser) {
			newData.append("browser", browser);
		}
		if (device) {
			newData.append("device", device);
		}
		if (errorCount === 0) {
			setIsSubmitting(true);
			submitStudentInfo(newData, onSubmitSuccess, onSubmitFail);
		}
	};

	if (Object.keys(roleInfo).length == 0 || roleInfo === undefined) return;

	return (
		<div style={{ overflow: "hidden" }}>
			<Header notFixed={true} />

			<div>
				<SectionContainer>
					<Container>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Box>
									<FlexRow
										gap={20}
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "space-between",
										}}>
										<div gap={10} style={{ width: "70%" }}>
											<Title level="h2" text={`${roleInfo?.roleName}`} />
											<Title level="label" text={`Experience: ${roleInfo?.experience} years`} />{" "}
										</div>
										<div
											gap={10}
											style={{
												width: "30%",
												display: "flex",
												alignItems: "center",
												justifyContent: "flex-end",
											}}>
											<CompanyLogo src={roleInfo?.organization?.orgLogo} alt={roleInfo?.organization?.orgName} />
										</div>
									</FlexRow>
								</Box>
								{/* <BlockMargin size={20}></BlockMargin> */}
								<Box>
									<DescriptionBoxContainer fixedHeight={fixedHeight} style={{ paddingBottom: "10px" }}>
										<Title level="h4" text="Job Description" />
										<Title level="body" text={<DescriptionContainer fixedHeight={fixedHeight}>{roleInfo?.roleDiscription}</DescriptionContainer>} />
										<Button
											onClick={() => setFixedHeight(!fixedHeight)}
											btnText={`View ${fixedHeight ? "more" : "less"}`}
											variant="link"
											style={{
												width: "120px",
												display: "flex",
												justifyContent: "center",
												margin: "15px auto",
												background: "white",
												border: "1px solid #540793",
												color: "#540793",
											}}
										/>
									</DescriptionBoxContainer>
								</Box>
								{/* <BlockMargin size={20}></BlockMargin> */}

								<form onSubmit={handleSubmit} id="detailsForm">
									<Box>
										{/* <BlockMargin>
                  <ContentCenter>
                    <Title
                      level="h2"
                      text="We are excited to learn more about you"
                    />
                  </ContentCenter>
                </BlockMargin> */}

										{/* <Title level="h1" text="Please fill the form below" /> */}
										<Title level="h3" text="We are excited to learn more about you " style={{ marginBottom: "20px" }} />
										<Grid container spacing={5} alignItems="center" className="candidate-list">
											<Grid item lg={4} sm={12} xs={12}>
												<Input label="Name *" placeholder="Enter your name" value={formData.name} onChange={handleChange} name="name" error={!!fieldError["name"]} errorText={fieldError["name"]} onBlur={(e) => validateData(e)} pattern="[A-Za-z]" autoFocus />
											</Grid>
											<Grid item lg={4} sm={12} xs={12}>
												<Input label="Email *" placeholder="Enter your email" value={formData.email} onChange={handleChange} name="email" error={!!fieldError["email"]} errorText={fieldError["email"]} onBlur={(e) => validateData(e)} />
											</Grid>
											<Grid item lg={4} sm={12} xs={12}>
												<Input
													label="Phone Number *"
													value={formData.phoneNumber}
													onChange={handleChange}
													placeholder="Enter your phone number"
													name="phoneNumber"
													error={!!fieldError["phoneNumber"]}
													errorText={fieldError["phoneNumber"]}
													onBlur={(e) => validateData(e)}
													pattern="[(7|8|9)\d{9}]"
													maxLength={10}
													// focusOnly
												/>
											</Grid>
											<Grid item lg={4} sm={12} xs={12}>
												<FileUploadLabel>Upload Resume *</FileUploadLabel>
												<input
													type="file"
													label="Resume"
													onChange={handleFileUpload}
													placeholder="Upload your resume"
													name="resume"
													required
													accept=".pdf,.doc,.docx"
													error={!!fieldError["resume"]}
													errorText={fieldError["resume"]}
													onBlur={(e) => validateData(e)}
													// focusOnly
													style={{
														border: "1px solid #8C8C8C",
														borderRadius: "4px",
														backgroundColor: "#fcfcfb",
														padding: "5px 10px",
														width: "100%",
														boxSizing: "border-box",
													}}
												/>
												<Title level={"body"} text={"(Allowed files: pdf, doc. Max size: 5MB)"} />
											</Grid>
										</Grid>
										<hr style={{ margin: "20px 0" }} />
										<Title level="h3" text="Please answer the following questions" style={{ marginBottom: "20px" }} />
										<Grid container spacing={5} alignItems="center" className="candidate-list" style={{ marginBottom: "20px" }}>
											{questions?.map((item, i) => {
												return (
													<Grid item lg={4} sm={12} xs={12} key={item.qid}>
														<BaseQuestionLabel>{item.question + " *"}</BaseQuestionLabel>
														<Input
															onChange={(e) => {
																handleQuestionAnswerChange(e, i);
																setRemainingCharCount({
																	...remainingCharCount,
																	[item.qid]: e.target.value.length,
																});
															}}
															onBlur={(e) => validate(e, item.qid)}
															errorText={fieldError[item.qid]}
															minRows={2}
															isMulti
														/>
														<RemainingCharCount>{remainingCharCount?.[item.qid] ?? 0} / 500</RemainingCharCount>
													</Grid>
												);
											})}
										</Grid>
										<AlignRight>
											<ReCAPTCHA ref={recaptchaRef} sitekey="6LfjxJclAAAAABuXpZ0aLHba0iSvrPbuA9QGmrg-" onChange={onChange} />
										</AlignRight>
									</Box>
									<SubmitButtonContainer
										style={{
											justifyContent: isMobile ? "center" : "flex-start",
										}}>
										<Button
											variant="contained"
											type="submit"
											form="detailsForm"
											color="success"
											btnText="Submit"
											loading={isSubmitting}
											disabled={isSubmiting}
											style={{
												backgroundColor: "#540793",
											}}
										/>
									</SubmitButtonContainer>
								</form>
							</Grid>
						</Grid>
					</Container>
					<Footer />
				</SectionContainer>
			</div>
		</div>
	);
};

export default CandidateInfo;

import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate, Link } from "react-router-dom";

import Grid from "@mui/material/Grid";
import { Button, Input, Title, Footer } from "components";
import { IconButton } from "@mui/material";
import {
  LoginContainer,
  LoginBanner,
  FieldBox,
  // LinkLarge,
  // RegisterNow,
  LinkSmall,
  BlockMargin,
  LogoContainer,
  SignInContainer,
  LoginContent,
  ForgotPasswordWrapper,
} from "assets/styles/main.styles";
import loginBanner from "assets/images/login.jpg";
import logo from "assets/images/logo.svg";
import { appUrl, errorMessages } from "config/constants";
import { webAuth } from "config/auth-config";
import { ErrorText } from "components/Input/input.styles";
import { useAlert } from "contexts/alert.context";
const Login = () => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    resetEmail: "",
  });
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState({});
  const { showAlert } = useAlert();
  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);

  const handleCredentials = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
    validateInput(e);
  };

  async function storeToken() {
    getToken().then((data) => {
      setLoading(false);
      localStorage.setItem("token", data);
    });
  }

  const validateInput = (e) => {
    if (e.target.name === "email") {
      if (e.target.value === "") {
        setFieldErrors({ ...fieldErrors, email: errorMessages.required });
      } else {
        let temp = { ...fieldErrors };
        delete temp.email;
        setFieldErrors(temp);
      }
    } else if (e.target.name === "password") {
      if (e.target.value === "") {
        setFieldErrors({ ...fieldErrors, password: errorMessages.required });
      } else {
        let temp = { ...fieldErrors };
        delete temp.password;
        setFieldErrors(temp);
      }
    } else if (e.target.name === "resetEmail") {
      if (e.target.value === "") {
        setFieldErrors({ ...fieldErrors, resetEmail: errorMessages.required });
      } else {
        let temp = { ...fieldErrors };
        delete temp.resetEmail;
        setFieldErrors(temp);
      }
    }
  };

  const getToken = async () => {
    var data = await webAuth.currentSession();
    return data.idToken.jwtToken;
  };

  const onSubmit = () => {
    if (credentials.email === undefined || credentials.email === "") {
      setFieldErrors({ ...fieldErrors, email: errorMessages.required });
      return;
    }
    if (credentials.password === undefined || credentials.password === "") {
      setFieldErrors({ ...fieldErrors, password: errorMessages.required });
      return;
    }
    setLoading(true);
    webAuth.login(
      {
        username: credentials.email,
        password: credentials.password,
        redirectUri: appUrl,
        responseType: "token id_token",
        realm: "Username-Password-Authentication",
        scope: "openid profile email offline_access",
      },
      (err, result) => {
        if (err) {
          console.log("failed to login");
          setLoading(false);
          showAlert("invalid email or password", "error");
          return;
        }
        storeToken();
        console.log("result", result);
      }
    );
  };

  const onResetSubmit = () => {
    if (credentials.resetEmail === undefined || credentials.resetEmail === "") {
      setFieldErrors({ ...fieldErrors, resetEmail: errorMessages.required });
      return;
    }
    webAuth.changePassword(
      {
        email: credentials.resetEmail,
        connection: "Username-Password-Authentication",
      },
      (error, response) => {
        if (error) {
          console.log("failed");
          setLoading(false);
          showAlert(error, "error");
          return;
        }
        showAlert(response, "success");
        setIsPasswordForgotten(false);
        setCredentials({
          ...credentials,
          resetEmail: "",
        });
      }
    );
  };

  function handleSubmit(e) {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }

  return (
    <>
      <LoginContainer>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <LoginBanner src={loginBanner} />
          </Grid>
          <Grid item xs={12} md={6}>
            {isPasswordForgotten ? (
              <LoginContent>
                <SignInContainer>
                  <Link
                    to="https://kapiree.com/"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Link>
                  <Title level="h1" text="Forgot Password" />
                  <BlockMargin>
                    <Input
                      label="Email Address"
                      placeholder="Enter Email Address"
                      name="resetEmail"
                      value={credentials.resetEmail}
                      onChange={handleCredentials}
                      errorText={fieldErrors["resetEmail"]}
                      error={!!fieldErrors["resetEmail"]}
                      onBlur={validateInput}
                      isDisabled={loading}
                    />
                    <ForgotPasswordWrapper
                      onClick={() => setIsPasswordForgotten(false)}
                    >
                      Sign In
                    </ForgotPasswordWrapper>
                  </BlockMargin>{" "}
                  <BlockMargin size={30}>
                    <Button
                      variant="contained"
                      btnText="Reset Password"
                      onClick={onResetSubmit}
                      disabled={loading}
                      loading={loading}
                    />
                  </BlockMargin>
                </SignInContainer>
              </LoginContent>
            ) : (
              <LoginContent>
                <SignInContainer>
                  <Link
                    to="https://kapiree.com/"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <img src={logo} alt="logo" />
                  </Link>
                  <Title level="h1" text="Sign In" />
                  <BlockMargin>
                    <Input
                      label="Email Address"
                      placeholder="Enter Email Address"
                      name="email"
                      value={credentials.email}
                      onChange={handleCredentials}
                      errorText={fieldErrors["email"]}
                      error={!!fieldErrors["email"]}
                      onBlur={validateInput}
                      isDisabled={loading}
                      onKeyDown={handleSubmit}
                    />
                  </BlockMargin>

                  <BlockMargin>
                    <Input
                      type="password"
                      label="Password"
                      placeholder="Enter Password"
                      name="password"
                      value={credentials.password}
                      onChange={handleCredentials}
                      errorText={fieldErrors["password"]}
                      error={!!fieldErrors["password"]}
                      onBlur={validateInput}
                      isDisabled={loading}
                      onKeyDown={handleSubmit}
                    />
                    <ForgotPasswordWrapper
                      onClick={() => setIsPasswordForgotten(true)}
                    >
                      Forgot Password?
                    </ForgotPasswordWrapper>
                  </BlockMargin>
                  <BlockMargin size={30}>
                    <Button
                      variant="contained"
                      btnText="Sign in"
                      onClick={onSubmit}
                      disabled={loading}
                      loading={loading}
                    />
                  </BlockMargin>
                </SignInContainer>
              </LoginContent>
            )}
            <Footer />
          </Grid>
        </Grid>
      </LoginContainer>
    </>
  );
};

export default Login;

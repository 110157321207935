import React, { useEffect, useState } from "react";
import { Avatar, Grid, Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Input,
  PageHeader,
  Tag,
  Title,
  Modal,
  GoBackButton,
} from "components";

import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { formatDateDMY } from "utils";
import BadgeIcon from "@mui/icons-material/Badge";
import {
  Box,
  BackButton,
  InfoActionsBlock,
  CandidateInfoBlock,
  ActionButtons,
  CIBBlock,
  SubtitleInfo,
  BlockMargin,
  BasicQuestionItem,
  BasicAnswerItem,
  ActionRightButton,
} from "assets/styles/main.styles";
import ReviewAnswerCard from "./components/ReviewAnswerCard";
import {
  downloadResume,
  getBaseQuestionResponse,
  getInterviewFeedbackDetails,
  saveReview,
} from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { capitalizeWord } from "../../../utils";
import { ModalContainer } from "components/Modal/modal-styles";
import ResumePreview from "components/ResumePreview/ResumePreview";
import { format } from "date-fns";
import useWindowSize from "hooks/useWindowSize";
function stringAvatar() {}

const Review = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  // candidateId: item.id,
  // roleId,
  // fullName: item.name,
  // roleDetails: {
  //   roleName: item.jobrole.roleName,
  //   isKycRequired: item.jobrole.isKycRequired,
  // },
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const { candidateId, roleId, fullName, roleDetails } = state;
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [basicAnswers, setBasicAnswers] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownloadingResume, setIsDownloadingResume] = useState(false);
  const { showAlert } = useAlert();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [kycModalOpen, setKycModalOpen] = useState(false);
  const [kycDetails, setKycDetails] = useState(null);
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
  const [resumePath, setResumePath] = useState("");

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleSetReviewData = (data) => {
    setReviewData(data);
  };

  const handleReviewData = (id, rating) => {
    let temp = [...reviewData];
    const index = temp.findIndex((item) => item.questionId === id);
    temp[index] = { ...temp[index], rating };
    setReviewData(temp);
  };

  const handleFeedbackDetails = (res) => {
    setInterviewDetails(res.qResponse);
    setKycDetails(res.kycPoolData);
    handleSetReviewData(
      res.qResponse?.map((item) => {
        return {
          questionId: item.questionId,
        };
      })
    );
  };

  const handleBasicAnswers = (res) => {
    setBasicAnswers(res);
  };

  const handleBasicAnswersErr = (error) => {
    console.log(error);
  };

  useEffect(() => {
    (async () => {
      if (candidateId && roleId) {
        getInterviewFeedbackDetails(
          { roleId, candidateId },
          handleFeedbackDetails
        );
        await getBaseQuestionResponse(
          { roleId, candidateId },
          handleBasicAnswers,
          handleBasicAnswersErr
        );
      }
    })();
  }, []);

  // const resumeFile =
  //   candidateData?.resumeDir?.length > 0
  //     ? JSON.parse(candidateData?.resumeDir[0]?.docMeta).Location
  //     : null;

  // const kycFile =
  //   candidateData?.KycPool?.length > 0
  //     ? JSON.parse(candidateData?.KycPool[0]?.kycMeta).Location
  //     : null;

  const handleSuccess = () => {
    showAlert("Successfully reviewed", "success");
    navigate(-1);
  };

  const handleFailedRequest = (errorMessage) => {
    showAlert(errorMessage || "some thing went wrong", "error");
  };

  const downloadResumeFunction = async () => {
    setIsDownloadingResume(true);
    await downloadResume(interviewDetails[0].resumeLink);
    setIsDownloadingResume(false);
  };

  const openResumePreview = () => {
    if (interviewDetails[0].resumeLink) {
      setResumePath(interviewDetails[0].resumeLink);
      setIsResumePreviewOpen(true);
    }
  };

  const handleResumePreview = () => {
    setIsResumePreviewOpen(false);
    setResumePath("");
  };

  const handleSubmit = async () => {
    let isValid = true;
    for (let reviewElement of reviewData) {
      if (reviewElement.rating === undefined || !reviewElement.rating) {
        isValid = false;
      }
    }
    if (isValid) {
      const data = {
        reviewData,
        candidateId,
        roleId,
        comment,
      };
      setIsSubmitting(true);
      await saveReview(data, handleSuccess, handleFailedRequest);
      setIsSubmitting(false);
    } else {
      showAlert("Please rate all the questions and add your comment", "error");
    }
  };

  return (
    <div style={{ padding: "1rem" }}>
      <Box>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="left"
          rowSpacing={3}
        >
          <Grid item lg={8} sm={12} xs={12}>
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <GoBackButton
                sx={{
                  color: "#dcdcdc",
                  marginTop: "10px",
                  marginLeft: "-12px",
                  marginRight: "12px",
                  padding: "2px 15px",
                  borderRight: "1px solid #c4c5c5",
                  height: "36px",
                  cursor: "pointer",
                }}
              />
              <CIBBlock>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    flexWrap: "wrap",
                    gap: isMobile ? 0 : "1rem",
                  }}
                >
                  <Title
                    level={isMobile ? "h3" : "h2"}
                    text={fullName}
                    color="black"
                  />
                  <span
                    style={{
                      color: "gray",
                      fontWeight: "900",
                      marginBottom: "0.5rem",
                    }}
                  >{`${
                    roleDetails
                      ? "" + capitalizeWord(roleDetails?.roleName) + ""
                      : ""
                  }`}</span>
                </div>
                <SubtitleInfo>
                  <strong>
                    {" "}
                    {interviewDetails.length
                      ? format(interviewDetails[0].createdDate, "PPp")
                      : ""}
                  </strong>
                </SubtitleInfo>
              </CIBBlock>
            </Stack>
          </Grid>
          <Grid item lg={4} sm={12} xs={12}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                startIcon={<CheckOutlinedIcon />}
                btnText="Submit Feedback"
                variant="contained"
                onClick={handleSubmit}
                loading={isSubmitting}
              />
            </Stack>
          </Grid>
        </Grid>

        {/* <Grid container alignItems="left" sx={{ marginBottom: "10px" }}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            paddingTop={"30px"}
          >
            <DescriptionOutlinedIcon
              sx={{
                color: resumeFile == null ? "lightgray" : "#4286F5",
                marginTop: " 15px",
                marginLeft: "30px",
              }}
            />
            <div
              style={{
                textDecoration: "solid",
                marginTop: " 4px",
                color: resumeFile == null ? "lightgray" : "#4286F5",
                marginRight: "30px",
                cursor: "pointer",
              }}
              disabled={!interviewDetails[0]?.resumeLink}
              onClick={() => {
                if (resumeFile != null) {
                  openResumePreview(resumeFile);
                }
              }}
            >
              Resume
            </div>
            <ContactPageOutlinedIcon
              sx={{
                color: kycFile == null ? "lightgray" : "#4286F5",
                marginTop: " 15px",
                marginLeft: "30px",
              }}
            />
            <div
              style={{
                textDecoration: "solid",
                marginTop: " 4px",
                color: kycFile == null ? "lightgray" : "#4286F5",
                marginRight: "30px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (kycFile != null) {
                  openKycPreview(kycFile);
                }
              }}
            >
              KYC
            </div>
            {roleDetails?.isKycRequired &&
              candidateData?.KycPool?.length > 0 && (
                <Tooltip placement="top" title={<>KYC</>} arrow>
                  <Button
                    startIcon={<BadgeIcon sx={{ opacity: "0.5" }} />}
                    variant="text"
                    btnText="KYC"
                    onClick={() => setKycModalOpen(true)}
                    sx={{ marginLeft: "20px" }}
                  />
                </Tooltip>
              )}
           
          </Stack>
        </Grid> */}
        <Grid container alignItems="left" sx={{ marginBottom: "10px" }}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            paddingTop={"30px"}
          >
            <Button
              startIcon={<DescriptionOutlinedIcon />}
              variant="text"
              btnText="Resume"
              onClick={openResumePreview}
              disabled={!interviewDetails[0]?.resumeLink}
            />
            {roleDetails?.isKycRequired && (
              <Tooltip placement="top" title={<>KYC</>} arrow>
                <Button
                  startIcon={<BadgeIcon sx={{ opacity: "0.5" }} />}
                  variant="text"
                  btnText="KYC"
                  onClick={() => setKycModalOpen(true)}
                  sx={{ marginLeft: "20px" }}
                />
              </Tooltip>
            )}
          </Stack>
        </Grid>

        <BlockMargin size={30}>
          <Grid container spacing={8} alignItems="flex-start">
            {interviewDetails?.map((item, i) => {
              return (
                <Grid item md={4} xs={12} key={i}>
                  <ReviewAnswerCard
                    data={item}
                    handleReviewData={handleReviewData}
                    reviewData={reviewData}
                    totalQuestions={interviewDetails.length}
                    currentQuestionNo={i + 1}
                    interviewDetails={interviewDetails}
                    comment={comment}
                    handleComment={handleComment}
                    handleSubmit={handleSubmit}
                  />
                </Grid>
              );
            })}
          </Grid>
        </BlockMargin>
        <br />
        <BlockMargin size={20}>
          <Title level="h4" text={"Comments"} />
        </BlockMargin>
        <BlockMargin>
          <Grid container spacing={6} alignItems="flex-start">
            <Grid item lg={6} sm={12} xs={12}>
              <Input
                placeholder="Enter comments"
                name="overall-comments"
                isMulti
                value={comment}
                onChange={handleComment}
                minRows={4}
                style={{
                  fontFamily: "Manrope",
                  borderRadius: "5px",
                  borderColor: "lightgray",
                  padding: "10px",
                }}
              />
            </Grid>
          </Grid>
        </BlockMargin>
        <BlockMargin size={20}>
          <Title level="h4" text="Basic Answers" />
        </BlockMargin>
        <BlockMargin>
          <Box isGrey>
            <Grid container spacing={2} alignItems="flex-start">
              {basicAnswers.map((item, index) => {
                return (
                  <Grid item xs={12}>
                    <BasicQuestionItem>
                      {index + 1}. {item.question}
                    </BasicQuestionItem>
                    <BasicAnswerItem>
                      {item.answer ?? "No Answer"}
                    </BasicAnswerItem>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </BlockMargin>

        <ActionRightButton>
          <Button
            startIcon={<CheckOutlinedIcon />}
            btnText="Submit Feedback"
            variant="contained"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </ActionRightButton>
      </Box>
      <Modal
        size="md"
        open={kycModalOpen}
        close={() => setKycModalOpen(false)}
        title="KYC Image"
        children={<>{kycDetails != null && showKycDetails(kycDetails)}</>}
      />
      <ResumePreview
        isResumePreviewOpen={isResumePreviewOpen}
        handleResumePreview={handleResumePreview}
        resumePath={resumePath}
      />
    </div>
  );
};

export default Review;

function showKycDetails(data) {
  const { createdDate, kycMeta } = data;
  const parsedKycMeta = JSON.parse(kycMeta);
  return (
    <>
      <Title
        level={"body"}
        text={`Captured on: ${formatDateDMY(createdDate)}`}
      />
      <img
        src={parsedKycMeta?.Location}
        alt="kyc"
        style={{ maxWidth: "100%" }}
      />
    </>
  );
}

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { searchCandidate } from "config/api.service";
import { useState, useEffect, useRef } from "react";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { sleep, capitalizeWord } from "utils";
import { SearchCandidatesText } from "./header.styles";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

export default function CandidateSearch() {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const loadingAnim = open && options.length === 0;
  const ref = useRef();

  const searchCandidateByText = async (e) => {
    const text = e.target.value;
    if (text.length < 3) return;
    await sleep(1e3);
    await searchCandidate(text, handleSearchSuccess, handleSearchError);
  };

  const handleSearchSuccess = (data) => {
    const newOptions = data.map((item) => ({
      label: item.name,
      role: item.jobrole.roleName,
      roleId: item.jobrole.id,
      id: item.id,
      thumbnail:
        item.AnswerPool.length > 0
          ? JSON.parse(item.AnswerPool[0].vidMeta)?.vidMeta?.assets?.thumbnail
          : "",
    }));
    setOptions(newOptions);
    setLoading(false);
  };

  const handleSearchError = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.label,
  });

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      size="small"
      options={options}
      ref={ref}
      onClose={() => {
        setOptions([]);
        setOpen(false);
      }}
      getOptionLabel={(option) => option.label}
      noOptionsText="Search Candidates"
      onOpen={() => setOptions([])}
      selectOnFocus
      clearOnBlur
      filterOptions={filterOptions}
      onChange={(event, value, reason, details) => {
        if (value) {
          navigate("/candidate-details", {
            state: {
              candidateId: value?.id,
              roleId: value?.roleId,
            },
          });
        }
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{
              "& > img": { mr: 2, flexShrink: 0 },
              borderBottom: "1px solid #E5E5E5",
            }}
            {...optionProps}
          >
            {option.thumbnail ? (
              <img loading="lazy" width="60" src={option.thumbnail} alt="" />
            ) : (
              <div
                style={{
                  width: "60px",
                  height: "30px",
                  backgroundColor: "#E5E5E5",
                  marginRight: "20px",
                }}
              ></div>
            )}

            <SearchCandidatesText>
              <Grid container xs={12}>
                <Grid xs={8}>
                  <h1>{capitalizeWord(option.label)}</h1>
                  <p style={{ marginTop: "-8px" }}>
                    {capitalizeWord(option.role)}
                  </p>
                </Grid>
                <Grid xs={4}>
                  <div
                    style={{
                      fontSize: "10px",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                  >
                    ID: {option.id}
                  </div>
                  {/* <div style={{ fontSize: "10px", paddingTop: "5px", textAlign: "right" }}>Interviewd On: {option.id}</div> */}
                </Grid>
              </Grid>
            </SearchCandidatesText>
          </Box>
        );
      }}
      sx={{ width: 600, marginRight: "20px" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Candidates"
          onChange={searchCandidateByText}
        />
      )}
    />
  );
}

import { AxiosHttp } from "./axios.conf";

export async function testWhatsapp(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/recuiter/testWhatsapp", data);
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error, "error from testWhatsapp()");
  }
}
export async function testEmail(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/recuiter/testEmail", data);
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error, "error from testEmail()");
  }
}
export async function getJobRoles(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/recuiter/getMyJobRoles");
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function getCandidateCount(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.get(
      "api/v1/recuiter/getTotalCandidateCount"
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function getOrganizations(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/admin/organizations");
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export function getUserDetails(successCallback) {
  AxiosHttp.post("api/v1/recuiter/getUserDetails")
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getJobRoleDetails(successCallback, id, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/recuiter/getJobRoleDetails", {
      roleId: id,
    });
    response.data.status === "error" && errorCallback(response.data.message);
    response.data.status === "success" && successCallback(response.data.data);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function createJobRoles(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/createJobRole",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function updateJobRoles(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/updateJobRole",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function getBaseQuestions(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/getBaseQuestionsTemplate"
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

// already using loader for this
export async function getInvitedCandidates(successCallback, id) {
  await AxiosHttp.post("api/v1/recuiter/getInvitedCandidates", { roleId: id })
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function getInvitedCandidate(successCallback, id) {
  AxiosHttp.post("api/v1/recuiter/getInvitedCandidate", { candidateId: id })
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function updateInvitedCandidate(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/updateInvitedCandidate",
      data
    );

    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" &&
      errorCallback(response.details?.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function resendCandidateInvite(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/resendCandidateInvite",
      data
    );

    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" &&
      errorCallback(response.details?.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function inviteCandidate(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/inviteCandidate",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

// not in use
export function requestCandidate(data, successCallback, errorCallback) {
  AxiosHttp.post("api/v1/recuiter/sendInviteRequest", data)
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
      errorCallback();
    });
}

export function getInterviewFeedbackDetails(
  data,
  successCallback,
  errorCallback
) {
  AxiosHttp.post("api/v1/recuiter/getInterviewAnswerDetails", data)
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getBaseQuestionResponse(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/getBaseQuestionResponse",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
  AxiosHttp.post("api/v1/recuiter/getBaseQuestionResponse", data)
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getCandidateInterviewDetails(id, successCallback) {
  AxiosHttp.post(`api/v1/candidate/invite/${id}`)
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getCandidateInterviewLoadDetails(id, successCallback) {
  AxiosHttp.post(`api/v1/candidate/invite/loadData/${id}`)
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function saveReview(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/recuiter/saveReview", data);
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback(error.message);
    console.log(error);
  }
}

export async function saveReviewFromExternal(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post("api/v1/plugin/saveReview", data);
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback(error.message);
    console.log(error);
  }
}

export async function licenceUpdate(id, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/candidate/licenceUpdate", {
      id,
    });
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}
export async function ApiVideo() {
  try {
    const response = await AxiosHttp.post("api/v1/candidate/ApiVideo");
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function saveBaseQuestion(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/candidate/saveBaseQuestion",
      data,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function saveInterviewAnswers(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/candidate/saveInterviewAnswers",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function updateInterviewAnswers(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/candidate/updateInterviewAnswers",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function downloadCsvTemplate() {
  try {
    const response = await AxiosHttp.get(
      "api/v1/recuiter/getCandidateUploadTemplate"
    );
    var blob = new Blob([response.data]);
    var url = URL.createObjectURL(blob);
    window.open(url);
  } catch (error) {
    console.log(error);
  }
}

export async function downloadResume(url) {
  try {
    window.open(url);
  } catch (error) {
    console.log(error);
  }
}

export async function updateKYC(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("/api/v1/candidate/updateKyc", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function getJobRoleDetailsToRegister(
  jobRoleId,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/plugin/getRoleDetails/${jobRoleId}`
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function getOrgDetails(orgId, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(`api/v1/admin/organization/${orgId}`);
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function submitStudentInfo(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/plugin/invite-candidate`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function addOrganizationInfo(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/admin/add-organizations`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function updateOrganizationDetails(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/updateOrganizationDetails",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function submitInterview(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/candidate/submit-interview",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function getRecentApplications(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/recuiter/getRecentApplications`
    );

    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error, "error from getRecentApplications()");
  }
}

export async function checkInterviewIsSubmitted(inviteId, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/candidate/interviewStatus/${inviteId}`
    );
    return response;
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function deleteVideo(id) {
  try {
    const response = await AxiosHttp.post("api/v1/candidate/deleteVideo", {
      videoId: id,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function getBaseQuestionsByJobRole(roleId) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/plugin/getBaseQuestionsByJobRole`,
      { roleId }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function saveBaseQuestionFromWebsite(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/candidate/saveBaseQuestionFromWebsite",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function addCategory(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post("api/v1/recuiter/addCategory", data);
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export function getCategory(successCallback) {
  AxiosHttp.post("api/v1/recuiter/getCategory")
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log("getCategory ", error);
    });
}

export async function updateCategory(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/updateCategory",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
  }
}

export async function deleteCategory(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/deleteCategory",
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
  }
}

export async function generateApi(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(`api/v1/recuiter/generateApi`, data);
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
  }
}

export async function generateApiFromAdmin(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/admin/generateApiFromAdmin`,
      data
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
  }
}

export function getCandidateVideos(data, successCallback, errorCallback) {
  AxiosHttp.post("api/v1/plugin/getCandidateVideos", data)
    .then((response) => {
      response.data.status === "success" && successCallback(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function getTotalCandidates(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.get("api/v1/recuiter/getTotalCandidates");
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function getTotalCandidatesByRole(
  id,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/getTotalCandidatesByRole",
      {
        roleId: id,
      }
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    console.log(error);
    errorCallback();
  }
}

export async function getCandidateDetails(candidateId, roleId) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/candidate/getCandidateDetails",
      {
        candidateId,
        roleId,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: "error",
      message: "Something went wrong",
    };
  }
}

export async function getTheLatestVideo(data) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/candidate/getTheLatestVideo",
      {
        candidateId: data.candidateId,
        roleId: data.roleId,
        questionDetails: data.questionDetails,
        videoTitle: data.videoTitle,
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      status: "error",
      message: "Something went wrong",
    };
  }
}

export async function getRecentVideoInterviews(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/recuiter/getRecentVideoInterviews`
    );

    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function searchCandidate(
  searchText,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(`api/v1/recuiter/searchCandidate`, {
      searchText,
    });
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function saveReviewAsDraft(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/saveReviewAsDraft",
      data
    );
    response.data.status === "success" && successCallback(response.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback(error.message);
    console.log(error);
  }
}

export async function applicationDailyCount(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/recuiter/applicationDailyCount`
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}
export async function applicationMonthlyCount(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/recuiter/applicationMonthlyCount`
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function uploadOrgLogo(data, successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/recuiter/uploadOrgLogo`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

export async function sendReviewInvitesByRole(
  data,
  successCallback,
  errorCallback
) {
  try {
    const response = await AxiosHttp.post(
      "api/v1/recuiter/sendReviewInvitesByRole",
      data
    );
    response.data.status === "success" && successCallback(response.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback(error.message);
    console.log(error);
  }
}


export async function getAllWhatsappMessages(successCallback, errorCallback) {
  try {
    const response = await AxiosHttp.post(
      `api/v1/recuiter/getAllWhatsappMessages`
    );
    response.data.status === "success" && successCallback(response.data.data);
    response.data.status === "error" && errorCallback(response.data.message);
  } catch (error) {
    errorCallback();
    console.log(error);
  }
}

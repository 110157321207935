import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
import DownloadIcon from "@mui/icons-material/Download";
import { Dialog, Button, Input, Title } from "components";
import Grid from "@mui/material/Grid";
import { errorMessages } from "config/constants";
import {
  Box,
  CandidateWrapper,
  CandidateItemList,
  CandidateItem,
} from "assets/styles/main.styles";
import { SendOutlined } from "@mui/icons-material";

const SendViaEmail = ({ isOpen, onClose, handleSendEmail }) => {
  const [inviteFieldError, setInviteFieldError] = useState({});
  const [fieldError, setFieldError] = useState({
    0: { email: null },
  });

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const getResumeType = (resumeFile) => {
    if (resumeFile) {
      const resumeArr = resumeFile.split("/");
      const fileName = resumeArr[resumeArr.length - 1];
      const fileType = fileName.split(".")[1];
      return fileType;
    }
  };

  const handleSend = () => {
    handleSendEmail(email);
  };

  const validateData = (e, index) => {
    let temp = { ...fieldError };
    if (e.target.name === "email") {
      temp[index] = {
        ...temp[index],
        email: e.target.value ? null : errorMessages.required,
      };
    }
    setFieldError(temp);
  };

  useEffect(() => {}, []);
  return (
    <Dialog
      size="sm"
      open={isOpen}
      close={onClose}
      title={"Send for Review "}
      actionWithTitle={<></>}
      children={
        <CandidateWrapper>
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            className="candidate-list"
            style={{ paddingBottom: "20px" }}
          >
            {/* <Grid item xs={6} >
                  <Title level="label" text="Full Name" />
                  <Input
                    name="fullName"
                    value={fullName}
                    
                    error={!!fieldError[0]["fullName"]}
                    errorText={fieldError[0]["fullName"]}
                    onBlur={(e) => validateData(e, 0)}
                  />
                </Grid> */}
            <Grid item xs={8}>
              <Input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!fieldError[0]["email"]}
                errorText={fieldError[0]["email"]}
                onBlur={(e) => validateData(e, 0)}
                placeholder="Enter reviewer Email"
              />
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" btnText="Send" onClick={handleSend} />
            </Grid>
          </Grid>
        </CandidateWrapper>
      }
    />
  );
};

export default SendViaEmail;

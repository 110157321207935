import React from "react";

import { Stepper as MuiStepper } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button } from "components";
import Typography from "@mui/material/Typography";

import {
  StepperContainer,
  TabContentContainer,
  RecordButtonContainer,
} from "./stepper.styles";
import InterviewWindow from "./components/InterviewWindow";

const Stepper = ({ steps, content }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [recordStatus, setRecordStatus] = React.useState(false);
  const [showNav, setShowNav] = React.useState(false);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 4;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setShowNav(false);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleRecordStart = () => {
    setRecordStatus(true);
  };

  const handleRecordStop = () => {
    setRecordStatus(false);
    setShowNav(true);
  };

  return (
    <StepperContainer>
      <Box sx={{ width: "100%" }}>
        <MuiStepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={index} {...stepProps}>
                <StepLabel {...labelProps}></StepLabel>
              </Step>
            );
          })}
        </MuiStepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {steps.map((item, index) => (
              <TabContentContainer key={index}>
                {activeStep === index ? (
                  <div>
                    <InterviewWindow
                      title={item.title}
                      isVideoOn={item.isVideo}
                    />
                  </div>
                ) : null}
              </TabContentContainer>
            ))}

            {!showNav && (
              <RecordButtonContainer>
                {!recordStatus ? (
                  <Button
                    onClick={handleRecordStart}
                    variant="contained"
                    btnText="Start Recording"
                  />
                ) : (
                  <Button
                    onClick={handleRecordStop}
                    variant="contained"
                    btnText="Stop Recording"
                  />
                )}
              </RecordButtonContainer>
            )}

            {showNav && (
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Button
                  variant="outlined"
                  // disabled={activeStep === 0}
                  // onClick={handleBack}
                  sx={{ mr: 1 }}
                  btnText="Retake"
                />

                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}

                <Button
                  onClick={handleNext}
                  variant="contained"
                  btnText={
                    activeStep === steps.length - 1
                      ? "Submit Interview"
                      : "Next"
                  }
                />
              </Box>
            )}
          </React.Fragment>
        )}
      </Box>
    </StepperContainer>
  );
};

export default Stepper;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GENERAL_BASIC_QUESTIONS } from "../../config/constants";
import { Input, Checkbox, Title } from "components";

import {
  AddBasicQuestion,
  CheckboxListContainer,
  CheckboxItemList,
  WrapperDiv,
} from "./checkboxlisting.styles";

import { errorMessages } from "config/constants";
import BarLoader from "components/SkeletonLoaders/BarLoader";

const CheckboxListing = ({
  baseQuestions,
  isEdit = false,
  selectedBaseQuestions,
  templateBaseQuestions,
  handleSelectedBaseQuestions,
  handleNewBaseQuestions,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  // const [questionList, setQuestionList] = useState(baseQuestions);
  const [editQuestionId, setEditQuestionID] = useState(null);
  const [currentEditItem, setCurrentEditItem] = useState("");
  const [fieldError, setFieldError] = useState("");
  const [editError, setEditError] = useState(null);
  const [currentEditQuestion, setCurrentEditQuestion] = useState({});
  // const [isGettingBaseQues, setIsGettingBaseQues] = useState(false);

  const addItemToList = (e) => {
    if (currentQuestion?.trim() === "") {
      setFieldError("Question is required");
    } else {
      setFieldError("");
      let newRandomId = getNewRandomId();

      const isExists = baseQuestions.find((item) => item.qid === newRandomId);

      while (isExists) {
        newRandomId = getNewRandomId();
      }

      const newData = {
        qid: newRandomId,
        sortOrder: baseQuestions.length + 1,
        question: currentQuestion,
        type: "",
      };
      // setQuestionList([...questionList, newData]);
      // setBaseQuestions([...baseQuestions, newData]);
      handleSelectedBaseQuestions(e, newData);
      handleNewBaseQuestions([...baseQuestions, newData]);
      setCurrentQuestion("");
    }
  };

  function getNewRandomId() {
    return "n_" + Math.floor(Math.random() * 100);
  }

  function handleEditInputChange(e) {
    if (e.target.value.trim() === "") {
      setEditError(errorMessages.required);
    } else {
      setEditError(null);
    }
    setCurrentEditItem(e.target.value);
  }

  function handleEditSubmit(e) {
    if (currentEditQuestion.question.trim() === "") {
      setEditError(errorMessages.required);
    } else {
      let temp = [...baseQuestions];
      const i = temp.findIndex((item) => item.qid === currentEditQuestion.qid);
      temp[i] = { ...temp[i], question: currentEditItem };
      // setQuestionList(temp);
      // setEditQuestionID(null);
      handleNewBaseQuestions(temp);
      setIsEditing(false);
    }
  }

  function handleEditClick(item) {
    setCurrentEditItem(item.question);
    setCurrentEditQuestion(item);
    // setEditQuestionID(item.qid);
    setIsEditing(true);
  }

  function handleDeleteClick(item) {
    const restofQuestions = baseQuestions.filter((question) => {
      return question !== item;
    });
    handleNewBaseQuestions(restofQuestions);
  }

  const isDataChecked = (data) => {
    return baseQuestions.map((item) => item.question).includes(data.question);
  };

  const templateQuestions = templateBaseQuestions.map((item) => item.question);

  //   <div>
  //   <BarLoader />
  // </div>

  return (
    <Grid container>
      <CheckboxListContainer>
        {baseQuestions.length > 0 &&
          baseQuestions.map((item, i) => (
            <CheckboxItemList key={item.sortOrder}>
              {isEditing && currentEditQuestion.qid === item.qid ? (
                <Input
                  name={item.qid}
                  value={currentEditItem}
                  onChange={handleEditInputChange}
                  className="Checkbox-Question"
                  errorText={editError ?? null}
                />
              ) : (
                <Checkbox
                  label={item.question}
                  checked={selectedBaseQuestions.includes(item.qid)}
                  disabled={item.qid.toString().startsWith("n_")}
                  onChange={(e) => handleSelectedBaseQuestions(e, item)}
                />
              )}

              {!templateQuestions.includes(item.question) &&
                (isEditing && currentEditQuestion.qid === item.qid ? (
                  <WrapperDiv>
                    <IconButton aria-label="save">
                      <CloseIcon
                        onClick={() => {
                          setCurrentEditQuestion({});
                          setIsEditing(false);
                          setEditError(null);
                        }}
                      />
                    </IconButton>
                    <IconButton
                      sx={{ margin: 0 }}
                      aria-label="save"
                      onClick={handleEditSubmit}
                    >
                      <SaveIcon />
                    </IconButton>
                  </WrapperDiv>
                ) : (
                  <WrapperDiv>
                    <WrapperDiv>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditClick(item)}
                        sx={{ margin: 0 }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        sx={{ margin: 0 }}
                        onClick={() => handleDeleteClick(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </WrapperDiv>
                  </WrapperDiv>
                ))}
            </CheckboxItemList>
          ))}

        <AddBasicQuestion>
          <Input
            placeholder="Add question here"
            name="ui-input"
            value={currentQuestion}
            onChange={(e) => {
              if (fieldError !== "") {
                setFieldError("");
              }
              setCurrentQuestion(e.target.value);
            }}
            errorText={fieldError}
          />
          <IconButton
            sx={{ margin: "0 !important", fontSize: "1.5rem" }}
            aria-label="save"
            onClick={addItemToList}
            size="large"
          >
            <SaveIcon />
          </IconButton>
        </AddBasicQuestion>
      </CheckboxListContainer>
    </Grid>
  );
};

export default CheckboxListing;

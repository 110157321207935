import React from "react";
import { CardMedia } from "@mui/material";

const Videoview = ({ videosrc }) => {
  return (
    <CardMedia
      component="video"
      controls
      src={videosrc}
      style={{ width: "100%" }}
    />
  );
};

export default Videoview;

import { useEffect, useState } from "react";
import {
  Box,
  BlockMargin,
  ModalButtonWrapper,
} from "assets/styles/main.styles";
import { Input, Button } from "components";
import Grid from "@mui/material/Grid";
import { errorMessages } from "config/constants";
import { addCategory, updateCategory } from "config/api.service";
import { useAlert } from "contexts/alert.context";

const CreateCategory = ({ isEdit, handleClose, details }) => {
  const [categoryDetails, setCategoryDetails] = useState({
    name: "",
    description: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const { showAlert } = useAlert();

  useEffect(() => {
    if (isEdit) {
      setCategoryDetails(details);
    }
  }, [details]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (isEdit) {
      updateCategory(categoryDetails, updateSuccessCallback, errorCallback);
    } else {
      addCategory(categoryDetails, createSuccessCallback, errorCallback);
    }
    setIsSubmitting(false);
  };

  const errorCallback = (errorMessage) => {
    showAlert(errorMessage || "something went wrong", "error");
    handleClose();
  };

  const createSuccessCallback = () => {
    showAlert("Successfully created", "success");
    handleClose();
  };

  const updateSuccessCallback = () => {
    showAlert("Successfully updated", "success");
    handleClose();
  };

  const handleCategoryDetails = (e) => {
    const value = e.target.value;
    setCategoryDetails({
      ...categoryDetails,
      [e.target.name]: value,
    });
  };

  const validateInput = (e) => {
    if (e.target.name) {
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.required,
        });
      }
    }
  };

  return (
    <>
      <Box isGrey>
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item xs={6}>
            <Input
              label={
                <>
                  Name <span style={{ color: "red" }}>*</span>
                </>
              }
              placeholder="Enter category name"
              name="name"
              onChange={handleCategoryDetails}
              value={categoryDetails?.name}
              onBlur={validateInput}
              error={!!fieldErrors["name"]}
              errorText={fieldErrors["name"]}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              label={<>Description</>}
              placeholder="Enter description"
              name="description"
              onChange={handleCategoryDetails}
              value={categoryDetails?.description}
            />
          </Grid>
        </Grid>
      </Box>
      <BlockMargin></BlockMargin>

      <ModalButtonWrapper>
        <Button
          variant="contained"
          btnText={`${isEdit ? "Update" : "Create"}`}
          loading={isSubmitting}
          onClick={handleSubmit}
        />
      </ModalButtonWrapper>
    </>
  );
};
export default CreateCategory;

import React, { useState, useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";
import Countdown from "./components/Countdown";
import { Alert } from "components";
import { InterviewContainer, CanvasContainer } from "./VideoInterview.styles";
import {
  ApiVideo,
  saveInterviewAnswers,
  updateInterviewAnswers,
} from "config/api.service";
import { questionType } from "config/constants";
import { useAlert } from "contexts/alert.context";
import QuestionBox from "./components/QuestionBox";
import { useNavigate } from "react-router-dom";
import Timer from "./components/Timer";
import Actions from "./components/Actions";
import {
  composer,
  WIDTH,
  HEIGHT,
  removeSpaceAndToLowerCase,
  openFullscreen,
  closeFullscreen,
} from "./utils";
import { useInterviewDataContext } from "contexts/interviewData";
import useWindowSize from "hooks/useWindowSize";
import { decrypt } from "utils/encrypt";

const VideoInterview = ({ retakeQuestionId = "" }) => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const { interviewData, setAnswerData, answerData } =
    useInterviewDataContext();
  const [startCountdown, setStartCountdown] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [isRecording, setIsRecording] = useState(false);

  // const interviewData = JSON.parse(localStorage.getItem("interviewData"));
  const interviewQuestions = interviewData?.jobrole?.interviewQuestions.map(
    (questionData) => ({
      ...questionData,
      question: decrypt(questionData.question),
    })
  );

  const [currentQuestionNo, setCurrentQuestionNo] = useState(1);
  const [currentQuestionId, setCurrentQuestionId] = useState(
    interviewQuestions[0].id
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hiddenQuestionTimer = 5;
  const [hiddenAlert, setHiddenAlert] = useState(false);
  const [currentQuestionTimeLimit, setCurrentQuestionTimeLimit] = useState(
    interviewQuestions[0].timeLimit
  );

  const windowSize = useWindowSize();

  const isMobile = windowSize.width <= 768;

  // useEffect(
  //   () =>
  //     navigation.addListener("beforeRemove", (e) => {
  //       // Prevent default behavior of leaving the screen
  //       e.preventDefault();

  //       // Prompt the user before leaving the screen
  //       alert("you cannot go back");
  //     }),
  //   [navigation]
  // );

  useEffect(() => {
    if (retakeQuestionId == "" && answerData.length > 0) {
      setAnswers(answerData);
      setCurrentQuestionNo(answerData.length + 1);
      setCurrentQuestionId(interviewQuestions[answerData.length]?.id);
      setCurrentQuestionTimeLimit(
        interviewQuestions[answerData.length]?.timeLimit
      );
      if (
        interviewQuestions[answerData.length]?.type ===
        questionType.HIDDEN_QUESTION
      ) {
        setHiddenAlert(true);
      }
      if (answerData.length >= interviewQuestions.length) {
        setTimeout(() => {
          closeFullscreen();
          navigate("/interview-videos", { replace: true });
        }, 5000);
      }
    }
    if (retakeQuestionId) {
      setCurrentQuestionId(retakeQuestionId);
      const retakeQuestionData = interviewQuestions.find(
        (q) => q.id === retakeQuestionId
      );
      setCurrentQuestionTimeLimit(retakeQuestionData?.timeLimit);
      if (retakeQuestionData?.type === questionType.HIDDEN_QUESTION) {
        setHiddenAlert(true);
      }
    }
  }, []);

  const handleCountDownEnd = () => {
    setStartCountdown(false);
    setTimerStarted(true);
    onRecord();
  };

  const closeHiddenAlert = () => {
    setHiddenAlert(false);
    setStartCountdown(true);
  };

  useEffect(() => {
    addStream();
    openFullscreen(
      document.getElementById("interview-container").parentElement
    );
  }, []);

  const addStream = async () => {
    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia is not supported in this browser.");

      return;
    }

    try {
      const deviceIds = JSON.parse(localStorage.getItem("deviceIds"));

      let stream = await navigator?.mediaDevices?.getUserMedia({
        video: {
          deviceId: deviceIds ? deviceIds.videoDeviceId : true,
        },
        audio: true,
      });
      setTimeout(() => {
        composer.addStream(stream, {
          type: "webcam",
          imageUrl: "",
          // position: "contain",
          position: "fixed",
          mask: "none",
          top: 0,
          left: 0,
          draggable: false,
          resizable: false,
        });
        const isContainerPresent = document.getElementById("container");
        if (isContainerPresent) {
          composer.appendCanvasTo("#container");
          const canvas = composer.getCanvas();
          if (canvas) {
            canvas.style.width = "100%";
            canvas.style.boxSizing = "border-box";
          }
          // updateStreams(composer.getStreams()[composer.getStreams().length - 1]);
          // composer.updateStream(streamId)
        }
      }, 1000);
    } catch (error) {
      console.log("Error accessing media devices: " + error.message);
    }
  };

  const onRecord = async () => {
    setTimerStarted(true);
    var res = await ApiVideo();
    // document.addEventListener('fullscreenchange', exitHandler, false);
    // console.log(document.fullscreenElement, "is full screen");
    let videoName = `${removeSpaceAndToLowerCase(
      interviewData?.jobrole?.roleName
    )}_${removeSpaceAndToLowerCase(
      interviewData?.CandidateList?.name
    )}_${currentQuestionId}`;
    composer.startRecording({
      uploadToken: res.data.data.token,
      videoName,
      generateFileOnStop: false,
      origin: {
        application: {
          name: "record-a-video",
          version: "1.0.0",
        },
      },
    });
    composer.addEventListener("error", (e) => {
      console.log("error", e);
      setErrorMessage(e.data.title || "An unknown error occurred");
      setShowError(true);
      setIsRecording(false);
    });
    setIsRecording(true);
  };

  const onStopRecording = () => {
    setTimerStarted(false);
    setIsSubmitting(true);
    composer.stopRecording().then(async (data) => {
      // handleLoading(true);
      // setPlayerUrl(ev.data.assets.player);
      // handleLoading(false);

      // console.log("uploadedBytes: " + event.uploadedBytes);
      // console.log("totalBytes: " + event.totalBytes);
      // console.log("chunksCount: " + event.chunksCount);
      // console.log("currentChunk: " + event.currentChunk);
      // console.log("currentChunkTotalBytes: " + event.currentChunkTotalBytes);
      // console.log("currentChunkUploadedBytes: " + event.currentChunkUploadedBytes);

      // composer.addEventListener("videoPlayable", (ev) => {
      let newAnswers = [...answers];
      if (currentQuestionNo <= interviewQuestions.length) {
        newAnswers[currentQuestionNo - 1] = {};
        newAnswers[currentQuestionNo - 1].isRecorded = true;
        newAnswers[currentQuestionNo - 1].isSubmitted = true;
        newAnswers[currentQuestionNo - 1].vidMeta = data;
        newAnswers[currentQuestionNo - 1].qId = currentQuestionId;
        let currentInterviewQuestion = interviewQuestions.find(
          (q) => q.id === currentQuestionId
        );
        newAnswers[currentQuestionNo - 1].question =
          currentInterviewQuestion?.question;
        newAnswers[currentQuestionNo - 1].retake =
          currentInterviewQuestion?.retake;
      }
      setAnswers(newAnswers);
      setIsRecording(false);
      storeAnswer(newAnswers); //function to store the answers to db
      removeStream();
      addStream();
    });
    // console.log({ data });
    // });
  };

  const removeStream = () => {
    composer.getStreams().forEach((stream) => {
      composer.removeStream(stream.id);
    });
  };

  const storeAnswer = async (newAnswers) => {
    const data = {
      inviteUUID: interviewData.inviteUUID,
      qId: currentQuestionId,
      metaObj: "",
      answer: "",
      candidateId: interviewData.candidateId,
      vid: newAnswers[currentQuestionNo - 1],
    };
    if (retakeQuestionId) {
      data.qId = retakeQuestionId;
      await updateInterviewAnswers(
        data,
        () => {
          console.log("updated the retook question");
          setTimeout(() => {
            closeFullscreen();
            navigate("/interview-videos", { replace: true });
          }, 5000);
        },
        () => console.log("Unable to submit the retake question")
      );
    } else {
      await saveInterviewAnswers(data, onSuccess, onFailure);
    }
    if (currentQuestionNo >= interviewQuestions.length) {
      setAnswerData(newAnswers);
      setTimeout(() => {
        closeFullscreen();
        navigate("/interview-videos", { replace: true });
      }, 5000);
    }
    setIsSubmitting(false);
  };

  const onSuccess = () => {
    if (currentQuestionNo < interviewQuestions.length) {
      setCurrentQuestionNo(currentQuestionNo + 1);
      setCurrentQuestionId(interviewQuestions[currentQuestionNo].id);
      setCurrentQuestionTimeLimit(
        interviewQuestions[currentQuestionNo].timeLimit
      );
      if (
        interviewQuestions[currentQuestionNo].type ===
        questionType.HIDDEN_QUESTION
      ) {
        setHiddenAlert(true);
      }
    }
  };

  const onFailure = (message) => {
    showAlert(message ? message : "Interview submission failed", "error");
  };

  return (
    <>
      <div
        style={{
          // position: "fixed",
          // left: 0,
          // right: 0,
          // top: 0,
          padding: "1rem 2rem ",
          backgroundColor: "rgba(255,255,255,0.9)",
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            width: isMobile ? "100%" : "unset",
          }}
        >
          <QuestionBox
            currentQuestionNo={currentQuestionNo}
            currentQuestionId={currentQuestionId}
            interviewQuestions={interviewQuestions}
            retakeQuestionId={retakeQuestionId}
          ></QuestionBox>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            width: isMobile ? "100%" : "unset",
            flexWrap: "wrap",
          }}
        >
          <Timer
            currentQuestionTimeLimit={currentQuestionTimeLimit}
            timerStarted={timerStarted}
            currentQuestionId={currentQuestionId}
            onStopRecording={onStopRecording}
          />
          <Actions
            onRecord={onRecord}
            onStopRecording={onStopRecording}
            isRecording={isRecording}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
      <InterviewContainer id="interview-container" allowFullScreen>
        <CanvasContainer
          id="container"
          style={{ aspectRatio: `${WIDTH}/${HEIGHT}`, transform: "scaleX(-1)" }}
        ></CanvasContainer>
        {startCountdown && (
          <Countdown
            startCountdown={startCountdown}
            hiddenQuestionTimer={hiddenQuestionTimer}
            timerEndTasks={handleCountDownEnd}
          />
        )}
      </InterviewContainer>

      {hiddenAlert && (
        <Alert
          open={hiddenAlert}
          icon={<InfoIcon className="alert-icon" />}
          alertText="Next Question will appear after you click “Proceed”. You will have 5 seconds to prepare before the recording automatically starts."
          onClick={closeHiddenAlert}
        />
      )}

      {showError && (
        <Alert
          open={showError}
          icon={<InfoIcon className="alert-icon" />}
          alertText={`${errorMessage}`}
          onClick={() => setShowError(false)}
        />
      )}
    </>
  );
};

export default VideoInterview;

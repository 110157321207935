import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import { Button, Checkbox, Input, Dropdown } from "components";

import Stack from "@mui/material/Stack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { InviteModalContainer, ModalActionButtons, InputWithDropDown, IWDBlock } from "assets/styles/main.styles";
import { errorMessages } from "config/constants";

const TemplateList = [
	{
		id: "Message 1",
		optionText: "Message 1",
		value: "Congratulations on being shortlisted for the position. We kindly request you to attend a video interview by clicking on the provided link in the email you will receive. Please ensure that you attend the interview before it expires.",
	},
	{
		id: "Message 2",
		optionText: "Message 2",
		value: "We are pleased to inform you that you have been shortlisted for an interview. Please check your email for the link to the video interview. Kindly ensure that you attend the interview before it expires.",
	},
];

const InviteCandidate = ({
	handleRequestCandidates,
	inviteCandidateData,
	handleUpdate,
	inviteFieldError,
	isSubmiting,
	isFromWebsite = false,
	resumeDir = [],
	isEdit = false,
}) => {
	const [templateValue, setTemplateValue] = useState("");
	const [candidateMsg, setCandidateMsg] = useState("");
	const [fieldError, setFieldError] = useState({});

	const handleSetCandidateMsg = (data) => {
		setCandidateMsg(data);
	};

	useEffect(() => {
		if (inviteFieldError && Object.keys(inviteFieldError)?.length) {
			setFieldError(inviteFieldError);
		}
	}, [inviteFieldError]);

	useEffect(() => {
		handleSetCandidateMsg(templateValue);
		handleRequestCandidates("message", templateValue);
	}, [templateValue]);

	useEffect(() => {
		setTemplateValue(TemplateList[0].value);
	}, []);

	const handleTemplateValue = (e) => {
		e.preventDefault();
		setTemplateValue(e.target.value);
	};

	const handleData = (e) => {
		validateData(e);
		if (e.target.type === "checkbox") {
			handleRequestCandidates(e.target.name, e.target.checked);
		} else {
			handleRequestCandidates(e.target.name, e.target.value);
		}
	};

	const validateData = (e) => {
		if (e.target.value === undefined || e.target.value === "") {
			setFieldError({ ...fieldError, [e.target.name]: errorMessages.required });
		} else {
			let temp = { ...fieldError };
			delete temp[e.target.name];
			setFieldError(temp);
		}
	};

	const downloadResume = () => {
		let resumeFile = "";
		if (resumeDir.length > 0) {
			const resumeObj = JSON.parse(resumeDir[0].docMeta);
			resumeFile = resumeObj.Location;
		}
		window.open(resumeFile, "download");
	};

	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} style={{ marginTop: "20px" }}>
				<div>
					<Input
						label="Invite Expires in(days)"
						type="number"
						placeholder="Enter Days"
						name="expireIn"
						value={inviteCandidateData?.expireIn}
						onChange={handleData}
						onBlur={validateData}
						error={!!fieldError["expireIn"]}
						errorText={fieldError["expireIn"]}
					/>
				</div>
				<div>
					{" "}
					{isEdit ? (
						<Grid item xs={3}>
							<Button variant="contained" btnText="Update" onClick={handleUpdate} loading={isSubmiting} disabled={isFromWebsite ? false : false} />
						</Grid>
					) : (
						<Grid item xs={3}>
							<Button
								variant="contained"
								btnText={`Add Candidates`}
								onClick={handleUpdate}
								loading={isSubmiting}
								disabled={isFromWebsite ? true : false}
							/>
						</Grid>
					)}
				</div>
			</Stack>
		</>
	);
};

export default InviteCandidate;

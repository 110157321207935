import React from "react";
import { Button } from "components";
import { useNavigate, useParams } from "react-router-dom";

const VidrecButton = () => {
  const navigate = useNavigate();
  const id = new URLSearchParams(window.location.search).get("id");
  const handleNavigate = () => {
    navigate(`/info-candidate/${id}`);
  };

  if (!id) return <></>;

  return (
    <div>
      <Button
        btnText="Apply Now"
        onClick={handleNavigate}
        className="add-button"
        variant="contained"
        size="small"
      />
    </div>
  );
};

export default VidrecButton;

import { useUser } from "contexts/user.context";
import { useMemo } from "react";
import { IsAccesible } from "utils";

const useRoleAccess = (currentRole) => {
  const { userDetails } = useUser();

  const hasAccess = useMemo(() => {
    if (userDetails?.userRoles?.length) {
      return IsAccesible(userDetails?.userRoles, currentRole)
    }
    return false
  }, [userDetails, currentRole]);

  return { hasAccess };
};

export default useRoleAccess;

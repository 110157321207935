import { memo } from "react";
import styled from "styled-components";

const Step = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 1em;
  border: 1px solid #1976d2;
  padding: 0.5rem;
  font-weight: bold;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  pointer-events: none;
`;

export const Count = memo(({ totalQuestions, currentQuestionNo }) => {
  return (
    <div style={{ display: "flex", justifyContent: "start" }}>
      {Array.from({ length: totalQuestions }).map((_, index) => {
        const item = index + 1;
        return (
          <Step key={item} color={currentQuestionNo === item ? "#88c4ff" : "#dbdbdb"} role="button">
            {item}
          </Step>
        );
      })}
    </div>
  );
});

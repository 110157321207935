import React, { useState } from "react";
import { Title, Button } from "components";
import {
  Box,
  BlockMargin,
  CreateJobContainer,
} from "assets/styles/main.styles";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const EmbedCode = ({ handleClose, id, displayVidrec }) => {
  const codeString = `<iframe width="100px" height="60px"  frameborder src="https://app.kapiree.com/vidrec-button?id=${id}"> </iframe>`;

  const [copySuccess, setCopySuccess] = useState("");

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  return (
    <CreateJobContainer>
      <BlockMargin>
        <Title level="h4" text="Copy the below code and paste it in your website." />
      </BlockMargin>
      <BlockMargin>
        <Box isGrey>
          <code>{codeString}</code>
        </Box>
      </BlockMargin>
      <Button
          startIcon={<ContentCopyIcon />}
          size="small"
          btnText="Copy to clipboard"
          variant="secondary"
          onClick={() => copyToClipboard(codeString)}
        />
        <span>{copySuccess}</span>
    </CreateJobContainer>
  );
};
export default EmbedCode;

import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { errorMessages } from "config/constants";
import { PageHeader, Button, Input, Title } from "components";
import {
  Box,
  SettingsLogoContainer,
  LogoUploadContainer,
  BlockMargin,
} from "assets/styles/main.styles";
import {
  getUserDetails,
  updateOrganizationDetails,
  generateApi,
  uploadOrgLogo,
} from "config/api.service";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useAlert } from "contexts/alert.context";
import { useNavigate } from "react-router-dom";
import { useUser } from "contexts/user.context";
import settings1Img from "../../../assets/images/settings-1.svg";
import settings2Img from "../../../assets/images/settings-2.svg";
import Graphs from "../Graphs";

const UserSettings = () => {
  const [details, setDetails] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [logo, setLogo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showAlert } = useAlert();
  const [apiKey, setApiKey] = useState("");
  const navigate = useNavigate();
  const { userDetails, isLoading } = useUser();

  const handleButtonGeneration = async () => {
    generateApi(
      {
        email: userDetails?.email,
      },
      successCallback,
      errorCallback
    );
  };

  const successCallback = (data) => {
    setApiKey(data?.apiKey);
    showAlert("API generated successfully", "success");
  };

  const errorCallback = (error) => {
    showAlert(error, "error");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (logo) {
      details["logo"] = logo;
    }
    if (details.orgName != "" && details.orgEmail != "") {
      setIsSubmitting(true);
      await updateOrganizationDetails(
        details,
        handleUpdateSuccess,
        handleSubmitError
      );
    } else {
      setIsSubmitting(false);
      handleSubmitError("Please fill mandatory fields.");
    }
  };

  const handleSubmitError = (errorMessage) => {
    showAlert(errorMessage || "something went wrong", "error");
    setIsSubmitting(false);
  };

  const handleUpdateSuccess = () => {
    showAlert("Successfully updated", "success");
    setIsSubmitting(false);
  };

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    getUserDetails(handleData);
  };

  const handleData = (data) => {
    let countryCode = "+91";
    let orgPhone = data.organization?.orgPhone;
    // if (
    //   data &&
    //   data.organization?.orgPhone != "" &&
    //   data.organization?.orgPhone != null
    // ) {
    //   countryCode = data.organization?.orgPhone?.substring(0, 2);
    //   orgPhone = data.organization?.orgPhone?.substring(2);
    // }
    setApiKey(data?.organization?.apiKey);
    setLogo(data?.organization?.orgLogo);
    setDetails({
      ...data?.organization,
      countryCode,
      orgPhone,
    });
  };

  const validateInput = (e) => {
    if (e.target.name) {
      if (e.target.value === "") {
        setFieldErrors({
          ...fieldErrors,
          [e.target.name]: errorMessages.required,
        });
      } else {
        let temp = { ...fieldErrors };
        delete temp[e.target.name];
        setFieldErrors(temp);
      }
    }
  };

  const handleFileUpload = async (e) => {
    if (e.target.files[0]) {
      const sizeInBytes = e.target.files[0].size;
      const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
      let temp = { ...fieldErrors };
      delete temp.logo;
      if (sizeInMB > 5) {
        temp = { ...temp, logo: errorMessages.fileSize };
        setFieldErrors(temp);
        return;
      }
      let data = new FormData();
      data.append("logo", e.target.files[0]);
      await uploadOrgLogo(data, handleFileUpdateSuccess, handleFileSubmitError);
    }
  };

  const handleFileUpdateSuccess = (res) => {
    setLogo(res.orgLogo);
    // showAlert("Successfully updated logo", "success");
  };

  const handleFileSubmitError = (errorMessage) => {
    showAlert(errorMessage || "something went wrong", "error");
  };

  const handleChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    getDetails();
  };

  if (!details) {
    return (
      <div>
        <PageHeader pageTitleText="Settings" />
        <Box>
          <CircularProgress />
        </Box>
      </div>
    );
  }

  return (
    <div style={{ padding: "2rem 1rem" }}>
      <Graphs />
      <PageHeader pageTitleText="Settings" />
      <Box>
        <>
          {/* {organizationDetails.orgLogo ? (
            <img src={organizationDetails.orgLogo} alt="logo" />
          ) : (
            <Input
              label={
                <>
                  Organization Logo <span style={{ color: "red" }}>*</span>
                </>
              }
              placeholder="Enter Organization Logo"
              name="logo"
              onChange={handleFileUpload}
              type="file"
              onBlur={validateInput}
              error={!!fieldErrors["logo"]}
              errorText={fieldErrors["logo"]}
            />
          )} */}
        </>
        <div
          style={{ position: "relative", width: "120px", marginBottom: "10px" }}
        >
          <SettingsLogoContainer>
            <img src={logo} alt="logo" />
          </SettingsLogoContainer>
          <LogoUploadContainer>
            <div className="uploadIcon">
              <input
                type="file"
                onClick={handleFileUpload}
                accept="image/png, image/jpeg, image/jpg"
              />
              <ModeEditOutlineOutlinedIcon />
            </div>
          </LogoUploadContainer>
        </div>
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item sm={12} xs={12} md={3}>
            <Input
              label={
                <>
                  Name of the Company <span style={{ color: "red" }}>*</span>
                </>
              }
              placeholder="Enter Name"
              name="orgName"
              onChange={handleChange}
              value={details?.orgName}
              onBlur={validateInput}
              error={!!fieldErrors["orgName"]}
              errorText={fieldErrors["orgName"]}
              readOnly
            />
          </Grid>
          <Grid item sm={12} xs={12} md={3}>
            <Input
              label="Company Website"
              placeholder="Enter Domain"
              name="orgDomain"
              onChange={handleChange}
              value={details?.orgDomain}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={3}>
            <Input
              label={
                <>
                  Email <span style={{ color: "red" }}>*</span>
                </>
              }
              placeholder="Enter Email"
              name="orgEmail"
              onChange={handleChange}
              error={!!fieldErrors["orgEmail"]}
              errorText={fieldErrors["orgEmail"]}
              onBlur={validateInput}
              value={details?.orgEmail}
              readOnly
            />
          </Grid>
          <Grid item sm={12} xs={12} md={3}>
            <Title
              level="label"
              text={
                <>
                  Phone Number <span style={{ color: "red" }}>*</span>
                </>
              }
            />
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Input
                  type="text"
                  placeholder={"+91"}
                  name="countryCode"
                  defaultValue={"+91"}
                  pattern="[^(\+?\d[0-9]{3})]"
                  onChange={handleChange}
                  vaulue={details.countryCode}
                  readOnly
                />
              </Grid>
              <Grid item xs={9}>
                <Input
                  type="text"
                  placeholder={"Phone number"}
                  name="orgPhone"
                  pattern="[0-9]{9}"
                  value={details.orgPhone}
                  onChange={handleChange}
                  maxLength={10}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <Input
              label="LinkedIn Profile"
              placeholder="Enter profile URL"
              name="orgLinkedIn"
              onChange={handleChange}
              value={details?.orgLinkedIn}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <Input
              label="Company Address"
              placeholder="Enter Address"
              name="orgAddress"
              onChange={handleChange}
              value={details?.orgAddress}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={12} style={{ marginBottom: "2rem" }}>
            <Input
              label="Bio"
              placeholder="Enter Address"
              name="orgBio"
              onChange={handleChange}
              value={details?.orgBio == null ? "" : details?.orgBio}
              isMulti
            />
          </Grid>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={3}
            alignItems="center"
            direction={"row-reverse"}
          >
            <Grid item xs={3} lg={2}>
              <Button
                btnText="Update"
                variant="contained"
                onClick={handleUpdate}
                disabled={isSubmitting}
              />
            </Grid>
            {/* <Grid item xs={3} lg={2}>
              <Button
                btnText="Cancel"
                variant="outlined"
                onClick={() => {
                  navigate("/");
                }}
              />
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
      {/* <BlockMargin></BlockMargin>
      <Box>
        <Title level="h4" text={"API Key generation"} />
        <BlockMargin size={10}></BlockMargin>
        <Grid container columnSpacing={3} rowSpacing={3}>
          <Grid item xs={12} lg={8}>
            <Input
              label={""}
              placeholder="Click generate button"
              name="api_key"
              value={apiKey}
              isDisabled
              className="apiKeyInput"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Button variant="contained" btnText="Generate" onClick={handleButtonGeneration} />
          </Grid>
        </Grid>
      </Box> */}
      <BlockMargin></BlockMargin>
      <Title
        level="h3"
        text="Website integrations"
        style={{ marginBottom: "0.5rem" }}
      />
      <Title
        level="body"
        text="Reach us to info@kapiree.com for integration support"
        style={{ marginBottom: "0.5rem" }}
      />
      <Grid container columnSpacing={3} rowSpacing={0}>
        <Grid item xs={12} lg={6}>
          <Box style={{ marginBottom: "1rem" }}>
            <img
              src={settings1Img}
              alt="website integration"
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>
          <Title
            level="h4"
            text="Generate Button Links"
            style={{ marginBottom: "0.5rem" }}
          />

          <Title
            level="body"
            text="Each job position can have an “Apply Now” button which can be linked directly with Kapiree using a unique link. This helps with adding the job positions manually to any sites irrespective  of the website technology used."
            style={{ marginBottom: "1rem" }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box style={{ marginBottom: "1rem" }}>
            <img
              src={settings2Img}
              alt="website integration"
              style={{ width: "100%", objectFit: "contain" }}
            />
          </Box>
          <Title
            level="h4"
            text="IFrame Intergration"
            style={{ marginBottom: "0.5rem" }}
          />

          <Title
            level="body"
            text="With some programing expertise Kapiree jobs can be integrated with your website career page using Iframes. This way the active jobs will always be automatically displayed on the career page."
            style={{ marginBottom: "1rem" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserSettings;

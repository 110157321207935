import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import { Button, Input, Title } from "components";
import {
  Box,
  CandidateWrapper,
  CandidateItemList,
  CandidateItem,
} from "assets/styles/main.styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import InviteCandidate from "../InviteCandidate";
import { inviteCandidate } from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { errorMessages } from "config/constants";
import {
  getInvitedCandidate,
  updateInvitedCandidate,
} from "config/api.service";

const EditCandidate = ({ handleClose, roleId, editId }) => {
  const [inviteFieldError, setInviteFieldError] = useState({});
  const [candidate, setCandidate] = useState({});
  const { showAlert } = useAlert();
  const [isFromWebsite, setIsFromWebsite] = useState(false);

  const [fieldError, setFieldError] = useState({
    name: null,
    email: null,
    countryCode: null,
    phoneNumber: null,
  });

  const [inviteDetails, setInviteDetails] = useState({
    message: "",
    expireIn: "3",
    isResumeRequired: true,
    sendRemider: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (editId) {
      getInvitedCandidate((data) => {
        setCandidate({
          ...data,
        });
        setIsFromWebsite(data.fromWebsite);
      }, Number(editId));
    }
  }, [editId]);

  const handleInviteCandidates = (name, value) => {
    setInviteDetails({ ...inviteDetails, [name]: value });
  };

  const validateDataWhileSubmit = () => {
    let haveError = false;
    let temp;
    let tempData = candidate;
    if (tempData.name === undefined || tempData.name === "") {
      temp = { ...temp, name: errorMessages.required };
      haveError = true;
    }
    if (tempData.email === undefined || tempData.email === "") {
      temp = { ...temp, email: errorMessages.required };
      haveError = true;
    }
    // if (tempData.countryCode === undefined || tempData.countryCode === "") {
    //   temp = { ...temp, countryCode: errorMessages.required };
    //   haveError = true;
    // }
    if (tempData.phoneNumber === undefined || tempData.phoneNumber === "") {
      temp = { ...temp, phoneNumber: errorMessages.required };
      haveError = true;
    }

    let inviteError = { ...inviteFieldError };
    if (inviteDetails.message.trim() === "") {
      inviteError = { ...inviteError, message: errorMessages.required };
      haveError = true;
    } else {
      delete inviteError.message;
    }
    if (inviteDetails.expireIn === null || inviteDetails.expireIn === "") {
      inviteError = { ...inviteError, expireIn: errorMessages.required };
      haveError = true;
    } else {
      delete inviteError.expireIn;
    }
    return { haveError, inviteError, temp };
  };

  const handleUpdate = async () => {
    const { haveError, inviteError, temp } = validateDataWhileSubmit();
    if (haveError) {
      setInviteFieldError(inviteError);
      setFieldError(temp);
      showAlert("some input fields have invalid data", "error");
      return;
    }
    const splittedName = candidate.name.split(" ");
    const postData = {
      candidateId: editId,
      roleId,
      data: {
        email: candidate.email,
        name: candidate.name,
        firstName: splittedName.length > 1 ? splittedName[0] : candidate.name,
        lastName: splittedName.length > 1 ? splittedName[1] : "",
        phoneNumber: `${candidate.phoneNumber}`,
        whatsappNo: `${candidate.phoneNumber}`,
        resumeMeta: "",
      },
      inviteDetails,
    };
    setIsSubmitting(true);
    await updateInvitedCandidate(
      postData,
      handleSubmitSuccess,
      handleSubmitFail
    );
    setIsSubmitting(false);
  };

  function handleSubmitSuccess() {
    showAlert("Candidate Updated Successfully", "success");
    handleClose();
  }

  function handleSubmitFail(errorMessage) {
    showAlert(errorMessage || "Something went wrong", "error");
  }

  const handleChange = (e) => {
    // candidate[e.target.name] = e.target.value;
    setCandidate({
      ...candidate,
      [e.target.name]: e.target.value,
    });
  };

  const validateData = (e) => {
    let temp;
    if (e.target.name === "name") {
      temp = {
        ...temp,
        name: e.target.value ? null : errorMessages.required,
      };
    } else if (e.target.name === "email") {
      temp = {
        ...temp,
        email: e.target.value ? null : errorMessages.required,
      };
    } else if (e.target.name === "countryCode") {
      temp = {
        ...temp,
        countryCode: e.target.value ? null : errorMessages.required,
      };
    } else if (e.target.name === "phoneNumber") {
      temp = {
        ...temp,
        phoneNumber: e.target.value ? null : errorMessages.required,
      };
    }
    setFieldError(temp);
  };

  return (
    <div>
      <CandidateWrapper>
        <Box isGrey>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Title level="label" text="Full Name" />
            </Grid>
            <Grid item xs={4}>
              <Title level="label" text="Email" />
            </Grid>
            <Grid item xs={4}>
              <Title level="label" text="Mobile" />
            </Grid>
          </Grid>
          <CandidateItemList>
            <CandidateItem>
              <Grid
                container
                spacing={2}
                alignItems="center"
                className="candidate-list"
              >
                <Grid item xs={4}>
                  <Input
                    name="name"
                    value={candidate.name}
                    onChange={handleChange}
                    error={!!fieldError["name"]}
                    errorText={fieldError["name"]}
                    onBlur={validateData}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    type="email"
                    name="email"
                    value={candidate.email}
                    onChange={handleChange}
                    error={!!fieldError["email"]}
                    errorText={fieldError["email"]}
                    onBlur={validateData}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    type="number"
                    name="phoneNumber"
                    value={candidate.phoneNumber}
                    onChange={handleChange}
                    error={!!fieldError["phoneNumber"]}
                    errorText={fieldError["phoneNumber"]}
                    onBlur={validateData}
                    maxLength={10}
                  />
                </Grid>
              </Grid>
            </CandidateItem>
          </CandidateItemList>
        </Box>
      </CandidateWrapper>
      <InviteCandidate
        handleRequestCandidates={handleInviteCandidates}
        inviteCandidateData={inviteDetails}
        handleUpdate={handleUpdate}
        inviteFieldError={inviteFieldError}
        isSubmiting={isSubmitting}
        isFromWebsite={isFromWebsite}
        resumeDir={candidate.resumeDir}
        isEdit={editId != null ? true : false}
      />
    </div>
  );
};

export default EditCandidate;

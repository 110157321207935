import React, { useEffect, useState } from "react";

import { Avatar, Grid } from "@mui/material";
import {
  BasicAnswerContainer,
  Box,
  CandidateInfoBlock,
  CIBBlock,
  InfoActionsBlock,
  SubtitleInfo,
  BasicQuestionItem,
  BasicAnswerItem,
} from "assets/styles/main.styles";
import { Tag, Title } from "components";
import { getBaseQuestionResponse } from "config/api.service";
import { FormatDate } from "utils";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  // return {
  //   sx: {
  //     bgcolor: stringToColor(name),
  //   },
  //   children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  // };
}

const BasicAnswer = ({ roleId, candidateDetails }) => {
  const [basicAnswers, setBasicAnswers] = useState([]);

  const handleBasicAnswers = (res) => {
    setBasicAnswers(res);
  };

  const handleBasicAnswersErr = (error) => {
    console.log(error);
  };

  useEffect(() => {
    (async () => {
      if (candidateDetails && roleId) {
        await getBaseQuestionResponse(
          { roleId, candidateId: candidateDetails.id },
          handleBasicAnswers,
          handleBasicAnswersErr
        );
      }
    })();
  }, [candidateDetails, roleId]);

  return (
    <BasicAnswerContainer>
      <InfoActionsBlock>
        <CandidateInfoBlock>
          <Avatar {...stringAvatar(candidateDetails?.name)} />
          <CIBBlock>
            <span>
              <Title level="h4" text={candidateDetails?.name} />{" "}
              {/* <Tag type="purple" label={candidateDetails?.id} /> */}
            </span>
            <SubtitleInfo>
              Interviewed on:{" "}
              {candidateDetails &&
                FormatDate(
                  new Date(candidateDetails.createdDate.split(" ")[0])
                )}
            </SubtitleInfo>
          </CIBBlock>
        </CandidateInfoBlock>
      </InfoActionsBlock>
      <Box isGrey>
        <Grid container spacing={2} alignItems="flex-start">
          {basicAnswers.map((item, index) => {
            return (
              <Grid item xs={12}>
                <BasicQuestionItem>
                  {index + 1}. {item.question}
                </BasicQuestionItem>
                <BasicAnswerItem>{item.answer ?? "No Answer"}</BasicAnswerItem>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </BasicAnswerContainer>
  );
};

export default BasicAnswer;

import styled, { css } from "styled-components";

export const FooterContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

export const FooterText = styled.p(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.semibold};
    line-height: 27px;
    a {
      color: #5a189a;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  `
);

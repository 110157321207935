import { MediaStreamComposer } from "@api.video/media-stream-composer";

export const WIDTH = 1280;
export const HEIGHT = 720;

export const composer = (() => {
  const mediaStreamComposer = new MediaStreamComposer({
    resolution: {
      width: WIDTH,
      height: HEIGHT,
    },
  });
  // mediaStreamComposer.addEventListener("recordingStopped", (e) => {
  //   let file = e.data.file;
  //   if (file) {
  //     let fileName = file.name;
  //     const a = document.createElement("a");

  //     let extension = file.type.split("/")[1]
  //       ? file.type.split("/")[1].split(";")[0]
  //       : "mp4";
  //     a.href = URL.createObjectURL(file);
  //     a.download = `${fileName}.${extension}`;
  //     a.click();
  //   }
  // });
  return mediaStreamComposer;
})();

export const openFullscreen = (element) => {
  if (!document.fullscreenElement) {
    element.requestFullscreen({ navigationUI: "hide" });
  } else if (element.webkitRequestFullscreen) {
    /* Safari */
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    /* IE11 */
    element.msRequestFullscreen();
  }
};

export const removeSpaceAndToLowerCase = (str) => {
  if (typeof str !== "string") return str;
  return str.replace(/\s+/g, "")?.toLowerCase();
};

export const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
};

export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  }
};

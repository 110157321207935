import {
  ActionsContainer,
  FullScreenContainerWithOverflow,
  IconWrapper,
  IconWrapperContainer,
  GotItWrapper,
} from "assets/styles/main.styles";
import Header from "components/CameraWindow/Header";
import EducationImgMobile from "assets/images/Video_Interview_instructions_mobile.webp";
import InterviewChecklistBg from "assets/images/instructions.png";
import { useNavigate } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";
import { IconButton } from "@mui/material";
import Button from "components/Button";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";

const Instructions = () => {
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  return (
    <div style={{ minHeight: "100vh", overflow: "hidden" }}>
      <Header
        item={{
          question: "Read video interview instructions clearly",
          subtitle: "Please read and understand instructions clearly for smooth completion of your interview",
        }}
        retakeAvailable={false}
        handleSubmit={() => {
          navigate("/candidate-interview", { replace: true });
        }}
        enableSubmit={true}
        educate={true}
        style={{
          position: "absolute",
          top: !isMobile ? "2rem" : "1rem",
          left: !isMobile ? "4rem" : "1rem",
          right: !isMobile ? "4rem" : "1rem",
          width: "unset",
        }}
      />
      <img
        className="webcam"
        src={isMobile ? EducationImgMobile : InterviewChecklistBg}
        alt="interview-instructions"
        style={{
          width: isMobile ? "100%" : "60%",
          height: isMobile ? "100vh" : "100vh",
          objectFit: isMobile ? "contain" : "contain",
          position: isMobile ? "relative" : "absolute",
          transform: isMobile ? "unset" : "translateX(30%)",
        }}
      />
      {/* {isMobile && (
        <ActionsContainer isVideoInterviewInstruction>
          <IconWrapperContainer>
            <IconWrapper>
              <IconButton
                onClick={() => {
                  navigate("/candidate-interview", { replace: true });
                }}
              >
                <KeyboardBackspace className="action-icon" />
              </IconButton>
            </IconWrapper>
          </IconWrapperContainer>
        </ActionsContainer>
      )} */}
      <GotItWrapper style={isMobile ? { margin: "2rem 0", display: "flex", justifyContent: "center" } : {}}>
        <Button
          onClick={() => {
            navigate("/candidate-interview", { replace: true });
          }}
          variant="contained"
          btnText={"Got it"}
        />
      </GotItWrapper>
    </div>
  );
};
export default Instructions;

import styled from "styled-components";

export const SaveIcon = styled.img`
  //use buttona and pass icon there
  width: 20px;
  height: 20px;
`;
export const AddBasicQuestion = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 5px;
  label {
    display: none;
  }
  input {
    min-width: 500px;
    width: 100%;
  }
  button {
    margin-top: 24px;
  }
`;
export const CheckboxListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const EditIcon = styled.img`
  width: 20px;
  height: 20px;
`;
export const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  color: grey;
`;
export const CheckboxItemList = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  button {
    visibility: hidden;
  }
  &:hover button {
    visibility: visible;
    cursor: pointer;
  }
  .Checkbox-Question {
    width: 400px;
    margin: 10px 0;
  }
`;

export const WrapperDiv = styled.div``;

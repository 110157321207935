import React from "react";

import { H1, H2, H3, H4, H5, H6, Label, Body } from "./title.styles";

const Title = ({ level, text, style }) => {
  switch (level) {
    case "h2":
      return <H2 style={style}>{text}</H2>;
    case "h3":
      return <H3 style={style}>{text}</H3>;
    case "h4":
      return <H4 style={style}>{text}</H4>;
    case "h5":
      return <H5 style={style}>{text}</H5>;
    case "h6":
      return <H6 style={style}>{text}</H6>;
    case "body":
      return <Body style={style}>{text}</Body>;
    case "label":
      return <Label style={style}>{text}</Label>;
    case "h1":
    default:
      return <H1 style={style}>{text}</H1>;
  }
};

export default Title;

import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import InterviewIllustration from "assets/images/interviewIllustration.svg";
import { Button, Title, Header } from "components";
import {
  SectionContainer,
  Box,
  ContentCenter,
  BlockMargin,
  CheckList,
  CheckListItem,
  FlexColumn,
  PaddingBlock,
  AppriciationButtonWrapper,
} from "assets/styles/main.styles";
import LeftColumnImg from "assets/images/image-371.svg";
import { useLocation, useNavigate } from "react-router-dom";
import PoweredComponent from "components/PoweredComponent/PoweredComponent";
import EmailImage from "assets/images/email_image.png";
import { appUrl } from "config/constants";
import useWindowSize from "hooks/useWindowSize";
import { webAuth } from "config/auth-config";
import { candidateAppUrl } from "config/constants";
import { useAlert } from "contexts/alert.context";

const Error = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const { showAlert } = useAlert();

  useEffect(() => {
    showAlert("Please login with the registered email", "error");
  }, []);

  return (
    <>
      <Header />
      <BlockMargin size={100}></BlockMargin>

      {!isMobile && <BlockMargin size={150}></BlockMargin>}
      <Container maxWidth="md" style={{ paddingBottom: "40px" }}>
        <Box>
          <PaddingBlock padding={20}>
            <FlexColumn gap={10}>
              <ContentCenter>
                <BlockMargin size={1}>
                  <h1
                    style={{
                      fontSize: "30px",
                      marginBottom: "20px",
                      lineHeight: "40px",
                    }}
                  >
                    Login Failed
                  </h1>
                  <Title
                    level="h4"
                    text={
                      <>
                        Logout from the website and login again with the
                        registered email.
                      </>
                    }
                  />
                </BlockMargin>
                {/* <Button
                  btnText="Login"
                  variant="contained"
                  onClick={() => {
                    // navigate(generatedLink(), {
                    //   replace: true,
                    // })
                    // webAuth.logout();
                    webAuth.authorize(
                      {
                        connection: "google-oauth2",
                        redirectUri: candidateAppUrl,
                        responseType: "token id_token",
                        scope: "openid profile email offline_access",
                      },
                      (err, result) => {
                        if (err) {
                          console.log("failed to login");
                          return;
                        }
                        localStorage.setItem(
                          "token",
                          webAuth.currentSession()?.idToken.jwtToken
                        );
                        console.log("result", result);
                      }
                    );
                  }}
                  style={{
                    width: "200px",
                    marginTop: "20px",
                  }}
                /> */}
              </ContentCenter>
              {/* <img src={LeftColumnImg} alt={LeftColumnImg} /> */}
              <img
                src={InterviewIllustration}
                alt="Interview"
                height={250}
                style={{ width: "100%" }}
              />
              <p style={{ fontSize: "12px", textAlign: "center" }}>
                If you have any technical issues or questions, contact our
                support team at{" "}
                <a href="mailto:info@kapiree.com">info@kapiree.com</a>. We look
                forward to learning more about you through this video interview.
                Good luck
              </p>
            </FlexColumn>
          </PaddingBlock>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PoweredComponent />
          </div>
        </Box>
      </Container>
    </>
  );
};

export default Error;

import { Grid } from "@mui/material";
import React from "react";
import {
  ProgressWithBadge,
  Badge,
  ProgressWithLabel,
  BadgeTitle,
  BadgeSubtext,
  BadgeIcon,
  NextAction,
  ProgressItem,
  BadgeLabel,
} from "../ProgressStepper/stepper.styles";

import BadgeIcon1 from "assets/images/badges/badge1.svg";
import Button from "components/Button";
import useWindowSize from "hooks/useWindowSize";

const Header = ({
  item,
  retakeAvailable,
  handleRetake,
  handleSubmit,
  enableSubmit,
  educate,
  isSubmitingKyc,
  style,
}) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  return (
    <ProgressWithBadge style={style}>
      <ProgressItem>
        <Grid container className="badge-grid">
          {/* <Grid item xs={1}>
            <Badge>
              <BadgeIcon src={BadgeIcon1} alt={BadgeIcon1} />
              <BadgeLabel>level 0</BadgeLabel>
            </Badge>
          </Grid> */}
          <Grid item xs={isMobile ? 12 : 9}>
            <ProgressWithLabel>
              <BadgeTitle style={{ marginBottom: "10px" }}>{item.question}</BadgeTitle>
              {item.subtitle && <BadgeSubtext>{item.subtitle}</BadgeSubtext>}
            </ProgressWithLabel>
          </Grid>
          {isMobile ? null : (
            <Grid item xs={3} display="flex" flexDirection="column" justifyContent="space-around">
              <div></div>
              <NextAction>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {retakeAvailable && <Button btnText={`Retake`} variant="outlined" onClick={handleRetake} />}
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      btnText={educate ? "Got it" : "Submit"}
                      disabled={!enableSubmit}
                      loading={isSubmitingKyc}
                    />
                  </Grid>
                </Grid>
              </NextAction>
            </Grid>
          )}
        </Grid>
      </ProgressItem>
    </ProgressWithBadge>
  );
};

export default Header;

import React from "react";
import "./poweredcomponent.css";

const PoweredComponent = () => {
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <h5 class="textStyles">
              Powered by&nbsp;
              <a href="http://www.kapiree.com" role={"button"}>
                <img
                  align="center"
                  alt="kapiree"
                  src={
                    "https://vidrec-bkt-01.s3.ap-south-1.amazonaws.com/logo/WhatsApp+Image+2023-07-06+at+6.22.03+PM.jpeg"
                  }
                  style={{ maxWidth: "80px", cursor: "pointer" }}
                />
              </a>
            </h5>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PoweredComponent;

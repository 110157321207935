import { Backdrop } from "@mui/material";
import { Countdown } from "components/ProgressStepper/stepper.styles";
import { useEffect, useState } from "react";

const CustomCountdown = ({ open, hiddenQuestionTimer, timerEndTasks }) => {
  const [timer, setTimer] = useState(hiddenQuestionTimer);

  useEffect(() => {
    if (open === true) {
      countDownFunction();
    }
    return () => {
      clearInterval(preHiddenQuestionTimer);
    };
  });

  var preHiddenQuestionTimer;
  const countDownFunction = () => {
    preHiddenQuestionTimer = setInterval(() => {
      if (timer === 0) {
        setTimer(timer);
        timerEndTasks();
      } else {
        setTimer(timer - 1);
      }
    }, 1000);
  };

  const getDigits = (number) => {
    return number.toString().length;
  };

  return (
    <Backdrop sx={{ zIndex: "9" }} open={open}>
      <Countdown>{`00:${
        getDigits(timer) > 1 ? timer : "0" + timer
      }`}</Countdown>
    </Backdrop>
  );
};

export default CustomCountdown;

import { BorderBottom } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import useWindowSize from "./useWindowSize";

const useMediaController = (videoRef) => {
  const [isRecording, setIsRecording] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoDetails, setVideoDetails] = useState([]);
  const windowSize = useWindowSize();

  const handleVideoData = (data) => {
    setVideoData(data);
  };

  const handleVideoDetails = (data) => {
    setVideoDetails(data);
  };

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  const reset = () => {
    setVideoSrc(null);
    setVideoData(null);
  };

  const getVideo = () => {
    const deviceIds = JSON.parse(localStorage.getItem("deviceIds"));
    navigator?.mediaDevices
      ?.getUserMedia({
        video: {
          deviceId: deviceIds ?  deviceIds.videoDeviceId  : true,
          width: windowSize.width,
          height: windowSize.height,
        },
        // audio: {
        //   deviceId: deviceIds.audioDeviceId,
        // },
      })
      .then((stream) => {
        let video = videoRef.current;
        if (video) {
          video.srcObject = stream;
          video.play();
        }
      })
      .catch((err) => console.log(err));
  };

  return {
    videoDetails,
    handleVideoDetails,
    isRecording,
    startRecording,
    stopRecording,
    videoData,
    videoSrc,
    reset,
    getVideo,
  };
};

export default useMediaController;

import CameraWindow from "components/CameraWindow";
import React from "react";
import { useInterviewDataContext } from "contexts/interviewData";

const CandidataKYC = () => {
  const { interviewData } = useInterviewDataContext();
  return <CameraWindow interviewUserData={interviewData} />;
};

export default CandidataKYC;

import styled, { css } from "styled-components";

export const InputContainer = styled.div(
  ({
    theme: {
      colors: { black, primaryBlue, red },
      fontWeight,
    },
  }) => css`
    & .MuiInputBase-input {
      font-weight: 400;
    }
    & .MuiInputLabel-root.Mui-focused {
      color: ${black};
    }
    & .MuiInputLabel-root {
      font-weight: ${fontWeight.semibold};
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${primaryBlue};
    }
    & .Mui-error input {
      border: 1px solid ${red};
    }
    & .TextArea-input {
      &:focus-visible {
        outline: none !important;
      }
    }
    .input-focus-only {
      .MuiInputBase-input {
        background-color: transparent;
        border: 1px solid transparent;
        &:focus {
          background-color: #fff;
          border: 1px solid #8c8c8c;
        }
      }
    }
    .label-focus-only {
      font-size: 20px;
    }
  `
);

export const ErrorText = styled.div(
  ({
    theme: {
      colors: { red },
    },
  }) => css`
    font-size: 12px;
    margin: 5px 0 0 0;
    color: ${red};
  `
);

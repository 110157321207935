import React, { useState } from "react";
import { Rating, Grid } from "@mui/material";
import { Title, Modal } from "components";
import {
  VideoContainer,
  ReviewCardContainer,
  ReviewModalHeader,
  ReviewQuestionWrapper,
  PlayButton,
  ReviewAnswerCardContainer,
  ReviewAnswerRatingWrapper,
} from "assets/styles/main.styles";
import Play from "assets/images/play.png";
import { RatingValues } from "config/constants";
import VideoThumbnailPlaceholder from "assets/images/video-thumbnail.svg";
import Stack from "@mui/material/Stack";

import { format } from "date-fns";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useWindowSize from "hooks/useWindowSize";
const QuestionAnswers = ({ data, reviewData, feedback, currentQuestionNo, handleModalOpen }) => {
  const [thumbnailIsPresent, setThumbnailIsPresent] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const questionFeedback = feedback.find((item) => item.questionId === data?.qId);

  const isImagePresent = () => {
    var image = new Image();
    image.src = data?.vidMeta?.assets?.thumbnail;
    if (image.complete) {
      setThumbnailIsPresent(true);
    } else {
      image.onload = () => {
        setThumbnailIsPresent(true);
      };

      image.onerror = () => {
        setThumbnailIsPresent(false);
      };
    }
  };

  isImagePresent();

  const currentReviewData = reviewData.find((item) => item.questionId === data?.qId);
  const rating = feedback.length === reviewData.length ? data?.rating?.rating : currentReviewData?.rating;

  const RatingBlock = () => {
    return (
      <>
        {/* {rating != 0 && rating != undefined && ( */}
        <ReviewAnswerRatingWrapper>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Stack direction="row" spacing={2}>
                <Rating
                  name="simple-controlled"
                  value={rating ?? 0}
                  defaultValue={rating ?? 0}
                  precision={1}
                  size="large"
                  readOnly
                />
                <Title level="h6" text={rating !== null && <b>{RatingValues[rating]}</b>} />
              </Stack>
            </Grid>
            {/* <Grid xs  sx={{ paddingTop: "10px" }}>
                <Title
                  level="h6"
                  text={format(data?.vidMeta?.createdAt, "PPp")}
                />
              </Grid> */}
          </Grid>
        </ReviewAnswerRatingWrapper>
        {/* )} */}
      </>
    );
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",

          cursor: "pointer",
          "&:hover": {
            transform: "translateY(-1px)",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)",
          },
        }}
        variant="outlined"
        elevation={6}
        onClick={() => {
          handleModalOpen();
        }}
      >
        <VideoContainer>
          {thumbnailIsPresent ? (
            <CardMedia sx={{ height: 230, width: "100%" }} image={data?.vidMeta.assets.thumbnail} title="" />
          ) : (
            <CardMedia sx={{ height: 230, width: "100%" }} image={VideoThumbnailPlaceholder} title="" />
          )}

          <PlayButton>
            <img src={Play} alt="play" />
          </PlayButton>
        </VideoContainer>
        <CardContent sx={{ paddingBottom: 0 }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              maxHeight: "12vh",
              margin: 0,
              fontSize: "1rem",
              whiteSpace: isMobile ? "wrap" : "nowrap",
              overflow: "hidden",
              width: "100%",
              textOverflow: "ellipsis",
            }}
          >
            {`${currentQuestionNo}. ${data?.question}`}
          </Typography>
        </CardContent>
        <CardActions sx={{ minHeight: "40px", paddingTop: 0 }}>
          {feedback.length === reviewData.length ? (
            <Stack direction="row" spacing={"10px"}>
              <Rating name="hover-feedback" value={questionFeedback?.rating} precision={1} readOnly />
              {questionFeedback?.rating !== null && (
                <span style={{ paddingTop: "5px" }}>{RatingValues[questionFeedback?.rating]}</span>
              )}
            </Stack>
          ) : (
            <>{RatingBlock()}</>
          )}
        </CardActions>
      </Card>

      {/* <ReviewAnswerCardContainer
        isClickable={true}
        onClick={() => {
          handleModalOpen();
        }}
      >
        
        <VideoContainer>
          <>
            {thumbnailIsPresent ? (
              <img
                className="thumbnail"
                src={data?.vidMeta.assets.thumbnail}
                alt="thumbnail"
              />
            ) : (
              <img
                src={VideoThumbnailPlaceholder}
                alt="thumbnail"
                align="center"
              />
            )}
            <PlayButton>
              <img
                src={Play}
                alt="play"
                onClick={() => {
                  handleModalOpen();
                }}
              />
            </PlayButton>
          </>
        </VideoContainer>
        <ReviewQuestionWrapper><Title level="h4" text={`${currentQuestionNo}. ${data?.question}`} /></ReviewQuestionWrapper>
        
        {feedback.length === reviewData.length ? (
          <Stack direction="column" spacing={"10px"}>
            <Rating
              name="hover-feedback"
              value={questionFeedback?.rating}
              precision={1}
              readOnly
            />
            {questionFeedback?.rating !== null && (
              <span style={{ paddingTop: "5px" }}>
                {RatingValues[questionFeedback?.rating]}
              </span>
            )}
          </Stack>
        ) : (
          <>{RatingBlock()}</>
        )}
      </ReviewAnswerCardContainer> */}
    </>
  );
};

export default QuestionAnswers;

import styled from "styled-components";

export const SearchWithIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MuiInputBase-root {
    margin-top: 0;
    margin-left: 0;
  }
  > div {
    flex-grow: 1;
  }
  & input[type="text"] {
    border: 1px solid #d2d2d2;
    border-left: none;
    color: #595959;
    padding: 0 8px;
    background: #f6f7f9;
    outline: none;
    border-radius: 0 4px 4px 0;
    font-family: "Manrope", sans-serif !important;
    font-size: 14px;
    height: 32px;
    min-width: 250px;
    line-height: 32px;
  }

  & input[type="text"]::placeholder {
    font-family: "Manrope", sans-serif !important;
    font-size: 12px;
    line-height: 32px;
  }

  & button {
    padding: 8px 0 8px 12px;
    background: #f6f7f9;
    color: #595959;
    font-size: 16px;
    border: 1px solid #d2d2d2;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`;

import styled, { css } from "styled-components";

export const HeaderContainer = styled.div`
  padding: 8px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 65px;
  position: ${(props) => (props.notFixed ? "unset" : "fixed")};
  top: 0;
  width: ${(props) => (props.notFixed ? "100%" : "100%")};
  z-index: 999;
  /* min-height: 64px; */
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  margin-right: 40px;
`;

export const LeftMenuBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  flex-grow: 1;
  & .link {
    text-decoration: none;
    color: inherit;
  }
`;

export const MenuItemList = styled.div(
  ({
    theme: {
      colors: { primaryBlue, black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    padding: 6px 16px;
    font-weight: ${fontWeight.medium};
    min-height: 32px;
    font-size: ${fontSize.f16};
    line-height: 32px;
    background: ${(props) => (props.isActive ? "#DDF3FF" : "transparent")};
    color: ${(props) => (props.isActive ? primaryBlue : black)};
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    & .home-icon {
      fill: ${(props) => (props.isActive ? "#005585" : "#8c8c8c")};
      margin-bottom: -4px;
    }
    &:hover {
      background: #daeaf3;
    }
  `
);

export const RightMenuBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchMenuBlock = styled.div`
display: flex;
  width: 30%;
  align-items: right;
`;

export const ProfileBlock = styled.div`
  margin: 0;
`;

export const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const MenuArrow = styled.div`
  margin-left: 16px;
`;

export const MenuIconFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex;
  svg {
    margin-right: 8px;
  }
`;

export const AvatarContainer = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  overflow: hidden;

  img {
    max-width: 100%;
  }
`;

export const SearchCandidatesText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  h1 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }
  p {
    font-size: 12px;
    font-weight: 400;
  }
`;

import LaptopIcon from "@mui/icons-material/Laptop";
import { ReactComponent as JobIcon } from "assets/images/icons/job-listing.svg";

export const menuRoutes = [
  {
    menu: "Dashboard",
    link: "/dashboard",
    icon: <LaptopIcon color="primary" />,
  },
  {
    menu: "Job Listings",
    link: "/job-listings",
    icon: <JobIcon />,
  },
  // {
  //   menu: "Interview Dashboard",
  //   link: "/interview-dashboard/?id=1860&role=40",
  //   icon: <JobIcon />,
  // },
];

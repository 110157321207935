import React, { useState, useCallback, useRef, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import confetti from "canvas-confetti";
import MobileStepper from "@mui/material/MobileStepper";
import styles from "../VideoCam/components/styles/Home.module.css";

import { Box, IconButton, Grid, Backdrop, LinearProgress, CircularProgress, Select, MenuItem } from "@mui/material";

import InterviewWindow from "./components/InterviewWindow";
import { Button, Title, Modal, Videoview, VideoThumbnail } from "components";

import BadgeIcon1 from "assets/images/badges/badge1.svg";
import BadgeIcon2 from "assets/images/badges/badge2.svg";
import RedDotImg from "assets/images/icons/Recording.svg";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ReplayIcon from "@mui/icons-material/Replay";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import InfoIcon from "@mui/icons-material/Info";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import StartRecordingIcon from "@mui/icons-material/FiberManualRecord";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  TabContentContainer,
  ProgressContainerBlock,
  ProgressBlock,
  ProgressWithBadge,
  Badge,
  ProgressWithLabel,
  BadgeTitle,
  BadgeSubtext,
  BadgeIcon,
  InterviewContainer,
  InterviewActions,
  TimeCounter,
  IAactionsflex,
  NextAction,
  ProgressItem,
  RedDot,
  BadgeLabel,
  Countdown,
  Step,
} from "./stepper.styles";
import AlertBox from "./components/AlertBox";

import useMediaController from "hooks/useMediaController";
import { saveInterviewAnswers, updateInterviewAnswers } from "config/api.service";
import { questionType, recordingStatus } from "config/constants";
import useAuthValidate from "hooks/useAuthValidate";
import { BlockMargin, CodeVideoBlock, ThumbnailContainer, VideoThumbnailContainer } from "assets/styles/main.styles";
import { useAlert } from "contexts/alert.context";
import useWindowSize from "hooks/useWindowSize";
import { ActionsContainer, IconWrapper, IconWrapperContainer } from "assets/styles/main.styles";
import Refresh from "@mui/icons-material/Refresh";
import KeyboardBackspace from "@mui/icons-material/KeyboardBackspace";
import { Check, Pause, PlayArrow } from "@mui/icons-material";
import useVideoPlayer from "hooks/useVideoPlayer";
import CustomCountdown from "./components/CountDownComponent/CustomCountdown";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import { ButtonContainer } from "../Button/button.styles";

const ProgressStepper = ({
  interviewQuestions,
  interviewDetails,
  handleSetInterviewDatas,
  interviewDatas,
  loadedSteps,
  setLoadVideoView,
}) => {
  const [activeStep, setActiveStep] = useState(loadedSteps ?? 0);
  const { logOutHandler } = useAuthValidate();
  const { showAlert } = useAlert();
  const [recordStatus, setRecordStatus] = useState(false);
  const [isPlayable, setIsPlayable] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [open, setOpen] = useState(false);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const videoRef = useRef();
  const videoTagRef = useRef(null);
  const [timerEnded, setTimerEnded] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);
  const [questionId, setQuestionId] = useState(0);
  const [startTimer, setStartTimer] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [timeLimit, setTimeLimit] = useState(null);
  const [retakeAvailable, setRetakeAvailable] = useState(0);
  const [retakeNo, setRetakeNo] = useState(0);
  const [videoLink, setVideoLink] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [audioDevices, setAudioDevices] = useState([]);
  const [videoRecordStatus, setVideoRecordStatus] = useState(recordingStatus.STAND_BY);

  const {
    playerState,
    togglePlay,
    timeCounter: showVidPlayerTimerCounter,
    reset: resetVideoPlayer,
  } = useVideoPlayer(videoTagRef);
  const [audioSource, setAudioSource] = useState("none");

  useEffect(() => {
    const deviceIds = JSON.parse(localStorage.getItem("deviceIds"));

    navigator?.mediaDevices
      ?.getUserMedia({ audio: true, video: deviceIds ? deviceIds.videoDeviceId : true })
      .then((stream) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          setAudioDevices(devices.filter((d) => d.kind === "audioinput"));
          stream.getTracks().forEach((x) => x.stop());
        });
      })
      .catch((e) => console.log(e));
  }, []);

  // const handleHiddenQuestionTimer = (data) => {
  //   setHiddenQuestionTimer(data);
  // };

  const handleLoading = (load) => {
    setIsLoading(load);
  };

  const faceDetectionReport = useRef([]);
  const hiddenQuestionTimer = 10;

  // confetti
  const triggerConfetti = useCallback(() => {
    confetti({
      particleCount: 150,
      spread: 60,
    });
  }, []);

  const handleSeconds = (data) => {
    setSeconds(data);
  };

  const handleMinutes = (data) => {
    setMinutes(data);
  };

  const handleVideoLink = (videoData) => {
    setVideoLink(videoData);
  };

  const getDigits = (number) => {
    return number.toString().length;
  };

  var timer;

  useEffect(() => {
    if (startTimer) {
      timerFunction();
      return () => {
        clearInterval(timer);
      };
    }
  });

  const timerFunction = () => {
    let timeLimitSecond = 0;
    let timeLimitMinute = 0;
    if (timeLimit * 60 > 60) {
      timeLimitSecond = 0;
      timeLimitMinute = timeLimit;
    } else {
      timeLimitSecond = timeLimit * 60;
      timeLimitMinute = 0;
    }
    timer = setInterval(() => {
      handleSeconds(seconds + 1);
      if (timeLimitMinute === 0) {
        if (timeLimitSecond <= seconds + 1) {
          handleRecordStop();
        }
      }
      if (seconds === 59) {
        handleSeconds(0);
        handleMinutes(minutes + 1);
        if (timeLimitMinute <= minutes + 1) {
          if (timeLimitSecond <= seconds + 1) {
            handleRecordStop();
          }
        }
      }
    }, 1000);
  };

  const [capture, setCapture] = useState(false);
  const [hiddenAlert, setHiddenAlert] = useState(false);

  const handleCapture = () => {
    setCapture(true);
  };

  const handleCountDownEnd = () => {
    setOpen(false);
    handleRecordStart();
    onRecordClicked();
    setTimerEnded(true);
  };

  // Record func handlers
  const handleRecordStart = (timeLimit, retake) => {
    setRecordStatus(true);
    setTimeLimit(timeLimit ?? 3);
    setStartTimer(true);
    setRetakeAvailable(retake ?? 0);
    handleQuestionAnswer(interviewQuestions[activeStep].id);
  };

  const handleRecordStop = () => {
    let temp = [...interviewDatas];
    temp[activeStep].isRecorded = true;
    handleSetInterviewDatas(temp);
    setRecordStatus(false);
    setIsPlayable(true);
    setShowNav(true);
    setStartTimer(false);
  };

  const handleVideoDetails = (data) => {
    setVideoDetails(data);
  };

  const handleSubmit = () => {
    triggerConfetti();
    setLoadVideoView(true);
  };

  const closeHiddenAlert = () => {
    setHiddenAlert(false);
    openTimer();
  };

  const showHiddenAlert = () => {
    setHiddenAlert(true);
  };

  const openTimer = () => {
    setOpen(true);
    setIsPlayable(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    console.log("success");
  };

  useEffect(() => {
    if (interviewQuestions[activeStep]?.type === questionType.HIDDEN_QUESTION) {
      showHiddenAlert();
    }
  }, [activeStep, interviewQuestions]);

  useEffect(() => {
    if (interviewDatas.length && interviewDatas[activeStep]) {
      setVideoSrc(interviewDatas[activeStep]?.vidMeta?.assets?.mp4 ?? null);
      handleVideoDetails(interviewDatas[activeStep]?.vidMeta);
      handleFaceDetection([]);
      setSeconds(0);
      setMinutes(0);
      setShowNav(interviewDatas[activeStep]?.vidMeta?.assets?.mp4 ? true : false);
      setRetakeNo(interviewDatas[activeStep].retakeTaken);
      handleQuestionAnswer(interviewQuestions[activeStep]?.id);
      if (!interviewDatas[activeStep]?.vidMeta) {
        setVideoRecordStatus(recordingStatus.STAND_BY);
      } else {
        setVideoRecordStatus(recordingStatus.STOP_RECORDING);
      }
    }
  }, [activeStep]);

  const onFailure = () => {
    showAlert("Interview submission failed", "error");
  };

  const validateSteperClick = (clickedStep) => {
    const noOfSubmittedSteps = interviewDatas.filter((item) => item.isSubmitted === true).length;
    return !recordStatus && noOfSubmittedSteps >= clickedStep && !isLoading;
  };

  const answerSubmit = async () => {
    let temp = [...interviewDatas];
    temp[activeStep].isSubmitted = true;
    handleSetInterviewDatas(temp);
    const data = {
      inviteUUID: interviewDetails.inviteUUID,
      qId: interviewQuestions[activeStep]?.id,
      metaObj: JSON.stringify(faceDetectionReport.current),
      answer: "",
      candidateId: interviewDetails.candidateId,
      vid: temp[activeStep],
    };
    await saveInterviewAnswers(data, onSuccess, onFailure);
  };

  const answerUpdate = async () => {
    let temp = [...interviewDatas];
    handleSetInterviewDatas(temp);
    const data = {
      inviteUUID: interviewDetails.inviteUUID,
      qId: interviewQuestions[activeStep]?.id,
      metaObj: JSON.stringify(faceDetectionReport.current),
      answer: "",
      candidateId: interviewDetails.candidateId,
      vid: temp[activeStep],
    };
    await updateInterviewAnswers(data, onSuccess, onFailure);
  };

  const handleQuestionAnswer = (qId) => {
    setQuestionId(qId);
  };

  const handleFaceDetection = (data) => {
    faceDetectionReport.current = data;
  };

  // useEffect(() => {
  //   if (detectionReport.length && recordStatus === true) {
  //     handleFaceDetection([...faceDetectionReport.current, ...detectionReport]);
  //   }
  // }, [detectionReport]);

  const handleRetake = () => {
    let temp = [...interviewDatas];
    temp[activeStep].retakeTaken = temp[activeStep].retakeTaken + 1;
    temp[activeStep].vidMeta = null;
    temp[activeStep].isRecorded = false;
    handleSetInterviewDatas(temp);
    handleVideoDetails(null);
    setVideoSrc(null);
    handleFaceDetection([]);
    setSeconds(0);
    setMinutes(0);
    setShowNav(false);
    setRetakeNo(retakeNo + 1);
    resetVideoPlayer();
    setVideoRecordStatus(recordingStatus.STAND_BY);
  };

  const handleActiveStep = (step) => {
    validateSteperClick(step) && setActiveStep(step);
  };

  const handleVideoData = async (data) => {
    let temp = [...interviewDatas];
    temp[activeStep].vidMeta = data;
    await handleSetInterviewDatas(temp);
    handleAnswerSubmit();
  };

  const onRecordClicked = () => {
    if (videoRecordStatus === recordingStatus.STAND_BY) {
      setVideoRecordStatus(recordingStatus.START_RECORDING);
    } else if (videoRecordStatus === recordingStatus.START_RECORDING) {
      setVideoRecordStatus(recordingStatus.STOP_RECORDING);
    }
  };

  const onAudioSelect = async (a) => {
    setAudioSource(a);
  };

  const handleAnswerSubmit = () => {
    if (interviewDatas[activeStep].retakeTaken === 0) {
      answerSubmit();
    } else {
      answerUpdate();
    }
  };

  const handleNext = () => {
    if (activeStep + 1 === interviewQuestions.length) {
      handleSubmit();
    } else {
      setVideoSrc(null);
      handleVideoDetails(null);
      handleFaceDetection([]);
      setSeconds(0);
      setMinutes(0);
      setShowNav(false);
      setRetakeNo(0);
      handleQuestionAnswer(interviewQuestions[activeStep].id);
      setVideoRecordStatus(recordingStatus.STAND_BY);
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <ProgressContainerBlock>
      {/* Bage with stepper  */}
      <>
        {activeStep >= 0 && hiddenAlert === false ? (
          <ProgressWithBadge>
            <ProgressItem>
              <Grid container className="badge-grid">
                {/* <Grid item xs={1}>
                  <Badge>
                    {BadgeIcon1 && activeStep == 0 && (
                      <BadgeIcon src={BadgeIcon1} alt={BadgeIcon1} />
                    )}
                    {BadgeIcon2 && activeStep == 1 && (
                      <BadgeIcon src={BadgeIcon2} alt={BadgeIcon2} />
                    )}
                    <BadgeLabel>level {Number(activeStep) + 1}</BadgeLabel>
                  </Badge>
                </Grid> */}
                <Grid item xs={9}>
                  <ProgressWithLabel>
                    <ProgressBlock>
                      {interviewQuestions?.map((item, i) => {
                        return (
                          <Step
                            colour={activeStep === i ? "#1876d1" : "#dbdbdb"}
                            role="button"
                            onClick={() => handleActiveStep(i)}
                          >
                            {i + 1}
                          </Step>
                        );
                      })}
                      {/* <MobileStepper
                        variant="dots"
                        steps={interviewQuestions.length}
                        position="static"
                        activeStep={activeStep}
                        sx={{ maxWidth: "100%", margin: "auto", flexGrow: 1 }}
                        nextButton={<div id={"nextButton"} />}
                        backButton={<div id={"prevButton"} />}
                        onClick={handleActiveStep}
                      /> */}
                    </ProgressBlock>
                    <BadgeTitle>{interviewQuestions[activeStep]?.question}</BadgeTitle>
                    {interviewQuestions[activeStep]?.subtitle && (
                      <BadgeSubtext>{interviewQuestions[activeStep]?.subtitle}</BadgeSubtext>
                    )}
                  </ProgressWithLabel>
                </Grid>
                {isMobile ? (
                  videoSrc ? (
                    <>{showVidPlayerTimerCounter()}</>
                  ) : (
                    <TimeCounter>
                      <RedDot src={RedDotImg} />
                      {`${getDigits(minutes) > 1 ? minutes : "0" + minutes}:${
                        getDigits(seconds) > 1 ? seconds : "0" + seconds
                      }`}
                      /<span>{`0${interviewQuestions[activeStep]?.timeLimit}:00`}</span>
                    </TimeCounter>
                  )
                ) : (
                  <Grid
                    item
                    xs={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-around"
                    spacing={2}
                    style={{
                      justifyContent: "end",
                      gap: "8%",
                    }}
                  >
                    {showNav && (
                      <NextAction>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Button
                              btnText={`Retake`}
                              variant="outlined"
                              onClick={handleRetake}
                              disabled={interviewQuestions[activeStep]?.retake === retakeNo || isLoading}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              onClick={handleNext}
                              variant="contained"
                              btnText={activeStep + 1 === interviewQuestions.length ? "Submit" : "Next"}
                              // disabled={interviewDatas[activeStep].isSubmitted}
                              disabled={isLoading}
                            />
                          </Grid>
                        </Grid>
                      </NextAction>
                    )}

                    {videoRecordStatus !== recordingStatus.STOP_RECORDING && !isLoading && (
                      <>
                        <TimeCounter>
                          <RedDot src={RedDotImg} />
                          {`${getDigits(minutes) > 1 ? minutes : "0" + minutes}:${
                            getDigits(seconds) > 1 ? seconds : "0" + seconds
                          }`}
                          /<span>{`0${interviewQuestions[activeStep]?.timeLimit}:00`}</span>
                        </TimeCounter>
                        <NextAction>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Select
                                id="audio-source-select"
                                value={audioSource?.target?.value ?? "default"}
                                IconComponent={ExpandMoreIcon}
                                onChange={(a) => onAudioSelect(a)}
                                style={{
                                  minWidth: "100%",
                                  maxWidth: "100%",
                                  overflow: "hidden",
                                  maxHeight: "70%",
                                }}
                              >
                                <MenuItem value="default">Default</MenuItem>
                                {audioDevices.map((d) => (
                                  <MenuItem key={d.deviceId} value={d.deviceId}>
                                    {d.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                btnText={
                                  videoRecordStatus === recordingStatus.STAND_BY ? "Start recording" : "Stop recording"
                                }
                                onClick={onRecordClicked}
                              />
                            </Grid>
                          </Grid>
                        </NextAction>
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </ProgressItem>
          </ProgressWithBadge>
        ) : null}
      </>
      {/* Content  */}
      {isLoading ? (
        <div style={{ backgroundColor: "black" }}>
          <VideoThumbnailContainer style={{ backgroundColor: "black", marginTop: "20%" }}>
            <Box>
              <Grid bgcolor={"black"} container spacing={2} alignItems="flex-center" justifyContent={"center"}>
                <Grid item xs={4}>
                  <CircularProgress />
                </Grid>
              </Grid>
            </Box>
          </VideoThumbnailContainer>
        </div>
      ) : (
        <>
          {activeStep >= 0 ? (
            <TabContentContainer>
              <InterviewContainer className="video mirror">
                {!videoSrc ? (
                  <>
                    <InterviewWindow
                      selectedAudioSource={audioSource}
                      recordStatus={videoRecordStatus}
                      handleLoading={handleLoading}
                      isLoading={isLoading}
                      handleVideoDetails={handleVideoDetails}
                      handleRecordStart={handleRecordStart}
                      handleRecordStop={handleRecordStop}
                      setVideoSrc={setVideoSrc}
                      index={activeStep}
                      title={interviewQuestions[activeStep]?.question}
                      // isVideoOn={
                      //   interviewQuestions[activeStep]?.type ===
                      //     questionType.NORMAL ||
                      //   interviewQuestions[activeStep]?.type ===
                      //     questionType.HIDDEN_QUESTION
                      // }
                      timeLimit={interviewQuestions[activeStep]?.timeLimit}
                      isHiddenQuestion={interviewQuestions[activeStep]?.type === questionType.HIDDEN_QUESTION}
                      isTimerEnded={timerEnded}
                      ref={videoRef}
                      handleVideoLink={handleVideoLink}
                      handleVideoData={handleVideoData}
                    />
                    {/* Record, Play, Retake handlers  */}
                    {/* <InterviewActions>
                      {item.type === questionType.Camera ? (
                        <IconButton onClick={handleCapture}>
                          <CameraAltIcon className="action-icons" />
                        </IconButton>
                      ) : (
                        <IAactionsflex>
                          {recordStatus ? (
                            <IconButton onClick={handleRecordStop}>
                              <StopCircleIcon className="action-icons" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                handleRecordStart(item.timeLimit, item.retake);
                                handleQuestionAnswer(item.id);
                              }}
                            >
                              <RadioButtonCheckedIcon className="action-icons" />
                            </IconButton>
                          )}
                        </IAactionsflex>
                      )}
                    </InterviewActions> */}
                  </>
                ) : (
                  <div style={{ backgroundColor: "black" }}>
                    {videoDetails?.assets?.thumbnail && videoDetails.assets.mp4 ? (
                      <VideoThumbnailContainer style={{ backgroundColor: "black", marginTop: "20%" }}>
                        <Box>
                          <VideoThumbnail
                            text={interviewQuestions[activeStep]?.question}
                            vidSrc={videoSrc}
                            thumbnail={videoDetails.assets.thumbnail}
                          />
                        </Box>
                      </VideoThumbnailContainer>
                    ) : (
                      <CircularProgress />
                    )}
                    <CodeVideoBlock progress={Math.ceil(playerState.progress)} className="console">
                      {isMobile ? (
                        <>
                          <ActionsContainer isVideo>
                            {true && (
                              <IconWrapperContainer>
                                <IconWrapper isDisabled={retakeAvailable === retakeNo}>
                                  <IconButton
                                    disabled={retakeAvailable === retakeNo}
                                    onClick={retakeAvailable !== retakeNo ? handleRetake : null}
                                  >
                                    <Refresh className="action-icon" />
                                  </IconButton>
                                </IconWrapper>
                                <p>{`${retakeNo}/${interviewQuestions[activeStep].retake}`}</p>
                              </IconWrapperContainer>
                            )}
                            <IconWrapperContainer>
                              <IconWrapper>
                                <IconButton
                                  onClick={() => {
                                    togglePlay();
                                  }}
                                  // disabled={loadingVideo}
                                >
                                  {!playerState.isPlaying ? (
                                    <PlayArrow
                                      style={{
                                        rotate: "none",
                                      }}
                                      className="action-icon"
                                    />
                                  ) : (
                                    <Pause
                                      style={{
                                        rotate: "none",
                                      }}
                                      className="action-icon"
                                    />
                                  )}
                                </IconButton>
                              </IconWrapper>
                              <p>{true ? "Pause" : "Play"}</p>
                            </IconWrapperContainer>
                            {true && (
                              <IconWrapperContainer>
                                <IconWrapper>
                                  <IconButton>
                                    <Check
                                      style={{
                                        rotate: "none",
                                      }}
                                      className="action-icon"
                                    />
                                  </IconButton>
                                </IconWrapper>
                                <p>{"Submit"}</p>
                              </IconWrapperContainer>
                            )}
                          </ActionsContainer>
                        </>
                      ) : null}
                    </CodeVideoBlock>
                  </div>
                )}
              </InterviewContainer>
            </TabContentContainer>
          ) : null}
        </>
      )}
      {open && (
        <CustomCountdown open={open} hiddenQuestionTimer={hiddenQuestionTimer} timerEndTasks={handleCountDownEnd} />
      )}
      {/* First Alert  */}
      <AlertBox
        open={capture}
        icon={<CheckCircleIcon className="alert-icon" />}
        alertText="Image Captured Successfully"
        // onClick={handleNext}
      />
      {/* hidden question alert */}
      <AlertBox
        open={hiddenAlert}
        icon={<InfoIcon className="alert-icon" />}
        alertText="Next Question will appear after you click “Proceed”. You will have 30 seconds to prepare before the recording automatically starts."
        onClick={closeHiddenAlert}
      />
    </ProgressContainerBlock>
  );
};

export default ProgressStepper;

import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { errorMessages } from "config/constants";
import { PageHeader, Button, Input, Title } from "components";
import { Card } from "components";
import { CardBlock } from "assets/styles/main.styles";
import { CardContainer } from "components/Card/card.styles.";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  applicationDailyCount,
  applicationMonthlyCount,
} from "config/api.service";
const Graphs = () => {
  const [loading, setLoading] = useState(true);
  const [applicationsCount, setApplicationsCount] = useState([]);
  const [videoCount, setVideoCount] = useState("");

  const generateMonthLabels = () => {
    const monthLabels = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    for (let i = 1; i < 13; i++) {
      const month = currentDate.getMonth() + i - 12;
      const year = month < 0 ? currentYear - 1 : currentYear;
      const date = new Date(year, month, 1);
      const formattedMonth = new Intl.DateTimeFormat("en-US", {
        month: "short",
      }).format(date);
      monthLabels.push(`${formattedMonth} ${year.toString().slice(-2)}`);
    }
    return monthLabels;
  };
// const generateMonthLabels = (date) => {
//   const monthLabels = [];
//   date = new Date(date);
//   for (let i = 11; i >= 0; i--) {
//     const month = (date.getMonth() - i + 12) % 12;
//     const year = date.getFullYear() - Math.floor((date.getMonth() - i + 1) / 12);
//     const formattedMonth = new Intl.DateTimeFormat("en-US", {
//       month: "short",
//     }).format(new Date(year, month));
//     monthLabels.push(`${formattedMonth} ${year.toString().slice(-2)}`);
//   }

//   return monthLabels.reverse();
// };

  const generateDaysLabels = () => {
    let dayLabels = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date(today);
      date.setDate(date.getDate() - i);
      const weekday = new Intl.DateTimeFormat("en-US", {
        weekday: "short",
      }).format(date);
      dayLabels.push(`${date.getDate()} ${weekday}`);
    }
    return dayLabels;
  };

  const today = new Date();
  const monthLabels = generateMonthLabels(new Date() - 365 * 24 * 60 * 60 * 1000);
  const dayLabels = generateDaysLabels();


  useEffect(() => {
    (async () => {
      setLoading(true);
      await applicationDailyCount(setApplicationsCountData, () =>
        console.log("Failed to fetch job details")
      );
      await applicationMonthlyCount(setVideoCountData, () =>
        console.log("Failed to fetch job details")
      );
      setLoading(false);
    })();
  }, []);

  const setApplicationsCountData = (data) => {
    setApplicationsCount(data);
  };
  const setVideoCountData = (data) => {
    setVideoCount(data);
  };

  return (
    <CardBlock>
      <Grid container spacing={2} alignItems="center" marginTop="10px">
        <Grid item xs>
          <CardContainer num={2}>
            <Title level="h4" text="Last 7 days Activities" />
            {applicationsCount?.totalCandidatesByDay &&
              applicationsCount?.totalSubmittedByDay && (
                <LineChart
                  xAxis={[{ scaleType: "point", data: dayLabels }]}
                  series={[
                    {
                      data: applicationsCount?.totalCandidatesByDay,
                      label: "Applications",
                      color: "#540893",
                    },
                    {
                      data: applicationsCount?.totalSubmittedByDay,
                      label: "Video Interviews",
                      color: "#04D200",
                    },
                  ]}
                  height={300}
                />
              )}
          </CardContainer>
        </Grid>

        <Grid item xs>
          <CardContainer num={100}>
            <Title level="h4" text="Monthwise Candidate Count" />
            {videoCount?.totalCandidatesByMonth &&
              videoCount?.totalSubmittedByMonth && (
                <LineChart
                  xAxis={[{ scaleType: "point", data: monthLabels }]}
                  series={[
                    {
                      data: videoCount?.totalCandidatesByMonth,
                      label: "Applications",
                      color: "#540893",
                    },
                    {
                      data: videoCount?.totalSubmittedByMonth,
                      label: "Video Interviews",
                      color: "#04D200",
                    },
                  ]}
                  height={300}
                />
              )}
          </CardContainer>
        </Grid>
      </Grid>
    </CardBlock>
  );
};

export default Graphs;

import { SelectWrapper } from "assets/styles/main.styles";
import { getCategory } from "config/api.service";
import { useState, useMemo } from "react";

const CategoryFilter = ({ filterValue, setFilterValue }) => {
  const [categoriesData, setCategoriesData] = useState([]);

  useMemo(async function getCategories() {
    getCategory(function (result) {
      setCategoriesData(result);
    });
  }, []);

  return (
    <SelectWrapper style={{ marginLeft: "10px" }}>
      <select
        onChange={(e) => setFilterValue(e.target.value)}
        name="categoryId"
        value={filterValue}
      >
        <option value="">
          <em>Filter Category</em>
        </option>
        {categoriesData.map((d) => (
          <option key={d.id} value={d.name}>
            {d.name}
          </option>
        ))}
      </select>
    </SelectWrapper>
  );
};
export default CategoryFilter;

import { MediaStreamComposer, MouseTool, StreamDetails } from "@api.video/media-stream-composer";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import StartRecordingIcon from "@mui/icons-material/FiberManualRecord";
import GestureIcon from "@mui/icons-material/Gesture";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import StopRoundedIcon from "@mui/icons-material/StopRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  FormLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import PopupState from "material-ui-popup-state";
import { bindMenu, bindTrigger } from "material-ui-popup-state/hooks";
// import type { NextPage } from 'next'
// import Head from 'next/head'
// import NextImage from 'next/image'
// import { useRouter } from 'next/router'
// import {useRouter} from 'react-router-dom'
import React, { useEffect, useRef, useState } from "react";
import { CirclePicker } from "react-color";
import styles from "./components/styles/Home.module.css";
import StreamDialog, { StreamFormValues } from "./components/StreamDialog";
import UploadSettingsDialog, { UploadSettings } from "./components/UploadSettingsDialog";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { ApiVideo } from "config/api.service";
import Header from "components/CameraWindow/Header";
import { recordingStatus } from "config/constants";
//  import ApiVideoClient from '@api.video/nodejs-client';
const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#FA5B30",
      dark: "#FF6B40",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const WIDTH = 1280; //3200;
const HEIGHT = 720; //2000;

export const composer = (() => {
  const mediaStreamComposer = new MediaStreamComposer({
    resolution: {
      width: WIDTH,
      height: HEIGHT,
    },
  });
  mediaStreamComposer.setMouseTool("move-resize");
  mediaStreamComposer.setDrawingSettings({
    color: "#ff0000",
    lineWidth: 6,
    autoEraseDelay: 2,
  });
  mediaStreamComposer.addEventListener("recordingStopped", (e) => {
    if (e.data.file) {
      const a = document.createElement("a");
      // console.log(e.data.file);
      let extension = "mp4";
      try {
        extension = e.data.file.type.split("/")[1].split(";")[0];
      } catch (e) {
        console.error(e);
      }
      a.href = URL.createObjectURL(e.data.file);
      a.download = `video.${extension}`;
      a.click();
    }
  });
  return mediaStreamComposer;
})();

const VideoCam2 = ({
  handleVideoDetails,
  handleRecordStart,
  handleRecordStop,
  timeLimit,
  isHiddenQuestion,
  isTimerEnded,
  setVideoSrc,
  handleVideoData,
  handleLoading,
  isLoading,
  selectedAudioSource,
  recordStatus,
}) => {
  const [addStreamDialogIsOpen, setAddStreamDialogOpen] = useState(false);
  const [uploadSettingsDialogIsOpen, setUploadSettingsDialogOpen] = useState(false);

  const streamsRef = useRef([]);
  const [streams, setStreams] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [playerUrl, setPlayerUrl] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const [uploadToken, setUploadToken] = useState(null);

  const [firstStreamAddedAlertOpen, setFirstStreamAddedAlertOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [audioSource, setAudioSource] = useState(audioDevices[0]?.deviceId ?? "none");
  const [audioStreamId, setAudioStreamId] = useState("");
  const [uploadSettings, setUploadSettings] = useState({
    videoName: "My record.a.video composition",
    downloadVideoFile: false,
  });
  const [videoStatus, setVideoStatus] = useState("");
  const [isVideoRecorded, setIsVideoRecorded] = useState(false);

  const handleStreamChange = (newStreams) => {
    streamsRef.current = newStreams;
  };

  const updateStreams = (streamData) => {
    let newStreams = [...streamsRef.current];
    newStreams = [streamData, ...newStreams];
    handleStreamChange(newStreams); // Update the ref
  };

  useEffect(() => {
    (async () => {
      const deviceIds = JSON.parse(localStorage.getItem("deviceIds"));

      await navigator?.mediaDevices
        ?.getUserMedia({ audio: true, video: deviceIds ? deviceIds.videoDeviceId : true })
        .then((stream) => {
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            setVideoDevices(devices.filter((d) => d.kind === "videoinput"));
            setAudioDevices(devices.filter((d) => d.kind === "audioinput"));
            stream.getTracks().forEach((x) => x.stop());
          });
        })
        .catch((e) => console.log(e));
    })();
  }, []);

  // const router = useRouter()

  useEffect(() => {
    addStream({
      type: "webcam",
      imageUrl: "",
      position: "contain",
      mask: "none",
      width: "",
      height: "25%",
      top: "0",
      left: "0",
      draggable: false,
      resizable: false,
      deviceId: "",
      opacity: "100%",
    });
    if (streamsRef.current.length === 0 && document.querySelector("canvas")) {
      const canvasContainer = document.getElementById("canvas-container");
      const canvas = document.querySelector("canvas");
      if (canvasContainer && canvas) {
        canvasContainer.removeChild(canvas);
      }
    }
  }, [streamsRef.current]);

  // handle the record duration timer
  useEffect(() => {
    if (isRecording) {
      setRecordingDuration(0);
      const interval = setInterval(() => {
        setRecordingDuration((recordingDuration) => recordingDuration + 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isRecording]);

  async function addStream(opts) {
    const deviceIds = JSON.parse(localStorage.getItem("deviceIds"));

    setAddStreamDialogOpen(false);
    let stream = await navigator?.mediaDevices?.getUserMedia({
      audio: true,
      video: { deviceId: deviceIds ? deviceIds.videoDeviceId : true },
    });
    setTimeout(() => {
      composer.addStream(stream, {
        position: opts.position,
        // width: opts.width
        //   ? (parseInt(opts.width, 10) * WIDTH) / 100
        //   : undefined,
        // height: opts.height
        //   ? (parseInt(opts.height, 10) * HEIGHT) / 100
        //   : undefined,
        // x: opts.left ? (parseInt(opts.left, 10) * WIDTH) / 100 : undefined,
        // y: opts.top ? (parseInt(opts.top, 10) * HEIGHT) / 100 : undefined,
        resizable: opts.resizable,
        draggable: opts.draggable,
        opacity: opts.opacity,
        mask: opts.mask,
        mute: true,
        name: `${opts.type}`,
      });
      const canvasContainer = document.getElementById("canvas-container");
      if (canvasContainer) {
        composer?.appendCanvasTo("#canvas-container");
        const canvas = composer.getCanvas();
        if (canvas) {
          canvas.style.width = "100%";
          canvas.style.height = "100%";
          canvas.style.boxSizing = "unset";
        }
        updateStreams(composer.getStreams()[composer.getStreams().length - 1]);
      }
    }, 100);
  }

  useEffect(() => {
    if (timeLimit * 60 === recordingDuration && isRecording) {
      onRecordClicked();
    }
  }, [recordingDuration]);

  useEffect(() => {
    if (streamsRef.current < 10) {
      streamsRef.current += 1;
      const timer = setTimeout(() => setStreams(streamsRef.current), 5000);
      return () => clearTimeout(timer);
    }
  }, [streams]);

  function removeStream() {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((mediaStream) => {
      const stream = mediaStream;
      stream.getTracks().forEach((item) => item.stop());
    });
  }

  useEffect(() => {
    return composer.destroy();
  }, []);

  const onRecordClicked = async () => {
    console.log("Streams", composer.getStreams());
    if (!isRecording) {
      var res = await ApiVideo();
      console.log("vod", res, res.data.data.token);
      await setUploadToken(res.data.data.token);
      let s = res.data.data.token;
      console.log("tok", uploadToken, s);
      composer.startRecording({
        uploadToken: res.data.data.token,
        videoName: uploadSettings.videoName,
        generateFileOnStop: uploadSettings.downloadVideoFile,
        mimeType: uploadSettings.mimeType,
        origin: {
          application: {
            name: "record-a-video",
            version: "1.0.0",
          },
        },
      });
      setVideoStatus("recording");
      handleRecordStart();
      composer.addEventListener("error", (e) => {
        setErrorMessage(e.data.title || "An unknown error occurred");
        setIsRecording(false);
      });
      composer.addEventListener("videoPlayable", (e) => {
        setVideoStatus("playable");
        setPlayerUrl(e.data.assets.player);
      });
      setPlayerUrl(null);
      setIsRecording(true);
    } else {
      composer.stopRecording().then(async (e) => {
        handleLoading(true);
        await composer.addEventListener("videoPlayable", (ev) => {
          setVideoStatus("playable");
          setPlayerUrl(ev.data.assets.player);
          setVideoSrc(e.assets.mp4);
          handleLoading(false);
        });
        handleVideoData(e);
        setVideoStatus("encoding");
        handleVideoDetails(e);
      });
      setIsRecording(false);
      handleRecordStop();
      setIsVideoRecorded(true);
      removeStream();
    }
  };

  const handleAudio = async (audioSource) => {
    if (audioStreamId) {
      composer.removeAudioSource(audioStreamId);
    }
    const selectedAudioSource = audioSource?.target?.value;
    let newAudioStreamId;
    if (selectedAudioSource !== "none") {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: selectedAudioSource },
      });
      newAudioStreamId = await composer.addAudioSource(stream);
    }
    setAudioStreamId(newAudioStreamId);
    setAudioSource(selectedAudioSource);
  };

  useEffect(() => {
    handleAudio(selectedAudioSource);
  }, [selectedAudioSource]);

  useEffect(() => {
    if (recordStatus === recordingStatus.START_RECORDING || recordStatus === recordingStatus.STOP_RECORDING) {
      onRecordClicked();
    }
  }, [recordStatus]);

  let stepNum = 0;
  if (videoStatus === "encoding") {
    stepNum = 1;
  }
  if (videoStatus === "playable") {
    stepNum = 2;
  }

  return (
    <>
      <div className={styles.container}>
        <ThemeProvider theme={theme}>
          <section className={styles.previewPaper}>
            <div
              id="canvas-container"
              className={styles.canvasContainer}
              style={{ aspectRatio: `${WIDTH}/${HEIGHT}` }}
            ></div>
          </section>

          <Snackbar
            open={firstStreamAddedAlertOpen}
            onClose={() => setFirstStreamAddedAlertOpen(false)}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={() => setFirstStreamAddedAlertOpen(false)} severity="success" sx={{ width: "100%" }}>
              You have added your first stream. You can now add more to create your composition!
            </Alert>
          </Snackbar>
          <Snackbar
            open={!!errorMessage}
            onClose={() => setErrorMessage(undefined)}
            autoHideDuration={4000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert onClose={() => setErrorMessage(undefined)} severity="error" sx={{ width: "100%" }}>
              {errorMessage}
            </Alert>
          </Snackbar>

          <StreamDialog
            open={addStreamDialogIsOpen}
            devices={videoDevices}
            onClose={() => setAddStreamDialogOpen(false)}
            onSubmit={(values) => {
              addStream({
                type: "webcam",
                imageUrl: "",
                position: "contain",
                mask: "none",
                width: "",
                height: "25%",
                top: "0",
                left: "0",
                draggable: false,
                resizable: false,
                deviceId: "",
                opacity: "100%",
              });
              // addStream(values);
              setAddStreamDialogOpen(false);
            }}
          />

          <UploadSettingsDialog
            open={uploadSettingsDialogIsOpen}
            onClose={() => setUploadSettingsDialogOpen(false)}
            uploadSettings={uploadSettings}
            onSubmit={(values) => {
              setUploadSettings(values);
              setUploadSettingsDialogOpen(false);
            }}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default VideoCam2;

import React, { useState, useEffect } from "react";
// import ProgressBar from "@ramonak/react-progress-bar";

import { CardMedia, Grid } from "@mui/material";
import { Modal, Title, Button } from "components";
import {
  ReviewAnswerCardContainer,
  VideoContainer,
  ReviewCardContainer,
  ReviewModalHeader,
  PlayButton,
  ThumbnailContainer,
} from "assets/styles/main.styles";
import Play from "assets/images/play.jpg";
import VideoThumbnailPlaceholder from "assets/images/video-thumbnail.svg";
import { useInterviewDataContext } from "contexts/interviewData";
import { getCandidateInterviewLoadDetails } from "config/api.service";
import { submitInterview } from "config/api.service";

const VideoPlayer = ({
  details,
  serialNumber,
  handleRetake,
  getLatestVideo,
}) => {
  const { interviewData, setAnswerData, answerData } =
    useInterviewDataContext();
  const [isOpen, setIsOpen] = useState(false);
  const [thumbnailData, setThumbnailData] = useState(
    details?.vidMeta?.assets?.thumbnail
  );
  const [thumbnailIsPresent, setThumbnailIsPresent] = useState(false);

  // const handleImageError = () => {
  //   //to reload the image
  //   setThumbnailData(`${thumbnailData}?v=${Date.now()}`);
  // };

  useEffect(() => {
    setThumbnailData(details?.vidMeta?.assets?.thumbnail);
    isImagePresent(details?.vidMeta?.assets?.thumbnail);
  }, [details]);

  const isImagePresent = (link) => {
    var image = new Image();
    image.src = link;
    if (image.complete) {
      setThumbnailIsPresent(true);
    } else {
      image.onload = () => {
        setThumbnailIsPresent(true);
      };

      image.onerror = () => {
        setThumbnailIsPresent(false);
      };
    }
  };

  return (
    <>
      <ThumbnailContainer isClickable={true}>
        <VideoContainer>
          {thumbnailIsPresent ? (
            <img
              className="thumbnail"
              src={thumbnailData}
              alt="thumbnail"
              align="center"
              style={{ width: "100%" }}
            />
          ) : (
            <img
              className="thumbnail"
              src={VideoThumbnailPlaceholder}
              alt="thumbnail"
              align="center"
              style={{ width: "100%" }}
            />
          )}
          <PlayButton>
            <img src={Play} alt="play" onClick={() => setIsOpen(true)} />
          </PlayButton>
        </VideoContainer>
        <div style={{ padding: "1rem" }}>
          <Title
            level="h6"
            text={`${serialNumber}. ${
              details?.question ??
              interviewData?.jobrole?.interviewQuestions[serialNumber - 1]
                ?.question
            }`}
          />
          <Grid
            container
            sx={{ margin: "0.5rem 0 0 0" }}
            justifyContent="space-between"
          >
            {/* <Grid item xs={2}>
              {!thumbnailIsPresent && (
                <>
                  <Button
                    btnText="Reload"
                    variant="text"
                    style={{ fontWeight: "bold" }}
                    onClick={getLatestVideo}
                    loading={false}
                  />
                </>
              )}
            </Grid> */}
            <Grid item xs={2}>
              {details?.retake >= 1 && (
                <>
                  <Button
                    btnText="Retake"
                    variant="outlined"
                    onClick={handleRetake}
                    loading={false}
                    style={{
                      maxWidth: "90px",
                      alignSelf: "flex-end",
                    }}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </ThumbnailContainer>
      {isOpen && (
        <Modal
          size="md"
          open={isOpen}
          close={() => setIsOpen(false)}
          title={
            <ReviewModalHeader>
              {details?.question ??
                interviewData?.jobrole?.interviewQuestions[serialNumber - 1]
                  ?.question}
            </ReviewModalHeader>
          }
          children={
            <VideoCard
              isModal={true}
              vidSrc={details?.vidMeta?.assets?.mp4}
              handleClose={() => setIsOpen(false)}
            />
          }
        />
      )}
    </>
  );
};

export default VideoPlayer;

function VideoCard({ isModal, setIsOpen, vidSrc }) {
  return (
    <ReviewCardContainer>
      {vidSrc && (
        <VideoContainer>
          <CardMedia component="video" controls src={vidSrc} autoPlay />
        </VideoContainer>
      )}
    </ReviewCardContainer>
  );
}

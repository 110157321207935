import { createContext, useContext, useState } from "react";

const InterviewDataContext = createContext();

export const InterviewDataProvider = ({ children }) => {
  const [interviewData, setInterviewData] = useState({});
  const [answerData, setAnswerData] = useState({});

  return (
    <InterviewDataContext.Provider
      value={{
        interviewData,
        setInterviewData,
        answerData,
        setAnswerData,
      }}
    >
      {children}
    </InterviewDataContext.Provider>
  );
};

export const useInterviewDataContext = () => useContext(InterviewDataContext);

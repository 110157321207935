import React from "react";
import { CandidateInterviewContainer } from "assets/styles/main.styles";
import VideoInterview from "../VideoInterview";
import { useInterviewDataContext } from "contexts/interviewData";
import { useLocation } from "react-router-dom";

const RetakeInterview = () => {
  const { state } = useLocation();
  const { interviewData } = useInterviewDataContext();
  const { retakeQuestionId } = state;
  // let inviteId = localStorage.getItem("uniqueInterviewId");
  // const interviewData = JSON.parse(localStorage.getItem("interviewData"));

  // React.useEffect(() => {
  //   if (inviteId != null) {
  //     (async () =>
  //       await getCandidateInterviewLoadDetails(inviteId, (res) => {
  //         setAnswerData(res);
  //       }))();
  //   }
  // }, [inviteId]);

  if (interviewData == null)
    return <h2>Please go back and attend the interview from start</h2>;

  return (
    <CandidateInterviewContainer>
      <VideoInterview retakeQuestionId={retakeQuestionId} />
    </CandidateInterviewContainer>
  );
};

export default RetakeInterview;

import Chip from "@mui/material/Chip";
import React from "react";
import { TagContainer } from "./tag.styles";

const Tag = ({ variant = "outlined", label, type, icon, style, ...rest }) => {
  return (
    <TagContainer type={type} style={style}>
      <Chip avatar={icon} label={label} variant={variant} {...rest} />
    </TagContainer>
  );
};

export default Tag;

import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import InterviewIllustration from "assets/images/interviewIllustration.svg";
import { Button, Title, Header } from "components";
import {
  SectionContainer,
  Box,
  ContentCenter,
  BlockMargin,
  CheckList,
  CheckListItem,
  FlexColumn,
  PaddingBlock,
  AppriciationButtonWrapper,
} from "assets/styles/main.styles";
import LeftColumnImg from "assets/images/image-371.svg";
import { useLocation, useNavigate } from "react-router-dom";
import PoweredComponent from "components/PoweredComponent/PoweredComponent";
import EmailImage from "assets/images/email_image.png";
import { appUrl } from "config/constants";
import useWindowSize from "hooks/useWindowSize";

const Appreciation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;

  const [inviteId, setInviteId] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (location.state) {
      const { email, inviteId } = location.state;
      setInviteId(inviteId);
      setEmail(email);
    }
  }, [location]);

  const generatedLink = () => {
    return `/invite?id=${inviteId}&email=${email}`;
  };

  return (
    <>
      <Header />
      <BlockMargin size={100}></BlockMargin>

      {!isMobile && <BlockMargin size={150}></BlockMargin>}
      <Container maxWidth="md" style={{ paddingBottom: "40px" }}>
        <Box>
          <PaddingBlock padding={20}>
            <FlexColumn gap={20}>
              <ContentCenter>
                <BlockMargin size={1}>
                  <h1 style={{ fontSize: "30px", marginBottom: "20px", lineHeight: "40px" }}>Application Success</h1>
                  <Title level="h4" text={<>You are invited for a video interview.</>} />
                </BlockMargin>
              </ContentCenter>
              <Grid container spacing={10} alignItems="center" className="mb-2">
                <Grid item xs={12} sm={6} textAlign="center">
                  {/* <img src={LeftColumnImg} alt={LeftColumnImg} /> */}
                  <img src={InterviewIllustration} alt="Interview" height={250} style={{ width: "100%" }} />
                  <div
                    style={{
                      width: "200px",
                      margin: "20px auto 0",
                    }}
                  >
                    <PoweredComponent />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} alignSelf={"center"}>
                  <Title level="h6" text="Video Interview Instructions:" />
                  <BlockMargin size={10} />
                  <CheckList>
                    <CheckListItem>
                      Please be aware that you must complete the interview in one sitting because you cannot resume
                      after leaving.
                    </CheckListItem>
                    <CheckListItem>
                      You can use your desktop or mobile phone to take part in the interview.
                    </CheckListItem>
                    <CheckListItem>
                      Find a quiet and well-lit space for the interview to ensure optimal conditions.
                    </CheckListItem>
                    <CheckListItem>
                      In case you are unable to attend the interview now, you can attend the interview by clicking the
                      link in your email.
                    </CheckListItem>
                  </CheckList>

                  <AppriciationButtonWrapper style={{ marginBottom: "20px" }}>
                    <Button
                      btnText="Start my Video Interview"
                      variant="contained"
                      onClick={() =>
                        navigate(generatedLink(), {
                          replace: true,
                        })
                      }
                    />
                  </AppriciationButtonWrapper>
                  <BlockMargin size={10} />
                  <p style={{ fontSize: "12px" }}>
                    If you have any technical issues or questions, contact our support team at{" "}
                    <a href="mailto:info@kapiree.com">info@kapiree.com</a>. We look forward to learning more about you
                    through this video interview. Good luck
                  </p>
                </Grid>
              </Grid>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img
                  style={{ width: "1.5rem" }}
                  src="data:image/svg+xml; charset=utf-8;utf8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgaWQ9IkluZm8iPjxnIGZpbGw9IiNmZmE3MjYiIGNsYXNzPSJjb2xvcjAwMDAwMCBzdmdTaGFwZSI+PGcgZmlsbD0iI2ZmYTcyNiIgY2xhc3M9ImNvbG9yMDAwMDAwIHN2Z1NoYXBlIj48cGF0aCBkPSJNMTIgMmExMCAxMCAwIDEgMCAxMCAxMEExMCAxMCAwIDAgMCAxMiAyem0wIDE4YTggOCAwIDEgMSA4LTggOCA4IDAgMCAxLTggOHoiIGZpbGw9IiNmZmE3MjYiIGNsYXNzPSJjb2xvcjAwMDAwMCBzdmdTaGFwZSI+PC9wYXRoPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iMSIgZmlsbD0iI2ZmYTcyNiIgY2xhc3M9ImNvbG9yMDAwMDAwIHN2Z1NoYXBlIj48L2NpcmNsZT48cGF0aCBkPSJNMTIgMTBhMSAxIDAgMCAwLTEgMXY1YTEgMSAwIDAgMCAyIDB2LTVhMSAxIDAgMCAwLTEtMXoiIGZpbGw9IiNmZmE3MjYiIGNsYXNzPSJjb2xvcjAwMDAwMCBzdmdTaGFwZSI+PC9wYXRoPjwvZz48L2c+PC9zdmc+"
                  alt="info"
                />
                <p style={{ color: "#ffa726", fontSize: "15px", fontWeight: "bold" }}>
                  You can do it later by clicking the link from the registered email
                </p>
              </div>
            </FlexColumn>
          </PaddingBlock>
        </Box>
      </Container>
    </>
  );
};

export default Appreciation;

import styled, { css } from "styled-components";

export const DropdownContainer = styled.div(
  ({
    theme: {
      colors: { black, primaryBlue, red },
      fontWeight,
    },
  }) => css`
    & .MuiInputLabel-root {
      position: static;
      transform-origin: left;
      transform: translate(0, -6px) scale(0.75);
    }
    & .MuiInputLabel-root.Mui-focused {
      color: ${black};
    }
    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${primaryBlue};
    }
    & .MuiSelect-select {
      font-size: 14px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  `
);

import React from "react";

import { FooterContainer, FooterText } from "./footer.styles";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>
        Copyright {new Date().getFullYear()} All rights reserved{" "}
        <a href="https://kapiree.com/">Kapiree</a>
      </FooterText>
    </FooterContainer>
  );
};

export default Footer;

import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: 'Manrope', sans-serif;
    background: #F6F7F9 ;
  }

  .App{
    min-height: 100vh;

  }

  .MuiMenuItem-root {
      font-size: 14px !important;
  }
  
  .table-nav-link{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #4286F5;
    cursor: pointer ;
  }

  .MuiDialog-paper{
    border-radius: 12px !important ;
  }

  .MuiTooltip-tooltip{
    background-color: #4A5668 !important;
  }

  .MuiTooltip-arrow{
    color: #4A5668 !important;
  }

  .table-checkbox{
    padding: 0  !important;
  }

  .MuiCheckbox-root svg {
      fill: #2E6AE0 !important;
    }

    .alert-icon{
      font-size: 64px  !important;
      color: #2E6AE0;
    }
    
    // .MuiSnackbar-root{
    //   .MuiPaper-root{
    //     background-color: #2E6AE0 !important;
    //     color:white !important;
    //   }
    //   .MuiAlert-icon{
    //     color: white !important;
    //   }
    // }

`;

export default GlobalStyle;

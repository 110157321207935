import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
import DownloadIcon from "@mui/icons-material/Download";
import { Dialog, Button } from "components";
import { ResumeWrapper } from "assets/styles/main.styles";

const ResumePreview = ({ isResumePreviewOpen, handleResumePreview, resumePath: resumeLink }) => {
  const [resumePath, setResumePath] = useState("");
  const [resumeFileType, setResumeFileType] = useState("");

  const getResumeType = (resumeFile) => {
    if (resumeFile) {
      const resumeArr = resumeFile.split("/");
      const fileName = resumeArr[resumeArr.length - 1];
      const fileType = fileName.split(".")[1];
      return fileType;
    }
  };

  useEffect(() => {
    const type = getResumeType(resumeLink);
    setResumePath(resumeLink);
    setResumeFileType(type);
  }, [resumeLink]);
  return (
    <Dialog
      size="md"
      open={isResumePreviewOpen}
      close={handleResumePreview}
      title={"Resume preview"}
      actionWithTitle={
        <a href={resumePath} download>
          <Button
            startIcon={<DownloadIcon />}
            variant="text"
            btnText="Download Resume"
            sx={{
              "& .MuiButton-root": {
                width: "200px",
              },
            }}
          />
        </a>
      }
      children={
        <ResumeWrapper>{resumePath && <FileViewer fileType={resumeFileType} filePath={resumePath} />}</ResumeWrapper>
      }
    />
  );
};

export default ResumePreview;

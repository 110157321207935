import * as React from "react";
import { Title } from "components";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Box, JobRoleList} from "assets/styles/main.styles";

import { useNavigate } from "react-router-dom";
const JobListingSidePanel = ({ jobRoleData }) => {
	const jobRoles = jobRoleData;

	const navigate = useNavigate();
	return (
		<Box sx={{ width: 320, maxWidth: "100%" }}>
			<div style={{ padding: "0 16px " }}>
				<Title level="h4" text="Job Roles" />
			</div>
			<MenuList >
				{jobRoles.map((item, index) => (
					<MenuItem onClick={() =>
						navigate(`/job-detail`, {
							state: {
								id: item.id ?? item.roleId,
							},
						})
					}
					sx={{display: "flex", justifyContent: "space-between"}}
					>
						<JobRoleList fontWeight="400" >{item.roleName}</JobRoleList>
						<JobRoleList fontWeight ="800">
							{item.assignedCandidates}
						</JobRoleList>
					</MenuItem>
				))}
			</MenuList>
		</Box>
	);
};

export default JobListingSidePanel;

import React, { useEffect, useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Title } from "components";
import { useAlert } from "contexts/alert.context";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CachedIcon from "@mui/icons-material/Cached";
import {
  BlockMargin,
  ThumbnailContainer,
  CircularProgressContainer,
  InterviewOverviewContainer,
  RetakeButtonContainer,
} from "assets/styles/main.styles";
import VideoPlayer from "components/VideoPlayer/VideoPlayer";
import AlertBox from "components/ProgressStepper/components/AlertBox";
import { submitInterview, getTheLatestVideo } from "config/api.service";
import { useInterviewDataContext } from "contexts/interviewData";
import LoadingButton from "@mui/lab/LoadingButton";
import { removeSpaceAndToLowerCase } from "../VideoInterview/utils";
import useFullscreen from "hooks/useFullscreen";

const InterviewOverview = () => {
  const navigate = useNavigate();
  const { answerData, interviewData, setAnswerData } = useInterviewDataContext();
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [showSubmitConfirm, setShowSubmitConfirm] = useState(false);
  // const interviewData = JSON.parse(localStorage.getItem("interviewData"));
  // const { toggle } = useFullscreen();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showAlert } = useAlert();

  useEffect(() => {
    // closeFullscreen();
    // toggle();
  }, []);

  const handleSubmit = async () => {
    setShowSubmitConfirm(false);
    setIsSubmitting(true);
    const { roleId, candidateId } = interviewData;
    const data = { roleId, candidateId };
    await submitInterview(data, handleSuccess, handleError);
    setIsSubmitting(false);
  };

  const handleSuccess = () => {
    setShowSubmitAlert(true);
    // showAlert("Interview submitted successfully", "success");
    localStorage.removeItem("uniqueInterviewId");
  };

  const handleError = () => {
    showAlert("Interview submission failed", "error");
  };

  const handleRetake = (step) => {
    // handleQuestionRetake(step);
    navigate("/candidate-interview-retake", {
      state: {
        retakeQuestionId: interviewData?.jobrole?.interviewQuestions[step]?.id,
      },
      replace: true,
    });
  };

  // const fetchLatest = async () => {
  //   console.log(interviewData);
  //   const { inviteUUID } = interviewData;
  //   await getCandidateInterviewLoadDetails(inviteUUID, (response) => {
  //     const answers = response.map((item) => {
  //       return {
  //         vidMeta: JSON.parse(item.vidMeta).vidMeta,
  //         isRecorded: true,
  //         isSubmitted: true,
  //         question: JSON.parse(item.vidMeta).question,
  //         retake: JSON.parse(item.vidMeta).retake,
  //       };
  //     });
  //     setAnswerData(answers);
  //   });
  // };

  const getLatestVideo = async (index) => {
    let questionDetails = interviewData?.jobrole?.interviewQuestions[index];
    let videoTitle = `${removeSpaceAndToLowerCase(interviewData?.jobrole?.roleName)}_${removeSpaceAndToLowerCase(
      interviewData?.CandidateList?.name
    )}_${questionDetails?.id}`;
    const data = {
      candidateId: interviewData.candidateId,
      roleId: interviewData.roleId,
      questionDetails: {
        id: questionDetails?.id,
        question: questionDetails?.question,
        retake: questionDetails?.retake,
      },
      videoTitle,
    };
    let res = await getTheLatestVideo(data);
    const currentAnswerDatas = [...answerData];
    const indexOfCurrentQuestion = currentAnswerDatas.findIndex((item) => item.qId === res?.data?.qId);
    currentAnswerDatas[indexOfCurrentQuestion] = {
      ...currentAnswerDatas[indexOfCurrentQuestion],
      vidMeta: res.data.vidMeta,
    };
    setAnswerData(currentAnswerDatas);
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      {answerData?.length <= 0 ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        <InterviewOverviewContainer>
          <BlockMargin size={30}>
            <Grid container spacing={1} alignItems="flex-start">
              <Grid item xs={10}>
                <Title level="h2" text="All Recorded Videos" />
              </Grid>
              {/* <Grid item xs={1}>
                <Button
                  startIcon={<CachedIcon />}
                  btnText="Reload Videos"
                  variant="outlined"
                  onClick={fetchLatest}
                />
              </Grid> */}
              <Grid item lg={2} sm={12} xs={12}>
                {isSubmitting ? (
                  <LoadingButton
                    disabled
                    variant="contained"
                    loading
                    loadingPosition="end"
                    endIcon={<></>}
                    sx={{
                      width: "140px",
                      "&MuiLoadingButton-loadingIndicator": {
                        marginLeft: "-10px",
                      },
                    }}
                  >
                    Submitting
                  </LoadingButton>
                ) : (
                  <Button
                    startIcon={<CheckOutlinedIcon />}
                    btnText="Submit"
                    variant="contained"
                    onClick={() => setShowSubmitConfirm(true)}
                  />
                )}
              </Grid>
            </Grid>
          </BlockMargin>
          <BlockMargin size={30}>
            <Grid container spacing={8} alignItems="flex-start">
              {answerData?.map((item, i) => {
                return (
                  <Grid item lg={3} sm={12} xs={12} key={i}>
                    {/* <ThumbnailContainer>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: vidMeta?.assets?.iframe,
                        }}
                      />
                    </ThumbnailContainer> */}
                    <VideoPlayer
                      // text={
                      //   item?.question ??
                      //   interviewData?.jobrole?.interviewQuestions[i]?.question
                      // }
                      // thumbnail={vidMeta?.assets?.thumbnail}
                      // vidSrc={vidMeta?.assets?.mp4}
                      details={item}
                      serialNumber={i + 1}
                      handleRetake={() => handleRetake(i)}
                      getLatestVideo={() => getLatestVideo(i)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </BlockMargin>
          {/* <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={1}>
              <Button
                startIcon={<CheckOutlinedIcon />}
                btnText="Submit"
                variant="contained"
                onClick={() => setShowSubmitConfirm(true)}
              />
            </Grid>
          </Grid> */}
        </InterviewOverviewContainer>
      )}
      <AlertBox
        isFinalStep
        open={showSubmitAlert}
        icon={<CheckCircleIcon className="alert-icon" />}
        alertText="Our team will review your interview submission and provide the feedbacks at the earliest."
        onClick={() => navigate("/candidate-completed-interview")}
      />
      <AlertBox
        isFinalStep
        open={showSubmitConfirm}
        icon={<CheckCircleIcon className="alert-icon" />}
        alertText="Are you sure to submit ?"
        onClick={() => handleSubmit()}
      />
    </div>
  );
};

export default InterviewOverview;

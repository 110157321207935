import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Tag } from "components";

import { FilterChips, TooltipTitle, TooltipText } from "../table.styles";

const Filter = ({ filterValue, setFilterValue }) => {
  return (
    <div>
      <FilterChips>
        <Tag
          className={filterValue === "" ? "active-all" : ""}
          label="All"
          type="violet"
          onClick={() => setFilterValue("")}
        />

        <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle> Requested interview</TooltipTitle>
              <TooltipText>
                This displays candidates who have been invited to an interview.
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              className={`breakpoint ${
                filterValue === "Requested" ? "active-requested" : ""
              }`}
              label="Requested"
              type="Requested"
              onClick={() => setFilterValue("Requested")}
            />
          </span>
        </Tooltip>
        <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle> Invite Expired</TooltipTitle>
              <TooltipText>
                This displays candidates whose invite is expired
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              type={filterValue === "Invite Expired" ? "purple" : "purple"}
              label="Invite Expired"
              onClick={() => setFilterValue("Invite Expired")}
            />
          </span>
        </Tooltip>

        {/* <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle> Fit</TooltipTitle>
              <TooltipText>
                This displays candidates who are fit for the role.
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              className={filterValue === "Fit" ? "active-fit" : ""}
              label="Fit"
              type="Fit"
              onClick={() => setFilterValue("Fit")}
            />
          </span>
        </Tooltip> */}

        {/* <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle> Partially Attempted</TooltipTitle>
              <TooltipText>
                This displays candidates who partially attempted interview.
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              className={filterValue === "Partially Attempted" ? "active-attempted" : ""}
              label="Partially Attempted"
              type="Attempted"
              onClick={() => setFilterValue("Attempted")}
            />
          </span>
        </Tooltip> */}

        {/* <Tooltip
          placement="top"
          title={
            <>
              <TooltipTitle> Not Fit</TooltipTitle>
              <TooltipText>
                This displays candidates who are not fit for the role.
              </TooltipText>
            </>
          }
          arrow
        >
          <span>
            <Tag
              className={filterValue === "Rejected" ? "active-NotFit" : ""}
              label="Rejected"
              type="Not Fit"
              onClick={() => setFilterValue("Rejected")}
            />
          </span>
        </Tooltip> */}
      </FilterChips>
    </div>
  );
};

export default Filter;

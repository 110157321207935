import styled from "styled-components";

export const TagContainer = styled.div`
  & .MuiChip-root {
    background-color: #f0f0f0;
    border-radius: 4px;
    border: 1px solid transparent;
    background: ${({ type }) =>
      type === "blue" || type === "New"
        ? "#DDF3FF"
        : type === "primaryBlue" || type === "Fit"
        ? "#ECFDF5"
        : type === "purple"
        ? "#E1DDFF"
        : type === "violet"
        ? "rgba(119, 37, 116, 0.1)"
        : type === "pink" || type === "Requested"
        ? "rgba(197, 53, 247, 0.1)"
        : type === "red" || type === "Not Fit" || type == "Rejected"
        ? "rgba(236, 47, 60, 0.1)"
        : type === "orange" || type === "Attempted"
        ? "rgba(247, 121, 53, 0.1)"
        : type === "yellow" || type === "Pending Review"
        ? "#FFF3B7"
        : "#EEEEEE"};
    color: ${({ type }) =>
      type === "blue" || type === "New"
        ? "#4286F5"
        : type === "primaryBlue" || type === "Fit"
        ? "#008561"
        : type === "purple"
        ? "#604FDD"
        : type === "violet"
        ? "#772574"
        : type === "pink" || type === "Requested"
        ? "#C535F7"
        : type === "red" || type === "Not Fit" || type == "Rejected"
        ? "#EC2F3C"
        : type === "orange" || type === "Maybe"
        ? "#F77935"
        : type === "yellow" || type === "Pending Review"
        ? "#A67C03"
        : "#4A5668"};
    padding: 0;
    height: 24px;
    line-height: 24px;
    svg {
      height: 20px;
      width: 20px;
    }
    .yellow {
      fill: #fbbc05;
    }
  }
`;

import styled, { css } from "styled-components";

export const QuestionBoxWrapper = styled.div`
  color: black;
  ${"" /* display: grid; */}
  ${"" /* grid-template-columns: 4fr 1fr; */}
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: "100%";
`;

export const QuestionSection = styled.div`
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  gap: 1rem;
`;

export const SubmitSection = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: end;
  gap: 10px;
`;

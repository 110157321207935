import styled from 'styled-components';

export const BarLoarWrapper = styled.div`
  @keyframes loading {
    40% {
      background-position: 100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  .bar-loading {
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
  }

  .bar-loading .bar {
    background-color: #e0e0e0;
    height: ${(props) => (props.height ? `${props.height}` : '16px')};
    border-radius: 7px;
    width: ${(props) => (props.width ? `${props.width}` : '90%')};
  }

  .bar-loading:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: ${(props) => (props.height ? `${props.height}` : '16px')};
    background-image: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0.075),
      rgba(255, 255, 255, 0.508) 60%,
      rgba(255, 255, 255, 0.025) 80%
    );
    background-size: ${(props) =>
      props.height ? `200px ${props.height}` : '200px 24px'};
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: loading 1s infinite;
  }
`;

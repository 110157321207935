import React from "react";

import { Button, Title } from "components";

import {
  PageHeaderContainer,
  ActionButton,
  SubLinkWrapper,
  TitleWrapper,
} from "./pageHeader.styles";

const PageHeader = ({
  pageTitleText,
  btnText,
  onClick,
  rightSideContent,
  isStartAlign,
  buttonIcon,
  subLink = <></>,
}) => {
  return (
    <PageHeaderContainer isStartAlign={isStartAlign}>
      <TitleWrapper>
        <Title level="h3" text={pageTitleText} />
        {subLink && <SubLinkWrapper>{subLink}</SubLinkWrapper>}
      </TitleWrapper>
      {btnText && !rightSideContent && (
        <ActionButton>
          <Button
            startIcon={buttonIcon}
            btnText={btnText}
            variant="contained"
            onClick={onClick}
          />
        </ActionButton>
      )}
      {rightSideContent && rightSideContent}
    </PageHeaderContainer>
  );
};

export default PageHeader;

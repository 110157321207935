import styled,{ css } from "styled-components";

export const DragList = styled.div(
  ({
    theme: {
      colors: { blue },
    },
  }) => css`

    .add-button {
      color: ${blue};
      margin-bottom:10px;
    }
  `
);

import React, { useState, useEffect } from "react";
import { PageHeader, Dialog, Title, Button } from "components";
import AddIcon from "@mui/icons-material/Add";
import { Box, ModalButtonWrapper } from "assets/styles/main.styles";
import CategoryListingTable from "./components/CategoryListingTable";
import CreateCategory from "./components/CreateCategory";
import { deleteCategory, getCategory } from "config/api.service";
import { useAlert } from "contexts/alert.context";

const Category = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { showAlert } = useAlert();

  useEffect(() => {
    setLoading(true);
    getCategories();
  }, []);

  async function getCategories() {
    getCategory(function (result) {
      setData(result);
      setLoading(false);
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickEdit = (details) => {
    setIsEdit(true);
    setSelectedCategory(details);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCategory({});
    getCategories();
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setIsDeleteOpen(true);
  };

  const deleteConfirmed = async () => {
    deleteCategory({ id: deleteId }, successCallback, errorCallback);
  };

  const successCallback = () => {
    showAlert("Category deleted successfully", "success");
    handleIsDelete();
    getCategories();
  };

  const errorCallback = (msg) => {
    handleIsDelete();
    showAlert(msg, "error");
  };

  const handleIsDelete = () => {
    setIsDeleteOpen(false);
  };

  return (
    <div style={{ padding: "2rem 1rem" }}>
      <PageHeader
        buttonIcon={<AddIcon />}
        pageTitleText="Categories"
        btnText="Create New Category"
        onClick={handleClickOpen}
      />
      <Box>
        <CategoryListingTable
          isCompleteList
          handleClickOpen={handleClickOpen}
          handleClickEdit={handleClickEdit}
          handleDelete={handleDelete}
          data={data}
          isLoading={loading}
        />
      </Box>
      {open && (
        <Dialog
          size="md"
          open={open}
          close={handleClose}
          title={<>{`${isEdit ? "Update" : "Create New"} Category`}</>}
          children={<CreateCategory isEdit={isEdit} handleClose={handleClose} details={selectedCategory} />}
        />
      )}
      {isDeleteOpen && (
        <Dialog
          size="md"
          open={isDeleteOpen}
          close={handleIsDelete}
          title={<>Delete Category ?</>}
          children={
            <>
              <Title level="h6" text="Once deleted, cannot revert back. Are you sure ?" />
              <ModalButtonWrapper>
                <Button variant="contained" btnText="Yes" onClick={deleteConfirmed} />
                <Button variant="outlined" btnText="No" onClick={handleIsDelete} />
              </ModalButtonWrapper>
            </>
          }
        />
      )}
    </div>
  );
};

export default Category;

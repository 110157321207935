import React, { useEffect, useState } from "react";
import { Grid, CardMedia, Tooltip, Rating, Avatar } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Title, Modal, GoBackButton, Tag, Input } from "components";
import Stack from "@mui/material/Stack";
import BadgeIcon from "@mui/icons-material/Badge";
import useWindowSize from "hooks/useWindowSize";
import VideoCard from "./VideoCard";
import {
  Box,
  BlockMargin,
  BodyTextFeedback,
  ButtonIcon,
  ReviewModalHeader,
} from "assets/styles/main.styles";
import {
  downloadResume,
  saveReview,
  getCandidateDetails,
  saveReviewAsDraft,
  saveReviewFromExternal,
} from "config/api.service";
import { useAlert } from "contexts/alert.context";
import ResumePreview from "components/ResumePreview/ResumePreview";
import { appUrl } from "config/constants";
import { format } from "date-fns";
import { isCandidatePassed } from "utils";
import QuestionAnswers from "./QuestionAnswers";
import ShareModalContent from "./ShareModalContent";
import AnswerHeader from "./AnswerHeader";
import AnswersNavbar from "./AnswersNavbar";
import { useUser } from "contexts/user.context";
import { encrypt } from "utils/encrypt";

const Answers = ({ id, role }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let candidateId = "";
  let roleId = "";
  if (id && role) {
    candidateId = id;
    roleId = role;
  } else {
    candidateId = state?.candidateId;
    roleId = state?.roleId;
  }
  const { userDetails } = useUser();
  const windowSize = useWindowSize();
  const [candidateData, setCandidateData] = useState({});
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [basicAnswers, setBasicAnswers] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [comment, setComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownloadingResume, setIsDownloadingResume] = useState(false);
  const { showAlert } = useAlert();
  const [roleDetails, setRoleDetails] = useState({});
  const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
  const [isKycPreviewOpen, setIsKycPreviewOpen] = useState(false);
  const [kycModalOpen, setKycModalOpen] = useState(false);
  const [resumeFile, setResumeFile] = useState("");
  const [kycFile, setKycFile] = useState("");
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveWarningModalOpen, setIsSaveWarningModalOpen] = useState(false);
  const [selectedQuestionDetails, setSelectedQuestionDetails] = useState({});
  const [selectedQuestionNo, setSelectedQuestionNo] = useState(0);
  const [remainingCharCount, setRemainingCharCount] = useState(0);

  const pageLeaveHandler = () => {
    setIsSaveWarningModalOpen(false);
  };

  useEffect(() => {
    if (candidateId && roleId) {
      async function getCandidateData() {
        const res = await getCandidateDetails(candidateId, roleId);
        if (res.status === "success") {
          // console.log(res.data);
          setResumeFile(
            res.data?.resumeDir?.length > 0
              ? JSON.parse(res.data?.resumeDir[0]?.docMeta).Location
              : null
          );
          setKycFile(
            res.data?.KycPool?.length > 0
              ? JSON.parse(res.data?.KycPool[0]?.kycMeta).Location
              : null
          );
          setCandidateData(res.data);
          let defaultReviewData = res?.data?.jobrole.interviewQuestions.map(
            (item) => {
              return {
                questionId: item.id,
                rating: 0,
              };
            }
          );
          if (res?.data?.Feedback?.length > 0) {
            res?.data?.Feedback?.forEach((item) => {
              defaultReviewData.forEach((data) => {
                if (data.questionId === item.questionId) {
                  data.rating = item.rating;
                }
              });
            });
          }
          setReviewData(defaultReviewData);
        }
      }
      getCandidateData();
    }
  }, [candidateId, roleId]);

  const useOnPageLeave = (handler) => {
    useEffect(() => {
      window.onbeforeunload = () => handler();

      window.addEventListener("beforeunload", (event) => {
        handler();
      });

      return () => {
        handler();
        document.removeEventListener("beforeunload", handler);
      };
    });
  };

  useOnPageLeave(pageLeaveHandler);

  const handleComment = (e) => {
    if (e.target.value.length <= 500) {
      setComment(e.target.value);
      setRemainingCharCount(e.target.value.length);
    }
  };

  const handleReviewData = (id, rating) => {
    let temp = [...reviewData];
    const index = temp.findIndex((item) => item.questionId === id);
    temp[index] = { ...temp[index], rating };
    setReviewData(temp);
  };

  const openResumePreview = () => {
    setIsResumePreviewOpen(true);
  };
  const openKycPreview = () => {
    setIsKycPreviewOpen(true);
  };

  function generateShareLink(id, role) {
    const currentTimestamp = Date.now();
    const threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
    const futureTimestamp = currentTimestamp + threeDaysInMilliseconds;
    let data = {
      id: candidateId,
      role: roleId,
      expiry: futureTimestamp,
    };
    let cipheredData = encrypt(data);
    const link = `${appUrl}/reviewer-login-enc?id=${cipheredData}`;
    setShareLink(link);
  }

  const handleBasicAnswers = (res) => {
    console.warn("handleBasicAnswers ()", res);
    setBasicAnswers(res);
  };

  const handleBasicAnswersErr = (error) => {
    console.log(error);
  };

  const handleSuccess = () => {
    showAlert("Successfully reviewed", "success");
    setIsModalOpen(false);
    if (id == undefined) {
      navigate(-1);
    }
  };

  const handleFailedRequest = (errorMessage) => {
    showAlert(errorMessage || "something went wrong", "error");
    setIsModalOpen(false);
  };

  const downloadResumeFunction = async () => {
    setIsDownloadingResume(true);
    await downloadResume(interviewDetails[0].resumeLink);
    setIsDownloadingResume(false);
  };

  const handleResumePreview = () => {
    setIsResumePreviewOpen(false);
    setResumeFile("");
  };
  const handleSubmit = async () => {
    let isValid = true;
    for (let reviewElement of reviewData) {
      if (reviewElement.rating === undefined || !reviewElement.rating) {
        isValid = false;
      }
    }
    if (isValid) {
      const data = {
        reviewData,
        candidateId,
        roleId,
        comment,
      };
      setIsSubmitting(true);
      if (id && role) {
        data.isExternal = id && role ? true : false;
        data.reviewerEmail = userDetails?.email;
        await saveReviewFromExternal(data, handleSuccess, handleFailedRequest);
      } else {
        await saveReview(data, handleSuccess, handleFailedRequest);
      }
      setIsSubmitting(false);
    } else {
      showAlert("Please rate all the questions and also add comment", "error");
    }
  };

  const handleSave = async () => {
    let isValid = false;
    for (let reviewElement of reviewData) {
      if (reviewElement.rating > 0) {
        isValid = true;
      }
    }
    if (isValid) {
      const data = {
        reviewData,
        candidateId,
        roleId,
        isExternal: id && role ? true : false,
      };
      setIsSubmitting(true);
      await saveReviewAsDraft(
        data,
        (res) => {
          showAlert(res.message, "success");
        },
        handleFailedRequest
      );
      setIsSubmitting(false);
    } else {
      showAlert("Please rate all the questions and also add comment", "error");
    }
  };

  function showKycDetails(data) {
    if (data && data.length) {
      const { createdDate, kycMeta } = data;
      const parsedKycMeta = JSON.parse(kycMeta);
      return (
        <>
          <Title
            level={"body"}
            text={`Captured on: ${format(createdDate, "PPp")}`}
          />
          <img
            src={parsedKycMeta?.Location}
            alt="kyc"
            style={{ maxWidth: "100%" }}
          />
        </>
      );
    }
    return;
  }

  const { isPassed, overallRating } = isCandidatePassed(
    candidateData?.Feedback,
    candidateData?.jobrole?.interviewQuestions
  );

  const statusId =
    candidateData?.InterviewPool?.length > 0
      ? candidateData?.InterviewPool?.[0].status
      : 0;

  if (!candidateData) {
    showAlert("No candidate found with the given details", "error");
    return <></>;
  }

  const onBasicAnswerNavClicked = () => {
    window.scrollTo({
      top: document.getElementById("basicAnswers")?.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div style={{ padding: "1rem" }}>
      <Box>
        <AnswerHeader
          candidateData={candidateData}
          reviewData={reviewData}
          statusId={statusId}
          overallRating={overallRating}
          handleReviewClicked={() => {
            setIsModalOpen(true);
            setSelectedQuestionDetails({});
            setSelectedQuestionNo(0);
          }}
        />

        <Grid container justifyContent={"center"}>
          {candidateData?.reviewComments?.length > 0 && (
            <BlockMargin size={30} style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item xs={12}>
                  <BodyTextFeedback>
                    {candidateData?.reviewComments[0]?.comments}
                  </BodyTextFeedback>
                </Grid>
              </Grid>
            </BlockMargin>
          )}
        </Grid>

        <AnswersNavbar
          resumeFile={resumeFile}
          kycFile={kycFile}
          candidateData={candidateData}
          statusId={statusId}
          handleResumePreview={openResumePreview}
          handleShareButton={() => {
            setShareModalOpen(true);
            generateShareLink(candidateId, roleId);
          }}
          roleDetails={roleDetails}
          handleKycPreview={openKycPreview}
          setKycModalOpen={setKycModalOpen}
          onBasicAnswerNavClicked={onBasicAnswerNavClicked}
        />

        <Grid container spacing={4} sx={{ marginTop: "10px" }}>
          {candidateData?.AnswerPool?.map((item, i) => {
            const vidMeta = JSON.parse(item?.vidMeta);
            const data = {
              qId: item.qId,
              ...vidMeta,
            };
            return (
              <Grid item sm={12} md={6} lg={4} xl={3} key={i}>
                <QuestionAnswers
                  data={data}
                  reviewData={reviewData}
                  currentQuestionNo={i + 1}
                  feedback={candidateData?.Feedback}
                  handleModalOpen={() => {
                    setIsModalOpen(true);
                    setSelectedQuestionDetails(data);
                    setSelectedQuestionNo(i + 1);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>

        {isModalOpen && (
          <Modal
            size="md"
            open={isModalOpen}
            close={() => setIsModalOpen(false)}
            title={<ReviewModalHeader></ReviewModalHeader>}
            children={
              <VideoCard
                selectedQuestionDetails={selectedQuestionDetails}
                selectedQuestionNo={selectedQuestionNo}
                totalQuestions={
                  candidateData?.jobrole?.interviewQuestions?.length
                }
                interviewDetails={candidateData?.AnswerPool}
                fromFeedback={candidateData?.Feedback > 0}
                reviewData={reviewData}
                handleReviewData={handleReviewData}
                comment={comment}
                handleComment={handleComment}
                handleSubmit={handleSubmit}
                remainingCharCount={remainingCharCount}
              />
            }
          />
        )}

        <BlockMargin size={20} />

        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <BlockMargin size={20} id="basicAnswers">
              <Title level="h4" text="Basic Answers" />
            </BlockMargin>

            <Grid container spacing={2} alignItems="flex-start">
              {candidateData?.jobrole?.baseQuestions?.map((item, index) => {
                return (
                  <Grid
                    container
                    item
                    sm={12}
                    isGrey
                    sx={{
                      backgroundColor: index % 2 == 0 ? "#F1F1F1" : "#FBFBFB",
                      paddingBottom: "16px",
                      marginLeft: "16px",
                    }}
                  >
                    <Grid item sm={6}>
                      {item.question}
                    </Grid>
                    <Grid item sm={6} sx={{ paddingLeft: "20px" }}>
                      {candidateData?.BaseQuestionsAnswers[index]?.answer ??
                        " "}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <ResumePreview
        isResumePreviewOpen={isResumePreviewOpen}
        handleResumePreview={handleResumePreview}
        resumePath={resumeFile}
      />

      {shareModalOpen && (
        <Modal
          size="md"
          open={shareModalOpen}
          close={() => setShareModalOpen(false)}
          title="Share link"
          children={<ShareModalContent shareLink={shareLink} />}
        />
      )}

      {isKycPreviewOpen && (
        <Modal
          size="md"
          open={isKycPreviewOpen}
          close={() => setIsKycPreviewOpen(false)}
          title="KYC Image"
          children={
            <img src={kycFile} alt="kyc" style={{ maxWidth: "100%" }} />
          }
        />
      )}
    </div>
  );
};

export default Answers;

/* {candidateData?.jobrole?.interviewQuestions?.length ? (
                <Grid item xs={6}>
                  <Title level="h4" text="Interview Question" />
                  <Box isGrey>
                    <Grid container spacing={2} alignItems="flex-start">
                      {candidateData?.jobrole?.interviewQuestions?.map(
                        (item, index) => {
                          return (
                            <BasicQuestionItem key={item.id}>
                              <List>
                                <ListItem disablePadding>
                                  <ListItemButton
                                    onClick={() => {
                                      setSelectedVideoQuestion(item);
                                      setSelectedVideoIndex(index);
                                    }}
                                  >
                                    <ListItemText>
                                      {index + 1} .{item.question}{" "}
                                    </ListItemText>
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </BasicQuestionItem>
                          );
                        }
                      )}
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                ""
              )} */

/* Comments */

/* {candidateData?.Feedback?.length != reviewData?.length && (
          <>
            <Title level="h6" text="Comments" />
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={6}>
                <Input
                  placeholder="Example:  Comments By: [ Your Name ] ... Add Comments about the Candidate."
                  name="overall-comments"
                  isMulti
                  value={comment}
                  onChange={handleComment}
                  minRows={4}
                  style={{
                    fontFamily: "Manrope",
                    borderRadius: "5px",
                    borderColor: "lightgray",
                    padding: "10px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Button
                  variant="contained"
                  btnText="Submit Feedback"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </>
        )} */

import React from "react";

import { SearchWithIcon } from "components";

const Search = ({onChange, ...rest }) => {
  return (
    <div>
      {/* <input placeholder="Firstname" onChange={onChange} /> */}
      <SearchWithIcon
        placeholder={ "Search here"}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default Search;

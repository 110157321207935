import React, { useState, useEffect } from "react";
import { Title, Modal, Input, Button, Tag } from "components";
import { Grid, Rating, CardMedia } from "@mui/material";
import Chip from "@mui/material/Chip";
import {
  ReviewAnswerCardContainer,
  VideoContainer,
  ReviewCardContainer,
  PlayButton,
  NameDisplay,
  RemainingCharCount,
  ModalCommentWrapper,
} from "assets/styles/main.styles";
import Stack from "@mui/material/Stack";
import { RatingValues } from "config/constants";
import { ButtonIcon } from "assets/styles/main.styles";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MessageIcon from "@mui/icons-material/Message";
import Icon from "@mui/material/Icon";
function VideoCard({
  selectedQuestionDetails,
  selectedQuestionNo,
  interviewDetails,
  fromFeedback,
  reviewData,
  handleReviewData,
  comment,
  handleComment,
  handleSubmit,
  remainingCharCount,
}) {
  const [currentData, setCurrentData] = useState(selectedQuestionDetails);
  const [serialNumber, setSerialNumber] = useState(selectedQuestionNo);
  const [newRating, setNewRating] = useState(0);
  const [hover, setHover] = React.useState(-1);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [currentReviewData, setCurrentReviewData] = useState(null);

  const interviews = interviewDetails.map((item) => ({
    qId: item.qId,
    ...JSON.parse(item?.vidMeta),
  }));

  useEffect(() => {
    if (selectedQuestionNo > 0) {
      setSerialNumber(selectedQuestionNo);
      const currentReviewData = reviewData.find(
        (item) => item.questionId === currentData?.qId
      );
      setNewRating(currentReviewData?.rating);
      setCurrentReviewData(currentReviewData);
    } else {
      let lastReviewedQuestion = reviewData.findIndex((item) => {
        return item.rating == 0;
      });
      if (lastReviewedQuestion == -1) {
        lastReviewedQuestion = 0;
      }
      if (lastReviewedQuestion < reviewData.length) {
        setSerialNumber(lastReviewedQuestion + 1);
      }
    }
  }, [selectedQuestionNo]);

  useEffect(() => {
    const newCurrentData = interviews[serialNumber - 1];
    if (newCurrentData) {
      setCurrentData(newCurrentData);
      if (fromFeedback) {
        setNewRating(newCurrentData?.rating);
      } else {
        const currentReviewData = reviewData.find(
          (item) => item.questionId === newCurrentData?.qId
        );
        setNewRating(currentReviewData?.rating);
        setCurrentReviewData(currentReviewData);
      }
    }
    checkIfLastQuestion(serialNumber);
  }, [serialNumber]);

  const handleSave = (givenRating) => {
    handleReviewData(currentData?.qId, givenRating);
  };

  const handleNext = () => {
    if (interviewDetails.length > serialNumber) {
      setSerialNumber(serialNumber + 1);
      if (!fromFeedback) {
        setNewRating(0);
      }
    }
    checkIfLastQuestion(serialNumber + 1);
  };

  const handlePrev = () => {
    setSerialNumber(serialNumber - 1);
    if (!fromFeedback) {
      setNewRating(0);
    }
  };

  const checkIfLastQuestion = (currentQuestion) => {
    if (interviewDetails.length < currentQuestion) {
      setIsLastQuestion(true);
    }
  };

  const backFromComment = () => {
    setSerialNumber(serialNumber - 1);
    setIsLastQuestion(false);
  };
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${RatingValues[value]}`;
  }

  return (
    <>
      {Object.keys(currentData).length > 0 && (
        <ReviewCardContainer>
          {!isLastQuestion ? (
            <>
              <Title
                style={{
                  marginRight: "50px",
                  marginTop: "-14px",
                  marginBottom: "20px",
                }}
                level="h4"
                lineheight={1.5}
                text={`${serialNumber}. ${currentData?.question}`}
              />

              <Grid
                container
                direction={"row"}
                justifyContent="space-between"
                alignItems={"flex-end"}
              >
                <Stack direction="row" spacing={1}>
                  <Rating
                    size="large"
                    sx={{
                      fontSize: "2.5rem",
                    }}
                    name="hover-feedback"
                    value={newRating ?? 0}
                    precision={1}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setNewRating(newValue);
                      handleSave(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    readOnly={fromFeedback}
                  />
                  {newRating !== null && (
                    <span style={{ paddingTop: "8px", fontSize: "1.5rem" }}>
                      {RatingValues[hover !== -1 ? hover : newRating]}
                    </span>
                  )}
                </Stack>

                <Stack direction="row" spacing={1} marginTop={"10px"}>
                  <Button
                    variant={"outlined"}
                    btnText="Prev"
                    startIcon={<ArrowBackOutlinedIcon />}
                    onClick={handlePrev}
                    disabled={serialNumber < 2}
                  />
                  <Button
                    variant={"contained"}
                    btnText="Next"
                    onClick={handleNext}
                    endIcon={<ArrowForwardIcon />}
                    disabled={newRating == null}
                  />
                </Stack>
              </Grid>
              {currentData?.vidMeta?.assets?.mp4 && (
                <Grid sx={{ marginTop: "10px" }}>
                  <VideoContainer>
                    <CardMedia
                      component="video"
                      controls
                      src={currentData?.vidMeta?.assets?.mp4}
                      autoPlay
                    />
                  </VideoContainer>
                </Grid>
              )}
            </>
          ) : (
            <ModalCommentWrapper>
              <Title level="h6" text="Tell us what you think" />
              <Grid container spacing={2} alignItems="flex-end">
                <Icon
                  color="disabled"
                  sx={{
                    position: "absolute",
                    left: 0,
                    width: "5em",
                    height: "5em",
                    top: "3rem",
                  }}
                >
                  <MessageIcon sx={{ fontSize: "4rem" }} />
                </Icon>
                <Grid item xs={12}>
                  <Input
                    placeholder="Example:  Comments by: [ Your Name ] | Comments about the candidate."
                    name="overall-comments"
                    isMulti
                    value={comment}
                    onChange={handleComment}
                    minRows={4}
                    maxLength={500}
                  />
                  <RemainingCharCount>
                    {remainingCharCount ?? 0} / 500
                  </RemainingCharCount>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant={"outlined"}
                    btnText="Prev"
                    startIcon={<ArrowBackOutlinedIcon />}
                    onClick={backFromComment}
                  />
                  <Button
                    variant="contained"
                    btnText="Submit Feedback"
                    onClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </ModalCommentWrapper>
          )}
        </ReviewCardContainer>
      )}
    </>
  );
}

export default VideoCard;

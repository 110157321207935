import styled, { css } from "styled-components";

export const StepperContainer = styled.div(
  ({
    theme: {
      colors: { primaryBlue },
    },
  }) => css`
    .Mui-active {
      color: ${primaryBlue} !important;
    }
    .Mui-completed {
      color: ${primaryBlue} !important;
    }
  `
);

export const ProgressContainerBlock = styled.div`
  /* position: relative; */
`;

export const TabContentContainer = styled.div`
  margin: 0;
`;

export const ProgressBlock = styled.div(
  ({
    theme: {
      colors: { orange },
    },
  }) => css`
    margin: 40px 0;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin: 0;
    .MuiLinearProgress-root {
      width: 100%;
      background-color: rgba(247, 121, 53, 0.3);
    }
    .MuiLinearProgress-bar {
      background-color: ${orange};
    }
    .MuiPaper-root {
      background-color: transparent;
    }
    .MuiMobileStepper-dots {
      width: 100%;
    }
    .MuiMobileStepper-dot {
      height: 11px;
      width: 36px;
      border-radius: 6px;
      color: #2e6ae0;
    }
  `
);

export const Step = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${(props) => props.colour};
  width: 30px;
  border-radius: 54px;
  margin-right: 3px;
  border: 1px solid;
  cursor: pointer;
`;

export const ProgressWithBadge = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.86);
  border-radius: 4px;
  padding: 25px 20px;
  position: absolute;
  top: 20px;
  z-index: 10;
  max-height: 195px;
  left: 5%;
  right: 5%;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;

  @media (max-width: 768px) {
  }
`;

export const ProgressItem = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  .badge-grid {
    justify-content: space-around;
    width: 100%;
  }
`;

export const Badge = styled.div`
  padding: 0 10px;
  background-color: rgba(255, 231, 5, 0.2);
  height: 140px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 50px;
    // height: 50px;
    margin-right: 10px;
  }
`;

export const ProgressWithLabel = styled.div`
  margin: 0;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const BadgeTitle = styled.h1(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f32};
    font-weight: ${fontWeight.semibold};
    line-height: 28px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f14};
      line-height: 20px;
    }
  `
);

export const BadgeSubtext = styled.h4(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f16};
    font-weight: ${fontWeight.regular};
  `
);

export const BadgeIcon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

export const BadgeLabel = styled.label(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.semibold};
    line-height: 28px;
    @media (max-width: 768px) {
      font-size: 10px;
      text-align: center;
      line-height: 16px;
    }
  `
);

export const InterviewContainer = styled.div`
  position: relative;
`;

export const InterviewActions = styled.div(
  ({
    theme: {
      colors: { white },
    },
  }) => css`
    position: absolute;
    bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    .action-icons {
      font-size: 78px;
      color: ${white};
      &.sm {
        font-size: 68px;
      }
    }
    z-index: 100;

    @media (max-width: 768px) {
      .action-icons {
        font-size: 42px;
        color: ${white};
        &.sm {
          font-size: 32px;
        }
      }
    }
  `
);

export const AlertContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
    h6 {
      margin: 10px 0 20px 0;
    }
    button {
      width: 70% !important;
    }
  }
`;

export const TimeCounter = styled.div(
  ({
    theme: {
      colors: { black },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${black};
    font-family: ${base};
    font-size: ${fontSize.f20};
    font-weight: ${fontWeight.semibold};
    line-height: 28px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    span {
      font-size: ${fontSize.f20};
      font-weight: ${fontWeight.regular};
    }

    @media (max-width: 768px) {
      font-size: ${fontSize.f16};
      span {
        font-size: ${fontSize.f14};
      }
      position: absolute;
      left: 0;
      bottom: -80px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      padding: 6px;
      border-radius: 3px;
    }
  `
);
export const RedDot = styled.img`
  margin-right: 5px;
`;

export const Countdown = styled.h1(
  ({
    theme: {
      colors: { white },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${white};
    font-family: ${base};
    font-size: ${fontSize.f32};
    font-weight: ${fontWeight.semibold};
  `
);

export const IAactionsflex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: 40px;
`;

export const NextAction = styled.div`
  /* position: absolute;
  right: -15%;
  top: 60%; */
  align-items: flex-end;
  @media (max-width: 768px) {
    position: static;
    display: flex;
    justify-content: flex-end;
  }
`;

export const AlertSub = styled.h5(
  ({
    theme: {
      colors: { blueGrey },
      fontFamily: { base },
      fontSize,
      fontWeight,
    },
  }) => css`
    color: ${blueGrey};
    font-family: ${base};
    font-size: ${fontSize.f14};
    font-weight: ${fontWeight.regular};
    line-height: 20px;
    margin-top: -16px;
    margin-bottom: 16px;
    @media (max-width: 768px) {
      font-size: ${fontSize.f14};
    }
  `
);

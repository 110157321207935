import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Table } from "components";
import {
  TableButtonContainer,
  TableContainer,
} from "assets/styles/main.styles";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDateDMY } from "utils";
import {format } from "date-fns";
const CategoryListingTable = ({
  isCompleteList = true,
  filter,
  hideSearch,
  handleClickEdit,
  handleDelete,
  reloadList,
  data,
  loading,
}) => {
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    setCategoryData(data);
  }, [data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "CREATED ON",
        accessor: "createdDate",
        Cell: (row) => {
          const date = new Date(row.row.original.createdDate);
          return (
            <>
              {row.row.original.createdDate
                ?  format(row.row.original.createdDate, "PPp")
                : ""}
            </>
          );
        },
      },
      {
        id: "action",
        Header: "",
        accessor: "Action",
        Cell: (row) => {
          return (
            <>
              {row.row.original.isAssigned
                ? null
                : row.row.original.name !== "General" && (
                    <TableButtonContainer isFlex>
                      <Button
                        className="editbtn"
                        startIcon={<ModeEditOutlineOutlinedIcon />}
                        size="small"
                        // btnText="Edit"
                        variant="secondary"
                        onClick={() => handleClickEdit(row.row.original)}
                        sx={{
                          "& .MuiButton-startIcon": {
                            marginRight: 0,
                          },
                        }}
                      />
                      <Button
                        startIcon={<DeleteIcon />}
                        size="small"
                        className="deletebtn"
                        variant="secondary"
                        onClick={() => handleDelete(row.row.original.id)}
                        sx={{
                          "& .MuiButton-startIcon": {
                            marginRight: 0,
                          },
                        }}
                      />
                    </TableButtonContainer>
                  )}
            </>
          );
        },
        disableSortBy: true,
      },
    ],
    [navigate, categoryData]
  );

  return (
    <div className="table-container">
      <TableContainer>
        <Table
          columns={columns}
          data={isCompleteList ? categoryData : categoryData.slice(0, 5)}
          isLoading={loading}
          defaultFilter={filter}
          hideSearch={hideSearch}
          hideFilter
          numOfLoadingBarCols={3}
        />
      </TableContainer>
    </div>
  );
};

export default CategoryListingTable;

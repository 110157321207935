import styled from 'styled-components';

export const DragContainer = styled.div`
  width: 100%;

  .drag-handle {
    display: flex;
    rotate: 90deg;
  }
  .rating-input {
    max-width: 100%;
    margin-left: 10px;
    margin-top: 5px;
  }
  .delete-icon {
    visibility: hidden;
  }
  &:hover .delete-icon {
    visibility: visible;
  }
  .question-padding {
    padding-top: 0px !important;
  }
  .box-padding {
    padding-top: 10px !important;
  }
`;
export const LabelTitle = styled.label`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: -15px;
`;
export const RatingBlockCutoff = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  h4 {
    margin-left: 10px;
    margin-bottom: -3px;
  }
  .MuiRating-sizeLarge {
    font-size: 38px;
  }
`;

export const DeleteIconWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: ${(props) => (props.showDeleteIcon ? 'block' : 'none')};
`;

import React, { useEffect, useState } from "react";
// import ProgressBar from "@ramonak/react-progress-bar";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { Rating, CardMedia, Grid } from "@mui/material";
import { Title, Modal, Button, Input } from "components";
import {
  ReviewAnswerCardContainer,
  VideoContainer,
  ReviewAnswerRatingWrapper,
  ReviewCardContainer,
  ReviewModalHeader,
  PlayButton,
} from "assets/styles/main.styles";
import { baseUrl } from "config/constants";
import Play from "assets/images/play.jpg";
import { format } from "date-fns";
import { RatingValues } from "config/constants";
import VideoThumbnailPlaceholder from "assets/images/video-thumbnail.svg";

import Stack from "@mui/material/Stack";
import "./review-card-styles.css";

const ReviewAnswerCard = ({
  data,
  handleReviewData,
  reviewData,
  totalQuestions,
  currentQuestionNo,
  fromFeedback = false,
  interviewDetails,
  comment,
  handleComment,
  handleSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [thumbanilError, setthumbanilError] = useState(false);

  const findPercentage = (value, topValue) => {
    return (value / topValue) * 100;
  };

  const handleRating = (id, rating) => {
    handleReviewData(id, rating);
  };

  const details = {
    data,
    reviewData,
    totalQuestions,
    currentQuestionNo,
    setIsOpen,
    fromFeedback,
    interviewDetails,
    comment,
    handleComment,
    handleSubmit,
    handleRating,
    handleReviewData,
  };

  return (
    <>
      <ReviewAnswerCardContainer
        isClickable={true}
        onClick={() => setIsOpen(true)}
      >
        {data.thumbnail && (
          <VideoContainer>
            <img
              className="thumbnail"
              style={{ width: "100%" }}
              src={thumbanilError ? VideoThumbnailPlaceholder : data.thumbnail}
              alt="thumbnail"
              onClick={() => setIsOpen(true)}
              onError={() => setthumbanilError(true)}
            />
            <PlayButton>
              <img src={Play} alt="play" onClick={() => setIsOpen(true)} />
            </PlayButton>
          </VideoContainer>
        )}
        <FeedbackCard {...details} />
      </ReviewAnswerCardContainer>
      {isOpen && (
        <Modal
          size="md"
          open={isOpen}
          close={() => {
            setIsOpen(false);
          }}
          title={<ReviewModalHeader></ReviewModalHeader>}
          children={
            <ReviewCard
              currentQuestionNo={currentQuestionNo}
              isModal={true}
              handleClose={() => setIsOpen(false)}
              {...details}
            />
          }
        />
      )}
    </>
  );
};

export default ReviewAnswerCard;

function ReviewCard({ isModal, currentQuestionNo, ...details }) {
  const {
    data,
    reviewData,
    handleRating,
    setIsOpen,
    fromFeedback,
    interviewDetails,
    comment,
    handleComment,
    handleSubmit,
    handleReviewData,
  } = details;

  console.log(data);

  const [currentData, setCurrentData] = useState(data);
  const [serialNumber, setSerialNumber] = useState(currentQuestionNo);
  const [newRating, setNewRating] = useState(0);
  const [hover, setHover] = React.useState(-1);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [currentReviewData, setCurrentReviewData] = useState(null);

  useEffect(() => {
    if (fromFeedback) {
      setNewRating(data?.rating?.rating);
    } else {
      const currentReviewData = reviewData.find(
        (item) => item.questionId === currentData?.questionId
      );
      setNewRating(currentReviewData?.rating);
      setCurrentReviewData(currentReviewData);
    }
  }, [reviewData, currentData]);

  const handleSave = (givenRating) => {
    // handleRating(currentData?.questionId, givenRating);
    handleReviewData(currentData?.questionId, givenRating);
  };

  const handleNext = () => {
    if (interviewDetails.length > serialNumber) {
      setSerialNumber(serialNumber + 1);
      if (!fromFeedback) {
        setNewRating(0);
      }
    }
    checkIfLastQuestion(serialNumber + 1);
  };

  const checkIfLastQuestion = (currentQuestion) => {
    if (interviewDetails.length < currentQuestion) {
      setIsLastQuestion(true);
    }
  };

  function getLabelText(value) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${RatingValues[value]}`;
  }

  useEffect(() => {
    const newCurrentData = interviewDetails[serialNumber - 1];
    setCurrentData(newCurrentData);
    // console.log(currentData, reviewData, serialNumber, newCurrentData);
    if (fromFeedback) {
      setNewRating(newCurrentData?.rating);
    } else {
      const currentReviewData = reviewData.find(
        (item) => item.questionId === newCurrentData?.questionId
      );
      setNewRating(currentReviewData?.rating);
      setCurrentReviewData(currentReviewData);
    }

    checkIfLastQuestion(serialNumber);
  }, [serialNumber]);

  return (
    <ReviewCardContainer>
      {!isLastQuestion ? (
        <>
          <Title
            level="h6"
            text={`${serialNumber}. ${currentData?.question}`}
          />
          <Grid
            container
            sx={{ margin: "1em 0" }}
            justifyContent="space-between"
          >
            <Grid item xs={10}>
              <Grid container>
                <Grid item xs={4} justifyContent="flex-end">
                  <Stack direction="row" spacing={1} paddingTop={"10px"}>
                    <Rating
                      name="hover-feedback"
                      value={newRating ?? 0}
                      precision={1}
                      getLabelText={getLabelText}
                      onChange={(event, newValue) => {
                        setNewRating(newValue);
                        handleSave(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      readOnly={fromFeedback}
                    />
                    {newRating !== null && (
                      <span style={{ paddingTop: "5px" }}>
                        {RatingValues[hover !== -1 ? hover : newRating]}
                      </span>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant={"outlined"}
                btnText="Next"
                onClick={handleNext}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Title level="h6" text="Comments" />
      )}

      {isLastQuestion ? (
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12}>
            <Input
              placeholder="Enter comments"
              name="overall-comments"
              isMulti
              value={comment}
              onChange={handleComment}
              minRows={4}
              style={{
                fontFamily: "Manrope",
                borderRadius: "5px",
                borderColor: "lightgray",
                padding: "10px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              btnText="Submit Feedback"
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          {currentData.vidpath && (
            <VideoContainer>
              <CardMedia
                component="video"
                controls
                src={currentData?.vidpath}
                autoPlay
              />
            </VideoContainer>
          )}
        </>
      )}
      {}
    </ReviewCardContainer>
  );
}

function FeedbackCard({
  data,
  reviewData,
  totalQuestions,
  currentQuestionNo,
  fromFeedback,
  setIsOpen,
}) {
  const currentReviewData = reviewData.find(
    (item) => item.questionId === data?.questionId
  );
  const rating = fromFeedback
    ? data?.rating?.rating
    : currentReviewData?.rating;

  return (
    <ReviewAnswerRatingWrapper>
      <div>
        <Title level="h6" text={`${currentQuestionNo}. ${data?.question}`} />
      </div>
      {rating != undefined ? (
        <>
          <Grid container spacing={2} padding={"10px"}>
            <Grid xs={8}>
              <Stack direction="row" spacing={2} style={{ marginTop: "10px" }}>
                <Rating
                  name="simple-controlled"
                  value={rating ?? 0}
                  defaultValue={rating ?? 0}
                  precision={1}
                  size="large"
                  readOnly
                />
                <Title
                  level="h6"
                  text={rating !== null && <b>{RatingValues[rating]}</b>}
                />
              </Stack>
            </Grid>
            <Grid xs textAlign={"right"} sx={{ paddingTop: "10px" }}>
              <Title level="h6" text={format(data?.createdDate, "PPp")} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Button
          btnText="Rate now"
          variant="contained"
          size="sm"
          onClick={() => setIsOpen(true)}
        />
      )}
    </ReviewAnswerRatingWrapper>
  );
}

export function addZeroIfSingleDigit(number) {
  return number < 10 ? `0${number}` : number;
}

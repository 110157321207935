import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export default function GlobalStyleOverrides() {
  const theme = createTheme({
    typography: {
      fontFamily: ['"Manrope"', "sans-serif"].join(","),
      body1: {
        fontFamily: "'Manrope', sans-serif",
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              textTransform: "none",
              backgroundColor: "#2E6AE0",
              "&:hover": {
                backgroundColor: "#2455b7",
              },
              "&:disabled": {
                backgroundColor: "#2E6AE0",
                color: "rgba(255, 255, 255, 0.5)",
                opacity: 0.8,
              },
            },
          },
          {
            props: { variant: "outlined" },
            style: {
              textTransform: "none",
              backgroundColor: "transparent",
              color: "#2E6AE0",
              border: "1px solid #2E6AE0",
              "&:hover": {
                backgroundColor: "transparent",
                border: "1px solid #2455b7",
              },
              "&:disabled": {
                color: "#2E6AE0",
                border: "1px solid #2455b7",
                opacity: 0.9,
              },
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              textTransform: "none",
              backgroundColor: "rgba(36, 85, 183, 0.1)",
              color: "#2E6AE0",
              border: "none",
              "&:hover": {
                backgroundColor: "rgba(36, 85, 183, 0.15)",
                border: "none",
              },
              "&:disabled": {
                color: "#2E6AE0",
                opacity: 0.6,
              },
            },
          },
          {
            props: { variant: "text" },
            style: {
              textTransform: "none",
              color: "#4286F5",
              "&:hover": {
                color: "#4286F5",
              },
              "&:disabled": {
                color: "#4286F5",
                opacity: 0.6,
              },
            },
          },
        ],
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            "& label": {
              fontSize: 12,
            },
          },
          input: {
            fontSize: 14,
          },
          listbox: {
            fontSize: 14,
          },
        },
      },
    },
  });

  return responsiveFontSizes(theme);
}

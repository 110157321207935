import { Count } from "./components/Count";
import { Question } from "./components/Question";
import { useMemo } from "react";
import { QuestionBoxWrapper, QuestionSection, SubmitSection } from "./QuestionBox.styles";

const QuestionBox = ({ currentQuestionNo, currentQuestionId, interviewQuestions, retakeQuestionId, children }) => {
  const isRetake = retakeQuestionId !== "";
  let currentQuestionData = useMemo(() => {
    let qid = currentQuestionId;
    if (isRetake) {
      qid = retakeQuestionId;
    }
    return interviewQuestions.find((q) => q.id === qid);
  }, [interviewQuestions, currentQuestionId, retakeQuestionId]);

  let currentQuestion = currentQuestionData?.question;

  // console.log("FROM QUESTION BOX", currentQuestionData);

  return (
    <QuestionBoxWrapper>
      <QuestionSection>
        {!isRetake && (
          <>
            <Count totalQuestions={interviewQuestions.length} currentQuestionNo={currentQuestionNo ?? ""} />
          </>
        )}
        <Question currentQuestion={currentQuestion} />
      </QuestionSection>
      <SubmitSection>{children}</SubmitSection>
    </QuestionBoxWrapper>
  );
};

export default QuestionBox;

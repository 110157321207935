import React from 'react';
import './loader.css';

const Loader = ({ numOfLoadingBarCols = 3 }) => {
  return (
    <div class="tableWrapper">
      <table class="table">
        <tbody>
          <tr className="tr">
            {new Array(numOfLoadingBarCols).fill(0).map((_, index) => (
              <td class="loading">
                <div class="bar"></div>
              </td>
            ))}
          </tr>
          <tr>
            {new Array(numOfLoadingBarCols).fill(0).map((_, index) => (
              <td class="loading">
                <div class="bar"></div>
              </td>
            ))}
          </tr>
          <tr>
            {new Array(numOfLoadingBarCols).fill(0).map((_, index) => (
              <td class="loading">
                <div class="bar"></div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Loader;

import React, { useState } from "react";
import { Grid, FormControl, Input, InputLabel, Alert, Container } from "@mui/material";
import { Helmet } from "react-helmet";

import Stack from "@mui/material/Stack";
import { Card, Modal, PageHeader, Title, Dialog, Button } from "components";
import JobListingTable from "./components/JobListingTable";
import DashboardCountCards from "components/DashboardCountCards";
import HireSvg from "assets/images/illustrations/hire.svg";
import ClientSvg from "assets/images/illustrations/client.svg";
import InterviewSvg from "assets/images/illustrations/interview.svg";
import AddIcon from "@mui/icons-material/Add";
import Loading from "pages/Loading/Loading";
import { Box, CardBlock, ContentCenter, HideInMobileView, TableActionButton } from "assets/styles/main.styles";
import CreateJob from "./components/CreateJob";
import EmbedCode from "./components/EmbedCode";
import VideoCam from "../../../components/VideoCam";
import { useNavigate, Link } from "react-router-dom";
import { CardContainer, CardImg, CardText, TitleContainer } from "components/Card/card.styles.";
const cardData = [
  // {
  //   img: HireSvg,
  //   tag: "Premium",
  //   title: "Hiring Professional Interviewers",
  //   text: "Examine interviewer profiles and hire the best candidate.",
  // },
  {
    img: InterviewSvg,
    tag: "Premium",
    title: "How to setup Kapiree?",
    text: "Watch a sample video interview to gain a better understanding of the process.",
    btnText: "Watch video",
  },
  // {
  //   img: ClientSvg,
  //   tag: "Premium",
  //   title: "Client Testimonial Video",
  //   text: "Watch videos of clients sharing their platform experiences.",
  // },
  // {
  //   img: InterviewSvg,
  //   tag: "Premium",
  //   title: "Video Interview Sample",
  //   text: "Watch a sample video interview to gain a better understanding of the process.",
  // },
];

const SimpleCard = ({ img, cardTitle, cardText, btnText, setShowVideo }) => {
  return (
    <CardContainer num={true}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <CardImg src={img} alt={img} />
        </Grid>
        <Grid item xs={11}>
          <TitleContainer>
            <Title level="h4" text={cardTitle} />
          </TitleContainer>
          <CardText>{cardText}</CardText>
          <div style={{ width: "100px", marginTop: "5px" }}>
            <Button btnText={btnText} variant="outlined" size="small" onClick={() => setShowVideo(true)} />
          </div>
        </Grid>
      </Grid>
    </CardContainer>
  );
};

const JobListing = () => {
  const [open, setOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [listUpdated, setListUpdated] = useState(false);
  const [embedOpen, setEmbedOpen] = useState(false);
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);

  const handleClickOpen = () => {
    setSelectedJobId(null);
    setOpen(true);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickEdit = (id) => {
    setSelectedJobId(id);
    setOpen(true);
  };
  const handleClickEmbed = (id) => {
    setSelectedJobId(id);
    setEmbedOpen(true);
  };

  const handleClose = () => {
    setSelectedJobId(null);
    setOpen(false);
    setListUpdated(!listUpdated);
  };
  const handleCloseEmbed = () => {
    setEmbedOpen(false);
    setSelectedJobId(null);
  };

  const getCounts = (type) => {
    // if (type === "shortlisted") {
    //   return invitedCandidatesList?.filter(
    //     (item) =>F
    //       item.InterviewPool.length &&
    //       item.InterviewPool[item.InterviewPool.length - 1].status === 3
    //   )?.length;
    // } else {
    //   return invitedCandidatesList?.filter(
    //     (item) =>
    //       item.InterviewPool.length &&
    //       item.InterviewPool[item.InterviewPool.length - 1].status === 5
    //   )?.length;
    // }
  };
  return (
    <div style={{ padding: "1rem" }}>
      {/* <FormControl>
      <Input id="my-input" aria-describedby="my-helper-text" />
      <Button className="custom-btn inactive">
        <a href="https://app.vidrec.neointeraction.com/">Click Me</a>
        </Button>
      
      <Helmet>
              <script src="https://app.vidrec.neointeraction.com/" async></script>
      </Helmet>
      </FormControl> */}
      {/* <script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_ItCgN3ejTHQ3ph" async></script> */}
      {/* <form> <button className="custom-btn inactive" onClick="loadScript()">Click Me</button> <script src="https://app.vidrec.neointeraction.com/loadScript.js"  async></script> </form> */}

      <DashboardCountCards />

      <Box>
        <Grid container justifyContent="flex-start" alignItems="left">
          <Grid item xs={8}>
            <Stack direction="row" spacing={1} justifyContent="flex-start">
              <PageHeader pageTitleText="Job Listings" />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button startIcon={<AddIcon />} btnText="Create New Job" variant="contained" onClick={handleClickOpen} />
            </Stack>
          </Grid>
        </Grid>

        <JobListingTable
          isCompleteList
          handleClickOpen={handleClickOpen}
          handleClickEdit={handleClickEdit}
          handleClickEmbed={handleClickEmbed}
          listUpdated={listUpdated}
        />
      </Box>
      {embedOpen && (
        <Modal
          size="md"
          open={embedOpen}
          close={handleCloseEmbed}
          title="Embed Button Code"
          children={<EmbedCode handleCloseEmbed={handleCloseEmbed} id={selectedJobId} />}
        />
      )}
      {open && (
        <Dialog
          size="md"
          open={open}
          close={handleClose}
          title={<>{`${selectedJobId != null ? "Update" : "Create New"} Job`}</>}
          children={<CreateJob handleClose={handleClose} id={selectedJobId} />}
        />
      )}
      {showVideo && (
        <Modal
          size={"md"}
          open={showVideo}
          close={() => setShowVideo(false)}
          title="How to setup Kapiree?"
          children={
            <ContentCenter>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/wjwLf-Tttcg?si=j0GNXe2M8pn1ZvoJ"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                style={{
                  margin: "2em",
                }}
              ></iframe>
            </ContentCenter>
          }
        />
      )}
    </div>
  );
};

export default JobListing;

import React from "react";
import { LoadingContainer } from "assets/styles/main.styles";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};

export default Loading;

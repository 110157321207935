import React, { useEffect, useState } from "react";
import { Avatar, Grid, Tooltip, CardMedia, Container } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { Button, GoBackButton, Title, Modal, Header, Footer, Tag } from "components";
import Play from "assets/images/play.jpg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DownloadIcon from "@mui/icons-material/Download";
import { formatDateDMY, isCandidatePassed } from "utils";
import BadgeIcon from "@mui/icons-material/Badge";
import { TableActionButton } from "assets/styles/main.styles";
import {
	Box,
	BackButton,
	InfoActionsBlock,
	CandidateInfoBlock,
	CIBBlock,
	SubtitleInfo,
	BlockMargin,
	BasicQuestionItem,
	BasicAnswerItem,
	ReviewAnswerCardContainer,
	VideoContainer,
	PlayButton,
	RatingBlock,
	BodyTextFeedback,
} from "assets/styles/main.styles";
import { statuses } from "config/constants";
import ReviewAnswerCard from "pages/Interviewer/Review/components/ReviewAnswerCard";
import { downloadResume, getCandidateVideos } from "config/api.service";
import { useAlert } from "contexts/alert.context";
import { capitalizeWord } from "../../../utils";
import ResumePreview from "components/ResumePreview/ResumePreview";
import { RatingValues } from "config/constants";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";

import useWindowSize from "hooks/useWindowSize";
import { format } from "date-fns";

function stringAvatar() {}

const Review = () => {
	const navigate = useNavigate();
	const windowSize = useWindowSize();
	const isMobile = windowSize.width <= 768;
	const [params] = useSearchParams();
	// const id = new URLSearchParams(window.location.search).get("id");
	const id = params.get("id");
	const role = params.get("role");
	const [interviewDetails, setInterviewDetails] = useState([]);
	const [reviewData, setReviewData] = useState([]);
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [kycModalOpen, setKycModalOpen] = useState(false);
	const [kycDetails, setKycDetails] = useState(null);
	const [isResumePreviewOpen, setIsResumePreviewOpen] = useState(false);
	const [resumePath, setResumePath] = useState("");
	const [isPlaying, setIsPlaying] = useState(false);
	const [candidateName, setCandidateName] = useState("");
	const [roleName, setRoleName] = useState("");
	const [interviewQuestions, setInterviewQuestions] = useState([]);
	const [feedback, setFeedback] = useState([]);
	const [interviewStatus, setInterviewStatus] = useState(0);
	const [comments, setComments] = useState("");

	const handleSetReviewData = (data) => {
		setReviewData(data);
	};

	const handleFeedbackDetails = (res) => {
		setInterviewDetails(res.details);
		setKycDetails(res.kycPoolData);
		setCandidateName(res.candidateName);
		setRoleName(res.roleName);
		handleSetReviewData(
			res.details?.map((item) => {
				return {
					questionId: item.questionId,
				};
			})
		);
		setInterviewQuestions(res.interviewQuestions);
		setFeedback(res.feedback);
		setInterviewStatus(res.status);
		setComments(res.reviewComments);
	};

	useEffect(() => {
		(async () => {
			if (id && role) {
				getCandidateVideos({ roleId: Number(role), candidateId: Number(id) }, handleFeedbackDetails);
			}
		})();
	}, []);

	const openResumePreview = () => {
		if (interviewDetails[0].resumeLink) {
			setResumePath(interviewDetails[0].resumeLink);
			setIsResumePreviewOpen(true);
		}
	};

	const handleResumePreview = () => {
		setIsResumePreviewOpen(false);
		setResumePath("");
	};

	const { overallRating } = isCandidatePassed(feedback, interviewQuestions);
	function stringAvatar(name) {
		if (name) {
			let nameArray = name.split(" ");
			return `${nameArray[0][0] || ""}${nameArray?.[1] ? nameArray?.[1][0] : ""}`;
		}
		return;
	}
	return (
		<>
			<Header />
			<BlockMargin size={100}></BlockMargin>
			<Container
				maxWidth={true}
				sx={{
					paddingBottom: "20px",
				}}>
				<Box>
					{/* <Grid container justifyContent="space-between" alignItems="flex-start" marginBottom={"40px"}>
						<Grid item xs={12} md={6} >
							<Avatar
								src={interviewDetails[0]?.thumbnail && interviewDetails[0]?.thumbnail}
								sx={{
									width: 90,
									height: 90,
									fontSize: 42,
									color: "#A2DFFF",
									border: "2px solid #C1EAFF",
									bgcolor: "#E0F5FF",
									textAlign: "center",
								}}>
								{stringAvatar(candidateName)}
							</Avatar>

              <CIBBlock>
								<span>
									<Title level="h2" text={candidateName} paddingTop={"40px"} color="black" /> 
								</span>
								<span
									style={{
										color: "gray",
										paddingTop: "8px",
										paddingBottom: "8px",
										fontWeight: "800",
                  }}>{`${roleName ? " (" + capitalizeWord(roleName) + ")" : ""}`}</span>

                <SubtitleInfo>
                  <strong>{interviewDetails.length && interviewDetails[0].createdDate ? format(interviewDetails[0].createdDate, "PPp") : ""}</strong>
                </SubtitleInfo>
              </CIBBlock>
						</Grid>
						<Grid item xs={4} justifyContent={"flex-end"}>
							<TableActionButton>
								<Button
									startIcon={<DownloadIcon />}
									variant="text"
									maxWidth={"150"}
									btnText="Download Resume"
									onClick={openResumePreview}
									disabled={!interviewDetails[0]?.resumeLink}
								/>
							</TableActionButton>
						</Grid>
					</Grid>
					{feedback?.length > 0 && (
						<>
							<Stack direction="row" spacing={1} justifyContent="center" verticalAlign={"center"} sx={{ alignItems: "center" }}>
								<Title level="h4" text={RatingValues[overallRating]}></Title>
								<div>
									<RatingBlock
										style={{
											textAlign: "center",
											display: "flex",
											alignItems: "center",
										}}>
										{[...Array(5)].map((_, index) => {
											const isFilled = overallRating >= index + 1;
											const isHalfFilled = overallRating > index && overallRating < index + 1;
											const color = isHalfFilled || isFilled ? "#faaf00" : "rgba(0, 0, 0, 0.25)";
											const Icon = isHalfFilled ? StarHalfRoundedIcon : isFilled ? StarRoundedIcon : StarBorderRoundedIcon;
											return (
												<Icon
													key={index}
													style={{
														width: "80px",
														height: "80px",
														color: color,
														textAlign: "center",
													}}></Icon>
											);
										})}
									</RatingBlock>
								</div>
								<div>
									<Title level="h4" text={overallRating ?? 0 + " / 5"}></Title>
								</div>
							</Stack>
							<Stack direction="row" spacing={1} justifyContent="center" verticalAlign={"center"} sx={{ alignItems: "center" }} paddingBottom={"30px"}>
								<Title level="h4" text="PreScreening Status: ">
									Total Rating:
								</Title>
								<Tag type={statuses[interviewStatus]} label={statuses[interviewStatus]} size="large" />
							</Stack>
							{comments?.length > 0 && (
								<BlockMargin size={30} style={{ textAlign: "center" }}>
									<Grid container>
										<Grid item xs={12}>
											<BodyTextFeedback>{comments[0]?.comments}</BodyTextFeedback>
										</Grid>
									</Grid>
								</BlockMargin>
							)}
						</>
					)} */}



<Grid container justifyContent="flex-start" alignItems="flex-start" xs={12}>
					<Grid item md={12} lg={5}>
						<BackButton>

							<Avatar
								src={interviewDetails[0]?.thumbnail  && interviewDetails[0]?.thumbnail }
								sx={{
									width: 90,
									height: 90,
									fontSize: 42,
									color: "#A2DFFF",
									border: "2px solid #C1EAFF",
									bgcolor: "#E0F5FF",
								}}>
								{stringAvatar(candidateName)}
							</Avatar>

							<CIBBlock>
								<span>
									<Title level="h2" text={candidateName} paddingTop={"40px"} color="black" /> <Tag type={statuses[interviewStatus]} label={statuses[interviewStatus]} size="large" />
								</span>
								<span
									style={{
										color: "gray",
										paddingTop: "8px",
										paddingBottom: "8px",
										fontWeight: "800",
                  }}>{`${roleName ? " (" + capitalizeWord(roleName) + ")" : ""}`}</span>

                <SubtitleInfo>
                <strong>{interviewDetails.length && interviewDetails[0].createdDate ? format(interviewDetails[0].createdDate, "PPp") : ""}</strong>
                </SubtitleInfo>
              </CIBBlock>
            </BackButton>
          </Grid>

					{feedback?.length > 0 && feedback?.length  === reviewData?.length && (
						<Grid item sm>
							<Stack direction="row" spacing={1} justifyContent="center" verticalAlign={"center"} sx={{ alignItems: "center" }}>
								<div>
									<RatingBlock
										style={{
											textAlign: "center",
											display: "flex",
											alignItems: "center",
										}}>
										{[...Array(5)].map((_, index) => {
											const isFilled = overallRating >= index + 1;
											const isHalfFilled = overallRating > index && overallRating < index + 1;
											const color = isHalfFilled || isFilled ? "#faaf00" : "rgba(0, 0, 0, 0.25)";
											const Icon = isHalfFilled ? StarHalfRoundedIcon : isFilled ? StarRoundedIcon : StarBorderRoundedIcon;
											return (
												<Icon
													key={index}
													style={{
														width: "60px",
														height: "60px",
														color: color,
														textAlign: "center",
													}}></Icon>
											);
										})}
									</RatingBlock>
								</div>
								{/* <div>
									<Title level="h4" text={Math.round(overallRating) ?? 0 + " / 5"}></Title>
								</div> */}
							</Stack>
							<Stack direction="row" spacing={1} justifyContent="center" verticalAlign={"center"} sx={{ alignItems: "center" }} paddingBottom={"30px"}>
								<Title level="h4" text={RatingValues[Math.round(overallRating)]}></Title>
							</Stack>
              {comments?.length > 0 && (
								<BlockMargin size={30} style={{ textAlign: "center" }}>
									<Grid container>
										<Grid item xs={12}>
											<BodyTextFeedback>{comments[0]?.comments}</BodyTextFeedback>
										</Grid>
									</Grid>
								</BlockMargin>
							)}
						</Grid>
					)}
				</Grid>

        <BlockMargin size={30}>
            <Grid container spacing={8} alignItems="flex-start">
              {interviewDetails?.map((data, i) => {
                return (
                  <Grid item md={4} xs={12} key={i}>
                    <ReviewAnswerCardContainer isClickable={false}>
                      <Title level="h6" text={`${i + 1}. ${data?.question}`} />
                      {data?.vidpath && (
                        <VideoContainer>
                          <CardMedia
                            component="video"
                            controls
                            src={data?.vidpath}
                          />
                        </VideoContainer>
                      )}
                    </ReviewAnswerCardContainer>
                  </Grid>
                );
              })}
            </Grid>
          </BlockMargin>
				</Box>
			</Container>
			<Modal size="md" open={kycModalOpen} close={() => setKycModalOpen(false)} title="KYC Image" children={<>{kycDetails != null && showKycDetails(kycDetails)}</>} />
			<ResumePreview isResumePreviewOpen={isResumePreviewOpen} handleResumePreview={handleResumePreview} resumePath={resumePath} />
			<Footer />
		</>
	);
};

export default Review;

function showKycDetails(data) {
	const { createdDate, kycMeta } = data;
	const parsedKycMeta = JSON.parse(kycMeta);
	return (
		<>
			<Title level={"body"} text={`Captured on: ${formatDateDMY(createdDate)}`} />
			<img src={parsedKycMeta?.Location} alt="kyc" style={{ maxWidth: "100%" }} />
		</>
	);
}
